import React from 'react';
import Option from './Option';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { OptionModel } from '../Models/OptionModel';
import { ColumnProps } from '../Props/ColumnProps';

const Column: React.FC<ColumnProps> = (props: ColumnProps) => {
  return (
    <div className={`placement-column ${props.column.id}`}>
      <h4>{props.column.title}</h4>
      <Droppable droppableId={props.column.id.toString()} isDropDisabled={props.readonly}>
        {(provided: DroppableProvided) => (
          <div className="options-list" {...provided.droppableProps} ref={provided.innerRef}>
            {props.column.optionIds.map((optionId: number, index: number) =>
              props.options
                .filter((option: OptionModel) => option.id === optionId)
                .map((option: OptionModel) => (
                  <Option key={option.id} option={option} index={index} readonly={props.readonly} />
                )),
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default Column;
