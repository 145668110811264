import { getLabel } from './../../glossary';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as H from 'history';
import 'semantic-ui-css/semantic.min.css';
import { Dropdown, Icon, Label, Table } from 'semantic-ui-react';
import { Permission } from '../../enums/Permission';
import { SortDirection } from '../../enums/SortDirection';
import { usePermissions } from '../../hooks/User/usePermissions';
import { UserFormModel } from '../../models/ViewModels/UserFormModel';
import DateColumn from '../DateColumn';
import SortedHeaderCell from '../SortedHeaderCell';
import './styles.scss';

interface Props {
  users?: UserFormModel[] | null;
  activeItem?: UserFormModel;
  noDataMessage: string;
  onUserEdit: (m: UserFormModel) => void;
  onUserDelete: (m: UserFormModel) => void;
  onUserStatusChange: (m: UserFormModel) => void;
}

const UsersList: React.FC<Props> = ({
  users,
  activeItem,
  noDataMessage,
  onUserEdit,
  onUserDelete,
  onUserStatusChange,
}) => {
  const history: H.History = useHistory();
  const location: H.Location = useLocation();

  const [sortField, setSortField] = useState<keyof UserFormModel>('creationDate');
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Desc);

  const hasPermission = usePermissions();
  const hasPermissionToUpdateOrDelete = hasPermission(Permission.UsersUpdate || Permission.UsersDelete);

  const getSortDirection = (field: string): SortDirection | null => {
    if (field !== sortField) {
      return null;
    }
    return sortDirection;
  };

  const onSortChanged = (field: string, direction: SortDirection) => {
    history.replace({
      state: {
        ...(location.state as object),
        sortField: field,
        sortDirection: direction,
      },
    });
  };

  useEffect(() => {
    type LocationSortData = { sortField: keyof UserFormModel; sortDirection: SortDirection };
    const locationSortData = location.state as LocationSortData;
    setSortField(locationSortData?.sortField ?? 'creationDate');
    setSortDirection(locationSortData?.sortDirection ?? SortDirection.Desc);
  }, [location]);

  return (
    <div className="users-table">
      <Table celled>
        <Table.Header>
          <Table.Row>
            <SortedHeaderCell
              className="name-column"
              label={getLabel('UserList_Name')}
              field="username"
              onSortChange={onSortChanged}
              direction={getSortDirection('username')}
            />
            <SortedHeaderCell
              className="email-column"
              label={getLabel('UserList_Email')}
              field="email"
              onSortChange={onSortChanged}
              direction={getSortDirection('email')}
            />
            <SortedHeaderCell
              className="role-column"
              label={getLabel('UserList_FeatureGroup')}
              field="role"
              onSortChange={onSortChanged}
              direction={getSortDirection('role')}
            />
            <SortedHeaderCell
              className="enabled-column"
              label={getLabel('UserList_Enabled')}
              field="enabled"
              onSortChange={onSortChanged}
              direction={getSortDirection('enabled')}
            />
            <SortedHeaderCell
              className="creation-date-column"
              label={getLabel('UserList_CreationDate')}
              field="creationDate"
              onSortChange={onSortChanged}
              direction={getSortDirection('creationDate')}
            />
            {hasPermissionToUpdateOrDelete && <Table.HeaderCell className="action-cell"></Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users?.length !== 0 ? (
            orderBy(
              users,
              [
                (u) =>
                  typeof u[sortField] === 'string' ? (u[sortField] as string).toLowerCase() : u[sortField],
              ],
              [sortDirection === SortDirection.Desc ? 'desc' : 'asc'],
            ).map((user) => (
              <Table.Row key={user.username} active={activeItem === user}>
                <Table.Cell>{user.username}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{user.role ?? getLabel('UserList_ReadOnly')}</Table.Cell>
                <Table.Cell>
                  <Label>
                    <Icon name="circle" size="small" color={user.enabled ? 'blue' : 'red'} />
                    {getLabel(`${user.enabled ? 'UserList_Enabled' : 'UserList_Disabled'}`)}
                  </Label>
                </Table.Cell>
                <Table.Cell>
                  <DateColumn date={user.creationDate} />
                </Table.Cell>
                {hasPermissionToUpdateOrDelete && (
                  <Table.Cell className="action-cell">
                    <Dropdown icon="ellipsis vertical" direction="left">
                      <Dropdown.Menu>
                        {hasPermission(Permission.UsersUpdate) && (
                          <>
                            <Dropdown.Item
                              text={getLabel('UserList_Edit')}
                              onClick={() => onUserEdit(user)}
                            />
                            <Dropdown.Item
                              text={getLabel(`${user.enabled ? 'UserList_Disable' : 'UserList_Enable'}`)}
                              onClick={() => onUserStatusChange(user)}
                            />
                          </>
                        )}
                        {hasPermission(Permission.UsersDelete) && (
                          <Dropdown.Item
                            text={getLabel('UserList_Delete')}
                            onClick={() => onUserDelete(user)}
                          />
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                )}
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={5} textAlign="center">
                {noDataMessage}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default UsersList;
