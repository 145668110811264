import React, { useEffect, useState } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic';
import { SortDirection } from '../../enums/SortDirection';
import './styles.scss';

interface Props {
  className?: string;
  label: string;
  field: string;
  width?: SemanticWIDTHS;
  direction: SortDirection | null;
  onSortChange: (field: string, direction: SortDirection) => void;
}

const SortedHeaderCell: React.FC<Props> = (props) => {
  const [direction, setDirection] = useState<SortDirection | null>(props.direction);

  useEffect(() => {
    setDirection(props.direction);
  }, [props.direction]);

  const onDirectionChanged = () => {
    const newDirection = direction !== SortDirection.Asc ? SortDirection.Asc : SortDirection.Desc;
    setDirection(newDirection);
    props.onSortChange(props.field, newDirection);
  };

  return (
    <Table.HeaderCell className={`sorted-header-cell ${props.className ?? ''}`} width={props.width}>
      <Button onClick={onDirectionChanged}>
        <span>
          {props.label}
          {direction && <Icon name={direction === SortDirection.Asc ? 'caret up' : 'caret down'} />}
        </span>
      </Button>
    </Table.HeaderCell>
  );
};

export default SortedHeaderCell;
