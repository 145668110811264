import { CheckboxModel } from '../CheckboxGroupWithInfo/CheckboxModel';
import { RadiobuttonModel } from '../RadiobuttonGroup/RadiobuttonModel';
import moment from 'moment';

const DateFormat = 'YYYY-MM-DD';

export function getCheckbox<T>(values: T[], checkboxValue: T, label: string, name: string): CheckboxModel<T> {
  return {
    label: label,
    name: name,
    value: checkboxValue,
    checked: values.some((value) => value === checkboxValue),
  };
}

export function getRadiobutton<T>(value: T, label: string, name: string): RadiobuttonModel<T> {
  return {
    label: label,
    name: name,
    value: value,
  };
}

export function getDateStringLabel(date: Date | Date[] | null): string {
  if (!date) {
    return '';
  }

  if (date instanceof Array) {
    return date.map((d) => moment(d).format(DateFormat)).join(' - ');
  }

  return moment(date).format(DateFormat);
}
