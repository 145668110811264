import React, { useEffect, useRef, useCallback } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { Controller, useForm } from 'react-hook-form';
import { isEqual } from 'lodash';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import { v4 as Guid } from 'uuid';

import { WaiverFormViewModel } from '../../models/ViewModels/WaiverFormViewModel';
import DateInputWithInfo from '../DateInputWithInfo';
import TextInputWithInfo from '../TextInputWithInfo';
import { getLabel } from './../../glossary';
import AuthorInfo from './AuthorInfo';
import PlacementOptions from '../PlacementOptions';
import TextAreaWithInfo from '../TextAreaWithInfo';
import StateToggle from '../StateToggle';
import { FlightType } from '../../enums/FlightType';
import RadiobuttonGroup from '../RadiobuttonGroup';
import CheckboxWithInfo from '../CheckboxWithInfo';
import { Region } from '../../enums/Region';
import CheckboxGroupWithInfo from '../CheckboxGroupWithInfo';
import WaiverCode from './WaiverCode';
import { WaiverCodeRef } from './WaiverCode/WaiverCodeRef';
import ButtonsBar from './ButtonsBar';
import { getCheckbox, getRadiobutton } from './helpers';
import { WaiverCodeType } from '../../enums/WaiverCodeType';
import { getEmptyWaiverCode } from '../../models/ViewModels/WaiverCodeViewModel';
import { Tag } from '../../enums/Tag';
import { deleteWaiverAttachment } from '../../services/AttachmentHelpers';
import MultipleDateInputWithInfo from '../MultipleDateInputWithInfo';
import { useGlobalComponents } from '../GlobalComponentsProvider';
import { ModalAction } from '../../enums/ModalAction';
import { ToastType } from '../../enums/ToastType';
import Attachments from '../Attachments';
import { usePermissions } from './../../hooks/User/usePermissions';
import { Permission } from '../../enums/Permission';
import { WaiversMapper } from '../../mappers/WaiversMapper';

import './styles.scss';
import '../../styles/form.scss';

interface Props {
  form: WaiverFormViewModel;
  onSubmit: (formData: WaiverFormViewModel) => void;
  onBackToList: () => void;
  onDelete: (id: string | undefined, deleteAttachments: () => Promise<void>) => void;
}

type MultipleDateRangeDateType = Date[][] | null | undefined;

const WaiversForm: React.FC<Props> = (props) => {
  const WaiverMapper = new WaiversMapper();
  const defaultValue = WaiverMapper.getDefaultValue();
  const hasPermission = usePermissions();
  const hasEditPermission = hasPermission(Permission.WaiversUpdate || Permission.WaiversCreate);
  const { showModal, showToast } = useGlobalComponents();
  const isEdit = !!props.form.id;
  const id = props.form.id ?? String(Guid());
  const formHook = useForm<WaiverFormViewModel>({
    defaultValues: (isEdit && WaiverMapper.mapToDefaultValues(props.form)) || defaultValue,
  });
  const { register, formState, handleSubmit, setValue, getValues, trigger, watch, control } = formHook;
  const defaultFormState = getValues();
  const errors = formState.errors;
  const commaSeparatedPattern = (charNumber: number): RegExp =>
    new RegExp(`^[a-zA-Z0-9]{${charNumber}}(?:,\\s*?[a-zA-Z0-9]{${charNumber}})*$`);
  const flightType = watch('flightType') ?? null;
  const isGlobal = watch('isGlobal') ?? false;
  const affectedAirportCodes = watch('affectedAirportCodes');
  const affectedCountryCodes = watch('affectedCountryCodes');
  const getWaiverCode = (type: WaiverCodeType) =>
    props.form.waiverCodes.find((code) => code.type === type) ?? getEmptyWaiverCode(type);

  const normalWaiverCode = getWaiverCode(WaiverCodeType.Normal);
  const cabinWaiverCode = getWaiverCode(WaiverCodeType.Cabin);
  const classWaiverCode = getWaiverCode(WaiverCodeType.Class);

  const normalWaiverCodeRef = useRef({} as WaiverCodeRef);
  const cabinWaiverCodeRef = useRef({} as WaiverCodeRef);
  const classWaiverCodeRef = useRef({} as WaiverCodeRef);

  const getMultipleDateRangeValidator = (
    requiredMsg: string,
    data: MultipleDateRangeDateType,
  ): string | undefined => {
    if (!data) {
      return requiredMsg;
    }

    const filledRanges = data.filter(
      (range: Date[]) => range && range.length === 2 && range.every((date) => date),
    );

    if (!filledRanges || filledRanges.length === 0 || filledRanges.length !== data.length) {
      return requiredMsg;
    }

    const dateOverlappingExists = filledRanges.some((currentRange: Date[], currentIndex: number) =>
      filledRanges
        .filter((_: Date[], index: number) => index !== currentIndex)
        .some((range: Date[]) => {
          return !(
            (currentRange[0] < range[0] && currentRange[1] < range[0]) ||
            (currentRange[0] > range[1] && currentRange[1] > range[1])
          );
        }),
    );

    if (dateOverlappingExists) {
      return getLabel('WaiversFormPage_MultiDateRange_OverlappingError');
    }
  };

  const validateAffectedSection = (): string | undefined => {
    const formValues = getValues();

    if (!formValues.isGlobal && !formValues.affectedAirportCodes && !formValues.affectedCountryCodes) {
      return getLabel('WaiversFormPage_AffectedSection_RequiredError');
    }
  };

  const onBackToList = useCallback((): void => {
    const currentFormState = getValues();

    if (isEqual(defaultFormState, currentFormState)) {
      props.onBackToList();
      return;
    }

    showModal(
      getLabel('WaiversFormPage_ModalWindow_UnsavedChangesMessage'),
      [ModalAction.Ok, ModalAction.Cancel],
      (action: ModalAction) => action === ModalAction.Ok && props.onBackToList(),
    );
  }, [getValues]);

  const onDelete = useCallback((): void => {
    const attachments = getValues('attachments') ?? [];
    const deletedAttachments = [...(props.form?.attachments ?? []), ...attachments];

    props.onDelete(id, async () => {
      id && (await deleteAttachments(deletedAttachments, id));
    });
  }, [getValues]);

  const onSubmitForm = async (formData: WaiverFormViewModel): Promise<void> => {
    if (!isEdit) {
      formData.id = id;
    }

    formData.waiverCodes = [
      normalWaiverCodeRef.current.submit(),
      cabinWaiverCodeRef.current.submit(),
      classWaiverCodeRef.current.submit(),
    ];

    const waiverCodeErrors = getWaiverCodeValidationError();
    if (waiverCodeErrors) {
      showToast(waiverCodeErrors, ToastType.Error);
      return;
    }

    const deletedAttachments = props.form.attachments?.filter((x) => !formData.attachments?.includes(x));
    deletedAttachments && id && (await deleteAttachments(deletedAttachments, id));
    props.onSubmit(formData);
  };

  const deleteAttachments = async (deletedAttachments: string[], waiverId: string): Promise<void> => {
    const deleteAttachmentPromises: Promise<void>[] = [];
    for (const attachment of deletedAttachments) {
      deleteAttachmentPromises.push(deleteWaiverAttachment(waiverId, attachment));
    }
    await Promise.all(deleteAttachmentPromises);
  };

  useEffect((): void => {
    if (isGlobal) {
      errors.affectedAirportCodes && void trigger('affectedAirportCodes');
      errors.affectedCountryCodes && void trigger('affectedCountryCodes');
    }
  }, [isGlobal, errors.affectedAirportCodes, errors.affectedCountryCodes]);

  useEffect((): void => {
    if (affectedAirportCodes) {
      errors.isGlobal && void trigger('isGlobal');
      errors.affectedCountryCodes && void trigger('affectedCountryCodes');
    }
  }, [affectedAirportCodes, errors.isGlobal, errors.affectedCountryCodes]);

  useEffect((): void => {
    if (affectedCountryCodes) {
      errors.isGlobal && void trigger('isGlobal');
      errors.affectedAirportCodes && void trigger('affectedAirportCodes');
    }
  }, [affectedCountryCodes, errors.isGlobal, errors.affectedAirportCodes]);

  useEffect((): void => {
    if (!affectedCountryCodes) {
      setValue('flightType', null);
    } else if (affectedCountryCodes && !flightType) {
      setValue('flightType', FlightType.All);
    }
  }, [affectedCountryCodes, flightType, setValue]);

  const getWaiverCodeValidationError = (): string | undefined => {
    const isExistCabinWaiverCodeRef = cabinWaiverCodeRef.current.isExist();
    const isValidCabinWaiverCodeRef = cabinWaiverCodeRef.current.isValid();
    const isExistNormalWaiverCodeRef = normalWaiverCodeRef.current.isExist();
    const isValidNormalWaiverCodeRef = normalWaiverCodeRef.current.isValid();
    const isExistClassWaiverCodeRef = classWaiverCodeRef.current.isExist();
    const isValidClassWaiverCodeRef = classWaiverCodeRef.current.isValid();

    if (!isExistCabinWaiverCodeRef && !isExistNormalWaiverCodeRef && !isExistClassWaiverCodeRef) {
      return getLabel('WaiversFormPage_WaiverCode_RequiredError');
    }

    if (!isValidCabinWaiverCodeRef || !isValidNormalWaiverCodeRef || !isValidClassWaiverCodeRef) {
      return getLabel('WaiversFormPage_WaiverCode_InvalidError');
    }
  };

  return (
    <>
      <ButtonsBar
        onBack={onBackToList}
        onSubmit={handleSubmit(onSubmitForm)}
        onDelete={isEdit ? onDelete : undefined}
      />
      <Grid className="form-container" stackable>
        <Grid.Row>
          <Grid.Column width={2} />
          <Grid.Column width={8}>
            <Form
              onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
                void handleSubmit(onSubmitForm)(event);
              }}
              autoComplete="off"
              className="form-panel"
            >
              <h2 className="header">{getLabel('WaiversFormPage_FirstHeader')}</h2>
              <TextInputWithInfo
                error={errors.title}
                value={props.form.title}
                label={getLabel('WaiversFormPage_TitleLabel')}
                infoMessage={getLabel('WaiversFormPage_TitleInfo')}
                required
                readonly={!hasEditPermission}
                register={register('title', { required: getLabel('WaiversFormPage_TitleRequired') })}
              />
              <Controller
                name="state"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <StateToggle
                    waiverState={value}
                    onChange={(v) => onChange(v)}
                    readonly={!hasEditPermission}
                  />
                )}
              />
              <Controller
                name="regions"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const regions = value ?? [];

                  return (
                    <CheckboxGroupWithInfo
                      readonly={!hasEditPermission}
                      error={errors.regions}
                      label={getLabel('WaiversFormPage_RegionsLabel')}
                      infoMessage={getLabel('WaiversFormPage_RegionsInfo')}
                      values={[
                        getCheckbox(regions, Region.AllRegions, getLabel('Region_AllRegions'), 'region-all'),
                        getCheckbox(
                          regions,
                          Region.NorthAmerica,
                          getLabel('Region_NorthAmerica'),
                          'region-north-america',
                        ),
                        getCheckbox(regions, Region.LATAM, getLabel('Region_LATAM'), 'region-latam'),
                        getCheckbox(regions, Region.EMEA, getLabel('Region_EMEA'), 'region-emea'),
                        getCheckbox(regions, Region.APAC, getLabel('Region_APAC'), 'region-apac'),
                        getCheckbox(
                          regions,
                          Region.Australia,
                          getLabel('Region_Australia'),
                          'region-australia',
                        ),
                      ]}
                      onChange={(v) => onChange(v)}
                      horizontal
                      required
                    />
                  );
                }}
                rules={{
                  validate: (data: Region[] | null | undefined): string | undefined => {
                    if (!data || data.length === 0) {
                      return getLabel('WaiversFormPage_RegionsRequired');
                    }
                  },
                }}
              />
              <TextInputWithInfo
                readonly={!hasEditPermission}
                error={errors.issuingAirlineCode}
                value={props.form.issuingAirlineCode}
                label={getLabel('WaiversFormPage_IssuingAirlineCodeLabel')}
                infoMessage={getLabel('WaiversFormPage_IssuingAirlineInfo')}
                required
                register={register('issuingAirlineCode', {
                  required: getLabel('WaiversFormPage_IssuingAirlineRequired'),
                  pattern: {
                    value: new RegExp('^([a-zA-Z0-9]{2,3})$'),
                    message: getLabel('WaiversFormPage_IssuingAirlineValidation'),
                  },
                })}
              />
              <Controller
                name="airlineIssueDate"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <DateInputWithInfo
                    readonly={!hasEditPermission}
                    value={value}
                    error={errors.airlineIssueDate}
                    onChange={(
                      _e: React.SyntheticEvent | undefined,
                      { value: dataValue }: SemanticDatepickerProps,
                    ): void => {
                      onChange(dataValue);
                    }}
                    name={name}
                    label={getLabel('WaiversFormPage_AirlineIssueDateLabel')}
                    infoMessage={getLabel('WaiversFormPage_AirlineIssueDateInfo')}
                    required
                  />
                )}
                rules={{
                  required: getLabel('WaiversFormPage_AirlineIssueDateRequiredError'),
                }}
              />
              <TextInputWithInfo
                readonly={!hasEditPermission}
                value={props.form.bookingOrMarketingAirlineCodes}
                error={errors.bookingOrMarketingAirlineCodes}
                label={getLabel('WaiversFormPage_BookingAirlinesLabel')}
                infoMessage={getLabel('WaiversFormPage_BookingAirlinesInfo')}
                required
                register={register('bookingOrMarketingAirlineCodes', {
                  required: getLabel('WaiversFormPage_BookingAirlinesRequired'),
                  pattern: {
                    value: commaSeparatedPattern(2),
                    message: getLabel('WaiversFormPage_BookingAirlinesValidation'),
                  },
                })}
              />
              <TextInputWithInfo
                readonly={!hasEditPermission}
                value={props.form.operatingAirlineCodes}
                error={errors.operatingAirlineCodes}
                label={getLabel('WaiversFormPage_OperatingAirlinesLabel')}
                infoMessage={getLabel('WaiversFormPage_OperatingAirlinesInfo')}
                required
                register={register('operatingAirlineCodes', {
                  required: getLabel('WaiversFormPage_OperatingAirlinesRequired'),
                  pattern: {
                    value: commaSeparatedPattern(2),
                    message: getLabel('WaiversFormPage_OperatingAirlinesValidation'),
                  },
                })}
              />
              <Controller
                name="isGlobal"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <CheckboxWithInfo
                    value={value}
                    label={getLabel('WaiversFormPage_IsGlobalLabel')}
                    infoMessage={getLabel('WaiversFormPage_IsGlobalInfo')}
                    onChange={onChange}
                    name={name}
                    disabled={!!affectedAirportCodes || !!affectedCountryCodes}
                    error={errors.isGlobal}
                    readonly={!hasEditPermission}
                  />
                )}
                rules={{ validate: validateAffectedSection }}
              />
              <TextAreaWithInfo
                readonly={!hasEditPermission}
                error={errors.affectedAirportCodes}
                label={getLabel('WaiversFormPage_AirportsLabel')}
                infoMessage={getLabel('WaiversFormPage_AirportsInfo')}
                disabled={isGlobal || !!affectedCountryCodes}
                register={register('affectedAirportCodes', {
                  pattern: {
                    value: commaSeparatedPattern(3),
                    message: getLabel('WaiversFormPage_AirportsValidation'),
                  },
                  validate: validateAffectedSection,
                })}
              />
              <TextAreaWithInfo
                readonly={!hasEditPermission}
                error={errors.affectedCountryCodes}
                label={getLabel('WaiversFormPage_CountriesLabel')}
                infoMessage={getLabel('WaiversFormPage_CountriesInfo')}
                disabled={isGlobal || !!affectedAirportCodes}
                register={register('affectedCountryCodes', {
                  pattern: {
                    value: commaSeparatedPattern(2),
                    message: getLabel('WaiversFormPage_CountriesValidation'),
                  },
                  validate: validateAffectedSection,
                })}
              />
              <Controller
                name="flightType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadiobuttonGroup
                    readonly={!hasEditPermission}
                    label={getLabel('WaiversFormPage_FlightTypeLabel')}
                    value={value ?? null}
                    infoMessage={getLabel('WaiversFormPage_FlightTypeInfo')}
                    radiobuttons={[
                      getRadiobutton(
                        FlightType.All,
                        getLabel('WaiversFormPage_FlightType_All'),
                        'flighttype-all',
                      ),
                      getRadiobutton(
                        FlightType.Domestic,
                        getLabel('WaiversFormPage_FlightType_Domestic'),
                        'flighttype-domestic',
                      ),
                      getRadiobutton(
                        FlightType.International,
                        getLabel('WaiversFormPage_FlightType_International'),
                        'flighttype-international',
                      ),
                    ]}
                    onChange={onChange}
                    horizontal
                    disabled={!affectedCountryCodes}
                  />
                )}
              />
              <Controller
                name="ticketedDates"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <MultipleDateInputWithInfo
                    readonly={!hasEditPermission}
                    value={value}
                    error={errors.ticketedDates}
                    name={name}
                    onChange={onChange}
                    label={getLabel('WaiversFormPage_TicketedTravelDatesLabel')}
                    infoMessage={getLabel('WaiversFormPage_TicketedTravelDatesInfo')}
                    required
                  />
                )}
                rules={{
                  validate: (dates: Date[][] | null | undefined): string | undefined =>
                    getMultipleDateRangeValidator(
                      getLabel('WaiversFormPage_TicketedTravelDatesRequiredError'),
                      dates,
                    ),
                }}
              />
              <Controller
                name="impactedTravelDates"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <MultipleDateInputWithInfo
                    readonly={!hasEditPermission}
                    value={value}
                    error={errors.impactedTravelDates}
                    name={name}
                    onChange={onChange}
                    label={getLabel('WaiversFormPage_ImpactedTravelDatesLabel')}
                    infoMessage={getLabel('WaiversFormPage_ImpactedTravelDatesInfo')}
                    required
                  />
                )}
                rules={{
                  validate: (dates: Date[][] | null | undefined): string | undefined =>
                    getMultipleDateRangeValidator(
                      getLabel('WaiversFormPage_ImpactedTravelDatesRequiredError'),
                      dates,
                    ),
                }}
              />
              <TextInputWithInfo
                readonly={!hasEditPermission}
                error={errors.ticketStocks}
                value={props.form.ticketStocks}
                label={getLabel('WaiversFormPage_TicketStocksLabel')}
                infoMessage={getLabel('WaiversFormPage_TicketStocksInfo')}
                required
                register={register('ticketStocks', {
                  required: getLabel('WaiversFormPage_TicketStocksRequiredError'),
                  pattern: {
                    value: /^[\d\w]{2} [0-9]{3}(?:,\s*?[\d\w]{2} [0-9]{3})*$/,
                    message: getLabel('WaiversFormPage_TicketStocksValidation'),
                  },
                })}
              />
              <TextInputWithInfo
                readonly={!hasEditPermission}
                value={props.form.sourceWaiverURL}
                label={getLabel('WaiversFormPage_SourceWaiverURLLabel')}
                infoMessage={getLabel('WaiversFormPage_SourceWaiverURLInfo')}
                error={errors.sourceWaiverURL}
                register={register('sourceWaiverURL')}
              />
              <TextInputWithInfo
                readonly={!hasEditPermission}
                value={props.form.osiRequirements}
                label={getLabel('WaiversFormPage_OSILabel')}
                infoMessage={getLabel('WaiversFormPage_OSIInfo')}
                error={errors.osiRequirements}
                register={register('osiRequirements')}
              />
              <TextAreaWithInfo
                readonly={!hasEditPermission}
                label={getLabel('WaiversFormPage_RefundInfoLabel')}
                infoMessage={getLabel('WaiversFormPage_RefundInfoInfo')}
                register={register('refundInfo')}
              />
              <TextAreaWithInfo
                readonly={!hasEditPermission}
                label={getLabel('WaiversFormPage_ReissueInfoLabel')}
                infoMessage={getLabel('WaiversFormPage_ReissueInfoInfo')}
                register={register('reissueInfo')}
              />
              <Controller
                name="tags"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const tags = value ?? [];
                  return (
                    <CheckboxGroupWithInfo
                      readonly={!hasEditPermission}
                      label={getLabel('WaiversFormPage_TagsLabel')}
                      infoMessage={getLabel('WaiversFormPage_TagsInfo')}
                      values={[
                        getCheckbox(
                          tags,
                          Tag.CivilUnrest,
                          getLabel('WaiversFormPage_Tag_CivilUnrest'),
                          'tag-civil-unrest',
                        ),
                        getCheckbox(
                          tags,
                          Tag.ConstructionWorks,
                          getLabel('WaiversFormPage_Tag_ConstructionWorks'),
                          'tag-construction-works',
                        ),
                        getCheckbox(
                          tags,
                          Tag.Coronavirus,
                          getLabel('WaiversFormPage_Tag_Coronavirus'),
                          'tag-coronavirus',
                        ),
                        getCheckbox(
                          tags,
                          Tag.Earthquake,
                          getLabel('WaiversFormPage_Tag_Earthquake'),
                          'tag-earthquake',
                        ),
                        getCheckbox(
                          tags,
                          Tag.ServiceSuspension,
                          getLabel('WaiversFormPage_Tag_ServiceSuspension'),
                          'tag-service-suspension',
                        ),
                        getCheckbox(tags, Tag.Strike, getLabel('WaiversFormPage_Tag_Strike'), 'tag-strike'),
                        getCheckbox(
                          tags,
                          Tag.Terrorism,
                          getLabel('WaiversFormPage_Tag_Terrorism'),
                          'tag-terrorism',
                        ),
                        getCheckbox(
                          tags,
                          Tag.VolcanoEruption,
                          getLabel('WaiversFormPage_Tag_VolcanoEruption'),
                          'tag-volcano-eruption',
                        ),
                        getCheckbox(
                          tags,
                          Tag.Weather,
                          getLabel('WaiversFormPage_Tag_Weather'),
                          'tag-weather',
                        ),
                      ]}
                      onChange={onChange}
                      horizontal
                    />
                  );
                }}
              />
              <TextInputWithInfo
                readonly={!hasEditPermission}
                value={props.form.otherTags}
                label={getLabel('WaiversFormPage_OtherTagsLabel')}
                infoMessage={getLabel('WaiversFormPage_OtherTagsInfo')}
                register={register('otherTags')}
              />
              <Controller
                name="placements"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PlacementOptions readonly={!hasEditPermission} optionIds={value} onChange={onChange} />
                )}
              />
              {id && (
                <Controller
                  name="attachments"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const attachments = value ?? [];

                    return (
                      <Attachments
                        readonly={!hasEditPermission}
                        waiverId={id}
                        files={attachments}
                        onFileUpload={(v) => onChange([...attachments, v])}
                        onFileDelete={(v) => onChange(attachments.filter((i: string) => i !== v))}
                      />
                    );
                  }}
                />
              )}
            </Form>

            <div className="waiver-codes-container">
              <WaiverCode
                readonly={!hasEditPermission}
                ref={normalWaiverCodeRef}
                data={normalWaiverCode}
                infoMessage={getLabel('WaiversFormPage_WaiverCode_ChangeFeeInfo')}
              />
              <WaiverCode
                readonly={!hasEditPermission}
                ref={cabinWaiverCodeRef}
                data={cabinWaiverCode}
                infoMessage={getLabel('WaiversFormPage_WaiverCode_CabinToCabinInfo')}
              />
              <WaiverCode
                readonly={!hasEditPermission}
                ref={classWaiverCodeRef}
                data={classWaiverCode}
                infoMessage={getLabel('WaiversFormPage_WaiverCode_ClassToClassInfo')}
              />
            </div>
          </Grid.Column>

          <Grid.Column width={1} />
          <Grid.Column width={4}>
            {isEdit && (
              <AuthorInfo
                createDate={props.form.createDate}
                createdBy={props.form.createdBy}
                updateDate={props.form.updateDate}
                updatedBy={props.form.updatedBy}
              ></AuthorInfo>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default WaiversForm;
