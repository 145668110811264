import React from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { Form, Label } from 'semantic-ui-react';
import InfoIcon from '../InfoIcon';

interface TextInputWithInfoProps {
  label?: string;
  infoMessage?: string;
  error?: FieldError;
  value?: string | null;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  register: UseFormRegisterReturn;
}

const TextInputWithInfo: React.FC<TextInputWithInfoProps> = ({
  error,
  required,
  disabled,
  label,
  infoMessage,
  readonly,
  value,
  register,
}) => {
  return (
    <Form.Group widths="equal">
      <Form.Field error={error !== undefined} required={required} width="15" disabled={disabled}>
        <label htmlFor={register?.name}>{label}</label>
        <InfoIcon infoMessage={infoMessage} />
        {readonly ? <div>{value}</div> : <input {...register} id={register?.name} />}
        {error?.message && (
          <Label basic color="red" pointing>
            {error.message}
          </Label>
        )}
      </Form.Field>
    </Form.Group>
  );
};

export default TextInputWithInfo;
