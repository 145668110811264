import { UserFormModel } from '../../models/ViewModels/UserFormModel';
import { GroupViewModel } from '../../models/ViewModels/GroupViewModel';
import { getLabel } from './../../glossary';

const getFilterCondition: (searchString: string, property?: string) => boolean = (searchString, property) => {
  return !!property?.toLowerCase().includes(searchString);
};

export const getSearchFilter: (searchText: string | undefined) => string = (searchText) => {
  return searchText ? searchText.trim().toLowerCase() : '';
};

export const getExpandedUsers = (
  usersList?: UserFormModel[],
  groupsList?: GroupViewModel[],
): UserFormModel[] => {
  return (
    usersList?.map((user) => ({
      ...user,
      role: user?.username
        ? groupsList?.find(({ usersNames }) => usersNames?.includes(user.username as string))?.name ||
          getLabel('UserList_ReadOnly')
        : '',
    })) || []
  );
};

export const getSearchingUsers = (users: UserFormModel[] | null, searchTerm: string): UserFormModel[] => {
  const searchStringToFilter = getSearchFilter(searchTerm);
  const result = searchStringToFilter
    ? users?.filter(
        (user) =>
          getFilterCondition(searchStringToFilter, user.username) ||
          getFilterCondition(searchStringToFilter, user.email),
      )
    : users;
  return result || [];
};
