import { Storage } from 'aws-amplify';

export async function uploadWaiverAttachment(waiverId: string, file: File): Promise<void> {
  await Storage.put(`${waiverId}/${file.name}`, file, {
    contentType: file.type,
  });
}

export async function downloadWaiverAttachment(waiverId: string, fileName: string): Promise<void> {
  try {
    const downloadUrl: RequestInfo = await Storage.get(`${waiverId}/${fileName}`);
    const downloadResponse = await fetch(downloadUrl);

    if (downloadResponse.ok) {
      const blobResponse = await downloadResponse.blob();
      const url = URL.createObjectURL(blobResponse);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    } else {
      throw new Error(`Status: ${downloadResponse.status}`);
    }
  } catch (error) {
    console.error(`error while downloading data. ${(error as Error)?.message}`);
  }
}

export async function deleteWaiverAttachment(waiverId: string, fileName: string): Promise<void> {
  await Storage.remove(`${waiverId}/${fileName}`);
}
