import { getLabel } from './../../../../glossary';
import React, { useState } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import './styles.scss';

interface Props {
  label: string;
  fieldName: string;
  value: [Date | null, Date | null];
  onChange: (fieldName: string, data: [Date | null, Date | null]) => void;
}

const DateFilter: React.FC<Props> = (props) => {
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const onChangeHandler = (from: Date | null, to: Date | null) => {
    setFromDate(from);
    setToDate(to);
    props.onChange(props.fieldName, [from, to]);
  };

  return (
    <div className="date-filter">
      <label>{props.label}</label>
      <div className="datepicker-group">
        <label>{getLabel('WaiversFilter_Datepicker_From')}</label>
        <SemanticDatepicker
          value={props.value[0]}
          pointing="top left"
          name={props.label}
          clearable
          type="basic"
          onChange={(event, data) => event && onChangeHandler(data.value as Date, toDate)}
        />
        <label>{getLabel('WaiversFilter_Datepicker_To')}</label>
        <SemanticDatepicker
          value={props.value[1]}
          pointing="top left"
          name={props.label}
          clearable
          type="basic"
          onChange={(event, data) => event && onChangeHandler(fromDate, data.value as Date)}
        />
      </div>
    </div>
  );
};

export default DateFilter;
