export enum Tag {
  Weather = 'Weather',
  Coronavirus = 'Coronavirus',
  CivilUnrest = 'Civil Unrest',
  Strike = 'Strike',
  Earthquake = 'Earthquake',
  Terrorism = 'Terrorism',
  VolcanoEruption = 'Volcano Eruption',
  ConstructionWorks = 'Construction Works',
  ServiceSuspension = 'Service Suspension',
}
