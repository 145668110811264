import React from 'react';

interface Props {
  date: Date | null | undefined;
}

const DateColumn: React.FC<Props> = (props) => {
  const { date } = props;

  const zeroFormat = (value: number): string | number => `${value}`.padStart(2, '0');

  return date ? (
    <>{`${date.getUTCFullYear()}-${zeroFormat(date.getUTCMonth() + 1)}-${zeroFormat(date.getUTCDate())}`}</>
  ) : (
    <></>
  );
};

export default DateColumn;
