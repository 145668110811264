import React, { useRef, useState } from 'react';
import { getLabel } from './../../glossary';

import './styles.scss';
import { Button, Icon } from 'semantic-ui-react';
import { PanelFilterModel } from '../../models/FilterModels/PanelFilterModel';
import useClickOutsideHandler from '../../hooks/useClickOutsideHandler';
import { WaiverState } from '../../enums/WaiverState';
import { Region } from '../../enums/Region';
import TextFilter from './Filters/TextFilter';
import CheckboxGroupFilter from './Filters/CheckboxGroupFilter';
import DateFilter from './Filters/DateFilter';

interface Props {
  filters: PanelFilterModel;
  isQuickSearchApplied: boolean;
  onReset: () => void;
  onFilterChange: (model: PanelFilterModel) => void;
}

const WaiverFilters: React.FC<Props> = (props) => {
  const [filtersMenuHidden, setFiltersMenuHidden] = useState<boolean>(true);
  const [filtersModel, setFiltersModel] = useState<PanelFilterModel>(props.filters);
  const [isQuickSearchApplied, setIsQuickSearchApplied] = useState<boolean>(props.isQuickSearchApplied);
  const filtersMenuRef = useRef<HTMLDivElement | null>(null);

  useClickOutsideHandler(filtersMenuRef, () => setFiltersMenuHidden(true));

  const onSearchHandler = () => {
    setFiltersMenuHidden(true);
    props?.onFilterChange(filtersModel);
  };

  const isSearchApplied = (): boolean =>
    Array.from(Object.values(filtersModel)).some((value) => value || value === false);

  const onResetHandler = () => {
    setFiltersMenuHidden(true);
    props.onReset();
  };

  const onCloseHandler = () => {
    setFiltersMenuHidden(true);
  };

  const getInputChangedHandler = (fieldName: string) => {
    return (data: React.ChangeEvent<HTMLInputElement>) => {
      const updatedFiltersModel: { [key: string]: unknown } = {
        ...filtersModel,
      };
      updatedFiltersModel[fieldName] = data.target.value;
      setFiltersModel(updatedFiltersModel);
    };
  };

  const getStateChangedHandler = (state: WaiverState) => {
    return (data: React.ChangeEvent<HTMLInputElement>) => {
      setFiltersModel({
        ...filtersModel,
        state: data.target.checked ? state : undefined,
      });
    };
  };

  const getRegionChangedHandler = (region: Region) => {
    return (data: React.ChangeEvent<HTMLInputElement>) => {
      const filterRegion = (regions: Region[], changedRegion: Region) => {
        const result = regions?.filter((item) => item !== changedRegion);
        return result.length ? result : undefined;
      };

      if (!filtersModel.regions) {
        filtersModel.regions = [];
      }
      setFiltersModel({
        ...filtersModel,
        regions: data.target.checked
          ? [...filtersModel.regions, region]
          : filterRegion(filtersModel?.regions, region),
      });
    };
  };

  const getIsGlobalChangedHandler = (value: boolean) => {
    return (data: React.ChangeEvent<HTMLInputElement>) => {
      setFiltersModel({
        ...filtersModel,
        isGlobal: data.target.checked ? value : undefined,
      });
    };
  };

  const onDateChangedHandler = (fieldName: string, data: [Date | null, Date | null]) => {
    const updatedFiltersModel: { [key: string]: unknown } = {
      ...filtersModel,
    };
    updatedFiltersModel[fieldName] = data.filter((item) => item != null).length > 0 ? data : undefined;
    setFiltersModel(updatedFiltersModel);
  };

  React.useEffect(() => {
    setFiltersModel(props.filters);
    setIsQuickSearchApplied(props.isQuickSearchApplied);
  }, [props.filters, props.isQuickSearchApplied]);
  return (
    <div ref={filtersMenuRef} className="waiver-filters">
      <button className="search-open" onClick={() => setFiltersMenuHidden(!filtersMenuHidden)} type="button">
        {getLabel('WaiversFilter_Filter')} <Icon name="angle down"></Icon>
      </button>
      {(isSearchApplied() || isQuickSearchApplied) && (
        <button type="button" className="reset-all" onClick={onResetHandler}>
          {getLabel('WaiversFilter_Reset')} <Icon name="close"></Icon>
        </button>
      )}
      <div className={filtersMenuHidden ? 'waiver-filters-menu hidden' : 'waiver-filters-menu'}>
        <Button className="close" onClick={onCloseHandler}>
          <Icon name="close"></Icon>
        </Button>
        <TextFilter
          label={getLabel('WaiversFilter_Title')}
          value={filtersModel.title || ''}
          onChange={getInputChangedHandler('title')}
        />
        <CheckboxGroupFilter
          label={getLabel('WaiversFilter_State')}
          valuesMap={[
            [getLabel('WaiversFilter_State_Active'), WaiverState.Active],
            [getLabel('WaiversFilter_State_Inactive'), WaiverState.Inactive],
          ]}
          getOnChangeHandler={getStateChangedHandler}
          isChecked={(value) => filtersModel.state === value}
        />
        <TextFilter
          label={getLabel('WaiversFilter_IssuingAirline')}
          value={filtersModel.issuingAirlineCode || ''}
          onChange={getInputChangedHandler('issuingAirlineCode')}
        />
        <TextFilter
          label={getLabel('WaiversFilter_BookingAirline')}
          value={filtersModel.bookingOrMarketingAirlineCode || ''}
          onChange={getInputChangedHandler('bookingOrMarketingAirlineCode')}
        />
        <TextFilter
          label={getLabel('WaiversFilter_OperatingAirline')}
          value={filtersModel.operatingAirlineCode || ''}
          onChange={getInputChangedHandler('operatingAirlineCode')}
        />
        <CheckboxGroupFilter
          label={getLabel('WaiversFilter_Global')}
          valuesMap={[
            [getLabel('WaiversFilter_Global_Yes'), true],
            [getLabel('WaiversFilter_Global_No'), false],
          ]}
          getOnChangeHandler={getIsGlobalChangedHandler}
          isChecked={(value) => filtersModel.isGlobal === value}
        />
        <TextFilter
          label={getLabel('WaiversFilter_Airpot')}
          value={filtersModel.airportCode || ''}
          onChange={getInputChangedHandler('airportCode')}
        />
        <TextFilter
          label={getLabel('WaiversFilter_Country')}
          value={filtersModel.countryCode || ''}
          onChange={getInputChangedHandler('countryCode')}
        />
        <CheckboxGroupFilter
          label={getLabel('WaiversFilter_Region')}
          valuesMap={[
            [getLabel('WaiversFilter_Region_AllRegions'), Region.AllRegions],
            [getLabel('WaiversFilter_Region_NorthAmerica'), Region.NorthAmerica],
            [getLabel('WaiversFilter_Region_LATAM'), Region.LATAM],
            [getLabel('WaiversFilter_Region_EMEA'), Region.EMEA],
            [getLabel('WaiversFilter_Region_APAC'), Region.APAC],
            [getLabel('WaiversFilter_Region_Australia'), Region.Australia],
          ]}
          getOnChangeHandler={getRegionChangedHandler}
          isChecked={(value) => !!filtersModel.regions?.some((region) => region === value)}
        />
        <TextFilter
          label={getLabel('WaiversFilter_Tag')}
          value={filtersModel.tags || ''}
          onChange={getInputChangedHandler('tags')}
        />
        <TextFilter
          label={getLabel('WaiversFilter_WaiverCode')}
          value={filtersModel.waiverCode || ''}
          onChange={getInputChangedHandler('waiverCode')}
        />
        <DateFilter
          label={getLabel('WaiversFilter_IssueDate')}
          fieldName="airlineIssueDate"
          value={filtersModel.airlineIssueDate || [null, null]}
          onChange={onDateChangedHandler}
        />
        <DateFilter
          label={getLabel('WaiversFilter_CreatedUpdatedDate')}
          fieldName="createdUpdatedDate"
          value={filtersModel.createdUpdatedDate || [null, null]}
          onChange={onDateChangedHandler}
        />
        <TextFilter
          label={getLabel('WaiversFilter_CreatedBy')}
          value={filtersModel.createdBy || ''}
          onChange={getInputChangedHandler('createdBy')}
        />
        <div className="buttons">
          <button type="button" className="reset" onClick={onResetHandler}>
            {getLabel('WaiversFilter_Reset')}
          </button>
          <button type="button" className="search" onClick={onSearchHandler}>
            {getLabel('WaiversFilter_Search')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WaiverFilters;
