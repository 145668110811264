import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Permission } from '../enums/Permission';
import Authentication from '../features/Authentication';
import ErrorBoundary from '../features/ErrorBoundary';
import Header from '../features/Header';
import { useUser } from '../hooks/User/user';
import { usePermissions } from '../hooks/User/usePermissions';
import WaiversListPage from '../pages/WaiversListPage';
import WaiversFormPage from '../pages/WaiversFormPage';
import ErrorPage from '../pages/ErrorPage';
import LogoutPage from '../pages/LogoutPage/LogoutPage';
import NotFoundPage from '../pages/NotFoundPage';
import UsersPage from '../pages/UsersPage/UsersPage';
import Loader from '../features/Loader';
import {
  usersEditPath,
  usersAddPath,
  usersPath,
  waiversPath,
  waiversAddPath,
  waiversEditPath,
  logoutPath,
} from './path';

const Routes = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user] = useUser();
  const hasPermission = usePermissions();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!user && location.hash.includes('access_token')) {
      setIsLoading(true);
    }

    if (user) {
      setIsLoading(false);
    }
  }, [location, user]);

  const handleLogoutRedirect = async () => {
    await Auth.signOut();
    history.push('/');
  };

  const handleLogoutClick = () => {
    history.push(logoutPath);
  };

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading &&
        (user ? (
          <>
            <Header onLogoutClick={handleLogoutClick} />
            <ErrorBoundary>
              <Switch>
                <Route path={waiversPath} exact component={WaiversListPage} />
                {hasPermission(Permission.WaiversCreate) && (
                  <Route path={waiversAddPath} exact component={WaiversFormPage} />
                )}
                <Route path={waiversEditPath} exact component={WaiversFormPage} />
                {hasPermission(Permission.UsersRead) && (
                  <Route path={usersPath} exact component={UsersPage} />
                )}
                {hasPermission(Permission.UsersCreate) && <Route path={usersAddPath} component={UsersPage} />}
                {hasPermission(Permission.UsersUpdate) && (
                  <Route path={usersEditPath} component={UsersPage} />
                )}

                <Route path={logoutPath} exact>
                  <LogoutPage onUserLogoutRedirect={() => void handleLogoutRedirect()} />
                </Route>

                <Route path="/error" exact component={ErrorPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </ErrorBoundary>
          </>
        ) : (
          <Authentication />
        ))}
    </>
  );
};
export default Routes;
