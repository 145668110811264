import React from 'react';
import { Grid } from 'semantic-ui-react';
import { getLabel } from './../../glossary';

const ErrorPage: React.FC = () => (
  <Grid celled="internally">
    <Grid.Row>
      <Grid.Column width={16} textAlign="center">
        <h1>{getLabel('ErrorPageMessage')}</h1>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default ErrorPage;
