import React, { useState } from 'react';
import { ModalAction } from '../../enums/ModalAction';
import { ToastType } from '../../enums/ToastType';
import { ModalWindow, ModalWindowProps } from '../ModalWindow';
import { ToastMessages, ToastProps } from '../ToastMessages';
import { GlobalComponentsContext } from '../../models/Component/GlobalComponentsContext';

type Props = {
  children: React.ReactElement | React.ReactElement[];
};

const GlobalComponentsContextObj = React.createContext<GlobalComponentsContext>({
  showModal: {} as () => void,
  showToast: {} as () => void,
});

const { Provider } = GlobalComponentsContextObj;

export const GlobalComponentsProvider: React.FC<Props> = ({ children }) => {
  const [modalProps, setModalProps] = useState<ModalWindowProps>({ open: false } as ModalWindowProps);
  const [toastProps, setToastProps] = useState<ToastProps>({ hidden: true } as ToastProps);

  const getModalCallback =
    (callback: (action: ModalAction) => void): ((action: ModalAction) => void) =>
    (action: ModalAction) => {
      callback(action);
      setModalProps({ open: false } as ModalWindowProps);
    };

  const onToastDismiss = () => {
    setToastProps({ hidden: true } as ToastProps);
  };

  const showModal = (
    message: string,
    actions: ModalAction[],
    callback: (action: ModalAction) => void,
    header?: string,
  ) => {
    setModalProps({
      header,
      message,
      actions,
      open: true,
      callback: getModalCallback(callback),
    });
  };

  const showToast = (message: string, type: ToastType) => {
    setToastProps({
      hidden: false,
      message: message,
      type: type,
      onDismiss: onToastDismiss,
    });

    setTimeout(() => setToastProps({ hidden: true } as ToastProps), 10000);
  };

  const context: GlobalComponentsContext = {
    showModal,
    showToast,
  };

  return (
    <>
      <Provider value={context}>{children}</Provider>
      <ModalWindow {...modalProps} />
      <ToastMessages {...toastProps} />
    </>
  );
};

export const useGlobalComponents = (): GlobalComponentsContext => {
  const context = React.useContext(GlobalComponentsContextObj);

  return {
    showModal: context.showModal,
    showToast: context.showToast,
  };
};
