import React, { useState } from 'react';
import { getLabel } from './../../glossary';

import './styles.scss';
import { QuickFilterModel } from '../../models/FilterModels/QuickFilterModel';
import { QuickFilter } from '../../enums/QuickFilter';
import PanelFilters from './PanelFilters';
import FullSearchFilter from './FullSearchFilter';
import { ContainType } from '../../enums/ContainType';
import { FullSearchFilterModel } from '../../models/FilterModels/FullSearchFilterModel';

interface Props {
  quickFilters: QuickFilterModel;
  onQuickFilterChange: (model: QuickFilterModel) => void;
}

const QuickFilters: React.FC<Props> = (props) => {
  const [quickFiltersModel, setQuickFiltersModel] = useState<QuickFilterModel>(props.quickFilters);

  const changeQuickFiltersModel = (quickFilter: QuickFilterModel) => {
    setQuickFiltersModel(quickFilter);
    props.onQuickFilterChange(quickFilter);
  };

  const getQuickFiltersPanelChangedHandler = (quickFilter: QuickFilter) => {
    return (data: React.ChangeEvent<HTMLInputElement>) => {
      const quickFilters = data.target.checked
        ? [...(quickFiltersModel.quickFilters ?? []), quickFilter]
        : quickFiltersModel.quickFilters?.filter((item) => item !== quickFilter);

      const currentQuickFiltersModel = {
        ...quickFiltersModel,
        quickFilters: quickFilters,
      };

      changeQuickFiltersModel(currentQuickFiltersModel);
    };
  };

  const getFullSearchFilterChangedHandler = (fullSearchFilter?: FullSearchFilterModel) => {
    changeQuickFiltersModel({
      ...quickFiltersModel,
      fullSearchFilter,
    });
  };

  React.useEffect(() => setQuickFiltersModel(props.quickFilters), [props.quickFilters]);

  return (
    <>
      <FullSearchFilter
        fullSearchFilter={props.quickFilters.fullSearchFilter}
        placeholder={getLabel('WaiversFilter_FullSearchFilter_Placeholder')}
        valuesMap={[
          [getLabel('WaiversFilter_ContainType_Contains'), ContainType.Contains],
          [getLabel('WaiversFilter_ContainType_DoesNotContain'), ContainType.DoesNotContain],
        ]}
        onSearch={getFullSearchFilterChangedHandler}
      />
      <div className="quick-filters-container">
        <PanelFilters
          valuesMap={[
            [getLabel('WaiversQuickFilter_QuickFilter_Active'), QuickFilter.Active],
            [getLabel('WaiversQuickFilter_QuickFilter_Inactive'), QuickFilter.Inactive],
            [getLabel('WaiversQuickFilter_QuickFilter_LastSevenDays'), QuickFilter.LastSevenDays],
            [getLabel('WaiversQuickFilter_QuickFilter_Global'), QuickFilter.Global],
            [getLabel('WaiversQuickFilter_QuickFilter_Manual'), QuickFilter.Manual],
            [getLabel('WaiversQuickFilter_QuickFilter_Automated'), QuickFilter.Automated],
            [getLabel('WaiversQuickFilter_QuickFilter_MyItems'), QuickFilter.MyItems],
          ]}
          getOnChangeHandler={getQuickFiltersPanelChangedHandler}
          isChecked={(value) =>
            !!quickFiltersModel.quickFilters?.some((quickFilter) => quickFilter === value)
          }
        />
      </div>
    </>
  );
};

export default QuickFilters;
