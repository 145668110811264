import React from 'react';
import { getLabel } from './../../../glossary';

import './styles.scss';

interface Props {
  createDate: Date;
  createdBy: string;
  updateDate?: Date | null;
  updatedBy?: string | null;
}

const AuthorInfo: React.FC<Props> = (props) => (
  <div className="author-info">
    <h2 className="header">{getLabel('WaiversFormPage_AuthorHeader')}</h2>
    <p data-testid="author-createdBy">
      <span className="author-info-label">{getLabel('WaiversFormPage_CreatedByLabel')}:</span>
      <br />
      {props.createdBy}
    </p>
    <p data-testid="author-createDate">
      <span className="author-info-label">{getLabel('WaiversFormPage_CreatedDateLabel')}:</span>
      <br />
      {props.createDate.toUTCString()}
    </p>
    <p data-testid="author-updatedBy">
      <span className="author-info-label">{getLabel('WaiversFormPage_UpdatedByLabel')}:</span>
      <br />
      {props.updatedBy || '-'}
    </p>
    <p data-testid="author-updateDate">
      <span className="author-info-label">{getLabel('WaiversFormPage_UpdateDateLabel')}:</span>
      <br />
      {(props.updateDate && props.updateDate.toUTCString()) || '-'}
    </p>
  </div>
);

export default AuthorInfo;
