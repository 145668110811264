import { useEffect, MutableRefObject } from 'react';

function useClickOutsideHandler(
  ref: MutableRefObject<HTMLDivElement | null>,
  onOutsideClick: () => void,
): void {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick, ref]);
}

export default useClickOutsideHandler;
