import { API, graphqlOperation } from 'aws-amplify';
import { getWaivers, searchWaivers } from '../api/queries';
import { WaiverDto } from '../models/WaiverDto';
import { WaiversMapper } from './../mappers/WaiversMapper';
import {
  CreateWaiversMutationVariables,
  GetWaiversQueryVariables,
  UpdateWaiversMutationVariables,
  DeleteWaiversInput,
  DeleteWaiversMutationVariables,
  SearchWaiversQueryVariables,
  CreateWaiversMutation,
} from '../ScaffoldedAPI';
import { createWaivers, updateWaivers, deleteWaivers } from '../api/mutations';
import { WaiverFormViewModel } from '../models/ViewModels/WaiverFormViewModel';
import { WaiversSearchResult, WaiversSearchResultDto } from '../models/WaiverSearchResult';
import { WaiverSearchModel } from '../models/WaiverSearchModel';
import { GraphQLResult } from '@aws-amplify/api-graphql';

export class WaiversService {
  waiversMapper: WaiversMapper;
  private readonly fetchDataErrorMessage = 'Error while fetching data from API';

  constructor(mapper: WaiversMapper) {
    this.waiversMapper = mapper;
  }

  public async deleteWaiver(id: string): Promise<void> {
    const deleteParams: DeleteWaiversInput = { id };
    const request: DeleteWaiversMutationVariables = { input: deleteParams };
    try {
      await API.graphql(graphqlOperation(deleteWaivers, request));
    } catch (err) {
      console.error(`error while fetching data ${JSON.stringify((err as GraphQLResult).errors)}`);
      throw new Error(this.fetchDataErrorMessage);
    }
  }

  public async getById(id: string): Promise<WaiverFormViewModel> {
    const request: GetWaiversQueryVariables = { id };
    try {
      const response = await API.graphql(graphqlOperation(getWaivers, request));
      const waiversDto = (response as GraphQLResult<{ getWaivers: WaiverDto }>).data?.getWaivers;

      if (!waiversDto) {
        throw new Error(this.fetchDataErrorMessage);
      }

      return this.waiversMapper.mapFromDto(waiversDto);
    } catch (err) {
      console.error(`error while fetching data ${JSON.stringify((err as GraphQLResult).errors)}`);
      throw new Error(this.fetchDataErrorMessage);
    }
  }

  public async searchWaivers(searchModel: WaiverSearchModel): Promise<WaiversSearchResult> {
    try {
      const query: SearchWaiversQueryVariables = this.waiversMapper.mapSearchModel(searchModel);
      const response = await API.graphql(graphqlOperation(searchWaivers, query));
      const waiversDto = (response as GraphQLResult<{ searchWaivers: WaiversSearchResultDto }>).data;

      if (!waiversDto) {
        throw new Error(this.fetchDataErrorMessage);
      }

      return {
        items: this.waiversMapper.mapFromDtoList(waiversDto.searchWaivers.items),
        total: waiversDto.searchWaivers.total,
      };
    } catch (err) {
      console.error(`error while fetching data ${JSON.stringify((err as GraphQLResult).errors)}`);
      throw new Error(this.fetchDataErrorMessage);
    }
  }

  public async createWaiver(formModel: WaiverFormViewModel): Promise<string> {
    try {
      const createDto = await this.waiversMapper.mapToCreateDto(formModel);
      const request: CreateWaiversMutationVariables = { input: createDto };
      const response = await API.graphql(graphqlOperation(createWaivers, request));
      return (response as GraphQLResult<CreateWaiversMutation>).data?.createWaivers?.id ?? '';
    } catch (err) {
      console.error(`error while saving data ${JSON.stringify((err as GraphQLResult).errors)}`);
      throw new Error('error while saving data');
    }
  }

  public async updateWaiver(formModel: WaiverFormViewModel): Promise<void> {
    try {
      const updateDto = await this.waiversMapper.mapToUpdateDto(formModel);
      const request: UpdateWaiversMutationVariables = { input: updateDto };
      await API.graphql(graphqlOperation(updateWaivers, request));
    } catch (err) {
      console.error(`error while updating data ${JSON.stringify((err as GraphQLResult).errors)}`);
      throw new Error('error while updating data');
    }
  }
}
