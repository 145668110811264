const guidRegex = '[0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12}';

export const usersPath = '/users';
export const usersAddPath = `${usersPath}/add`;
export const usersEditPath = `${usersPath}/edit/:id`;

export const waiversPath = '/';
export const waiversAddPath = `/waivers/add`;
export const waiversEditPath = `/waivers/:id(${guidRegex})`;

export const logoutPath = '/logout';
