import { WaiverExportModel } from '../models/ExportModel';
import { WaiverDto } from '../models/WaiverDto';
import { getLabel } from './../glossary';
import { FlightType } from '../enums/FlightType';
import { WaiverState } from '../enums/WaiverState';
import { RegionNameMap } from '../enums/Region';
import { PlacementOption } from '../enums/PlacementOption';
import { WaiverFee } from '../enums/WaiverFee';
import { WaiverCodeType } from '../enums/WaiverCodeType';
import { TicketStockInput } from '../ScaffoldedAPI';

const FlightTypeNameMap: { [key: number]: string } = {
  [FlightType.Domestic]: 'WaiversFormPage_FlightType_Domestic',
  [FlightType.International]: 'WaiversFormPage_FlightType_International',
  [FlightType.All]: 'WaiversFormPage_FlightType_All',
};

const StateNameMap: { [key: number]: string } = {
  [WaiverState.Active]: 'WaiverState_Active',
  [WaiverState.Inactive]: 'WaiverState_Inactive',
};

const PlacementOptionNameMap: { [key: number]: string } = {
  [PlacementOption.TicketDesignator]: 'WaiversCodePlacement_TicketDesignator',
  [PlacementOption.TourCodeBox]: 'WaiversCodePlacement_TourCodeBox',
  [PlacementOption.EndorsementBox]: 'WaiversCodePlacement_EndorsementBox',
  [PlacementOption.OsiMessage]: 'WaiversCodePlacement_OsiMessage',
};

const WaiverFeeNameMap: { [key: number]: string } = {
  [WaiverFee.ChangeFee]: 'WaiversFromPage_Fee_ChangeFee',
  [WaiverFee.FareDifference]: 'WaiversFromPage_Fee_FareDifference',
  [WaiverFee.NameChangeFee]: 'WaiversFromPage_Fee_NameChangeFee',
  [WaiverFee.RefundAvailable]: 'WaiversFromPage_Fee_RefundAvailable',
};

export class ExportMapper {
  public mapToExportData(source: WaiverDto[]): WaiverExportModel[] {
    return source.map((waiverDto: WaiverDto) => {
      const changeFeeCode = waiverDto.waiverCodes?.find((code) => code.type === WaiverCodeType.Normal);
      const cabinToCabinCode = waiverDto.waiverCodes?.find((code) => code.type === WaiverCodeType.Cabin);
      const classToClassCode = waiverDto.waiverCodes?.find((code) => code.type === WaiverCodeType.Class);

      return {
        id: waiverDto.id,
        state: getLabel(StateNameMap[waiverDto.state]),
        airlineIssueDate: this.mapDate(waiverDto.airlineIssueDate),
        title: waiverDto.title,
        issuingAirlineCode: waiverDto.issuingAirlineCode,
        bookingOrMarketingAirlineCodes: waiverDto.bookingOrMarketingAirlineCodes,
        operatingAirlineCodes: waiverDto.operatingAirlineCodes,
        affectedAirportCodes: waiverDto.affectedAirportCodes,
        affectedCountryCodes: waiverDto.affectedCountryCodes,
        isGlobal: waiverDto.isGlobal,
        ticketedStartDates: waiverDto.ticketedDates
          ?.map((date) => this.mapDate(date.startDate))
          .filter((date) => date) as string[],
        ticketedEndDates: waiverDto.ticketedDates
          ?.map((date) => this.mapDate(date.endDate))
          .filter((date) => date) as string[],
        impactedTravelStartDates: waiverDto.impactedTravelDates
          ?.map((date) => this.mapDate(date.startDate))
          .filter((date) => date) as string[],
        impactedTravelEndDates: waiverDto.impactedTravelDates
          ?.map((date) => this.mapDate(date.endDate))
          .filter((date) => date) as string[],
        flightType:
          ((waiverDto.flightType || waiverDto.flightType === 0) &&
            getLabel(FlightTypeNameMap[waiverDto.flightType])) ||
          undefined,
        ticketStocks: waiverDto.ticketStocks && this.mapTicketStocks(waiverDto.ticketStocks),
        sourceWaiverURL: waiverDto.sourceWaiverURL,
        placements: waiverDto.placements?.map((place) => getLabel(PlacementOptionNameMap[place])),
        reissueInfo: waiverDto.reissueInfo,
        osiRequirements: waiverDto.osiRequirements,
        createDate: this.mapDate(waiverDto.createDate) as string,
        createdBy: waiverDto.createdBy,
        updateDate: waiverDto.airlineIssueDate && this.mapDate(waiverDto.updateDate),
        updatedBy: waiverDto.updatedBy,
        regions: waiverDto.regions?.map((region) => getLabel(RegionNameMap[region])),
        refundInfo: waiverDto.refundInfo,
        tags: waiverDto.tags,

        ['changeFee_WaiverCode']: changeFeeCode?.code as string,
        ['changeFee_WaiverRebookStartDate']: this.mapDate(changeFeeCode?.rebookDate?.startDate),
        ['changeFee_WaiverRebookEndDate']: this.mapDate(changeFeeCode?.rebookDate?.endDate),
        ['changeFee_WaiverReissueStartDate']: this.mapDate(changeFeeCode?.reissueDate?.startDate),
        ['changeFee_WaiverReissueEndDate']: this.mapDate(changeFeeCode?.reissueDate?.endDate),
        ['changeFee_WaiverTravelStartDate']: this.mapDate(changeFeeCode?.travelDate?.startDate),
        ['changeFee_WaiverTravelEndDate']: this.mapDate(changeFeeCode?.travelDate?.endDate),
        ['changeFee_WaiverFees']:
          changeFeeCode?.fees &&
          changeFeeCode?.fees.map((fee) => getLabel(WaiverFeeNameMap[fee as WaiverFee])),
        ['changeFee_WaiverNotes']: changeFeeCode?.notes,

        ['cabinToCabin_WaiverCode']: cabinToCabinCode?.code as string,
        ['cabinToCabin_WaiverRebookStartDate']: this.mapDate(cabinToCabinCode?.rebookDate?.startDate),
        ['cabinToCabin_WaiverRebookEndDate']: this.mapDate(cabinToCabinCode?.rebookDate?.endDate),
        ['cabinToCabin_WaiverReissueStartDate']: this.mapDate(cabinToCabinCode?.reissueDate?.startDate),
        ['cabinToCabin_WaiverReissueEndDate']: this.mapDate(cabinToCabinCode?.reissueDate?.endDate),
        ['cabinToCabin_WaiverTravelStartDate']: this.mapDate(cabinToCabinCode?.travelDate?.startDate),
        ['cabinToCabin_WaiverTravelEndDate']: this.mapDate(cabinToCabinCode?.travelDate?.endDate),
        ['cabinToCabin_WaiverFees']:
          cabinToCabinCode?.fees &&
          cabinToCabinCode?.fees.map((fee) => getLabel(WaiverFeeNameMap[fee as WaiverFee])),
        ['cabinToCabin_WaiverNotes']: cabinToCabinCode?.notes,

        ['classToClass_WaiverCode']: classToClassCode?.code as string,
        ['classToClass_WaiverRebookStartDate']: this.mapDate(classToClassCode?.rebookDate?.startDate),
        ['classToClass_WaiverRebookEndDate']: this.mapDate(classToClassCode?.rebookDate?.endDate),
        ['classToClass_WaiverReissueStartDate']: this.mapDate(classToClassCode?.reissueDate?.startDate),
        ['classToClass_WaiverReissueEndDate']: this.mapDate(classToClassCode?.reissueDate?.endDate),
        ['classToClass_WaiverTravelStartDate']: this.mapDate(classToClassCode?.travelDate?.startDate),
        ['classToClass_WaiverTravelEndDate']: this.mapDate(classToClassCode?.travelDate?.endDate),
        ['classToClass_WaiverFees']:
          classToClassCode?.fees &&
          classToClassCode?.fees.map((fee) => getLabel(WaiverFeeNameMap[fee as WaiverFee])),
        ['classToClass_WaiverNotes']: classToClassCode?.notes,
      };
    });
  }

  private mapDate(date: string | null | undefined): string | null {
    return date ? date?.split('T')[0] : null;
  }

  private mapTicketStocks(stockInput: TicketStockInput[] | null): string[] | null {
    return stockInput
      ? stockInput.map((stock) => `${stock.airlineCode ?? ''} ${stock.stockNumber ?? ''}`)
      : null;
  }
}
