import { ApplicationLabelsKeys } from '../glossary';

export enum Region {
  AllRegions = 1,
  NorthAmerica,
  LATAM,
  EMEA,
  APAC,
  Australia,
}

export const RegionNameMap: { [key: number]: ApplicationLabelsKeys } = {
  [Region.AllRegions]: 'Region_AllRegions',
  [Region.NorthAmerica]: 'Region_NorthAmerica',
  [Region.LATAM]: 'Region_LATAM',
  [Region.EMEA]: 'Region_EMEA',
  [Region.APAC]: 'Region_APAC',
  [Region.Australia]: 'Region_Australia',
};
