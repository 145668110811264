import React from 'react';
import { Grid } from 'semantic-ui-react';
import { getLabel } from './../../glossary';

const NotFoundPage: React.FC = () => (
  <Grid celled="internally" className="sky">
    <Grid.Row>
      <Grid.Column width={16} textAlign="center">
        <h2 className="error">{getLabel('NotFoundPageMessage')}</h2>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default NotFoundPage;
