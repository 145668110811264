import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { getLabel } from './../../glossary';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Permission } from '../../enums/Permission';
import { usePermissions } from '../../hooks/User/usePermissions';
import { usersPath } from '../../routes/path';
import Logo from '../Logo/Logo';
import './styles.scss';

interface Props {
  onLogoutClick: () => void;
}

type CustomCognitoUser = CognitoUser & {
  attributes: {
    email: string;
  };
};

const Header: React.FC<Props> = ({ onLogoutClick }) => {
  const hasPermission = usePermissions();
  const [username, setUsername] = useState('');

  useEffect(() => {
    const getUsername = async () => {
      const user = (await Auth.currentUserInfo()) as CustomCognitoUser;
      if (user) {
        setUsername(user.attributes?.email);
      }
    };
    void getUsername();
  });

  return (
    <div className="header-container">
      <div className="logoHeader-container">
        <Logo white />
      </div>

      <div className="nav-container">
        <NavLink
          to={'/'}
          isActive={(_match, location) => location.pathname.includes('/waivers') || location.pathname === '/'}
          exact
          activeClassName="nav-link-active"
          className="nav-link"
        >
          {getLabel('Header_Waivers')}
        </NavLink>
        {hasPermission(Permission.UsersRead) && (
          <NavLink to={usersPath} activeClassName="nav-link-active" className="nav-link">
            {getLabel('Header_Users')}
          </NavLink>
        )}
      </div>
      <div className="right">
        <span className="user-logo" />
        <p className="username">{username}</p>
        <button className="logout" type="button" onClick={onLogoutClick}>
          {getLabel('Header_Logout')}
        </button>
      </div>
    </div>
  );
};

export default Header;
