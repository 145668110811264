import { useState, useCallback } from 'react';

export const useActiveItem = <T>(): [
  { activeItem: T | undefined },
  {
    updateActiveItem: (items: T[], idPropertyName: keyof T, id: T[keyof T], condition: boolean) => void;
  },
] => {
  const [activeItem, setActiveItem] = useState<T>();
  const updateActiveItem = useCallback(
    (items: T[], idPropertyName: keyof T, id: T[keyof T], condition: boolean) => {
      return condition
        ? setActiveItem(items?.find((item) => item[idPropertyName] === id))
        : setActiveItem({} as T);
    },
    [setActiveItem],
  );

  return [
    {
      activeItem,
    },
    { updateActiveItem },
  ];
};
