import React from 'react';
import { getLabel } from './../../../glossary';
import './styles.scss';
import { usePermissions } from '../../../hooks/User/usePermissions';
import { Permission } from '../../../enums/Permission';

interface Props {
  onSubmit: (e: React.BaseSyntheticEvent<object, any, any>) => Promise<void>;
  onBack: () => void;
  onDelete?: () => void;
}

const ButtonsBar: React.FC<Props> = (props) => {
  const hasPermission = usePermissions();
  const hasAnyWritePermission =
    hasPermission(Permission.WaiversDelete) ||
    hasPermission(Permission.WaiversUpdate) ||
    hasPermission(Permission.WaiversCreate);

  return (
    <div className="buttons-bar">
      <button onClick={props.onBack} type="button">
        {getLabel('WaiversFormPage_Back_Button')}
      </button>
      {hasAnyWritePermission && (
        <>
          <div className="divider" />
          {(hasPermission(Permission.WaiversUpdate) || hasPermission(Permission.WaiversCreate)) && (
            <button
              className="submit"
              onClick={(event: React.BaseSyntheticEvent<object, any, any>): void => {
                void props.onSubmit(event);
              }}
              type="button"
            >
              {getLabel('WaiversFormPage_Submit_Button')}
            </button>
          )}
          {props.onDelete && hasPermission(Permission.WaiversDelete) && (
            <button className="delete" onClick={props.onDelete} type="button">
              {getLabel('WaiversFormPage_Delete_Button')}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default ButtonsBar;
