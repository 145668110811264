import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import React, { SyntheticEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import ChangePassword from '../ChangePassword';
import { getLabel } from './../../../glossary';
import { useGlobalComponents } from '../../GlobalComponentsProvider';
import { ToastType } from '../../../enums/ToastType';

const SingIn = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [federatedAlertMessage, setFederatedAlertMessage] = useState('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState('');
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [userWithoutPassword, setUserWithoutPassword] = useState<CognitoUser | undefined>();
  const loadingClassName = isLoading ? 'loading' : '';
  const { showToast } = useGlobalComponents();

  const updateAlertMessage = (message: string) => {
    message && showToast(message, ToastType.Error);
    setIsLoading(false);
  };

  type CustomCognitoUser = CognitoUser & {
    challengeName: string;
    signInUserSession: {
      idToken: {
        payload: {
          'reportManagement:accessType': string;
          'reportManagement:reports': Array<string | number>;
        };
      };
    };
  };

  const handleSignIn = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const user = (await Auth.signIn(email, password)) as CustomCognitoUser;

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsChangePassword(true);
        setUserWithoutPassword(user);
        updateAlertMessage('');
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      updateAlertMessage((error as Error)?.message);
    }
  };

  const handleFederatedAuth = async () => {
    try {
      await Auth.federatedSignIn({ customProvider: 'BCD-AzureAD' });
    } catch (error) {
      setFederatedAlertMessage((error as Error)?.message);
    }
  };

  return (
    <>
      {!isChangePassword ? (
        <Form
          onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
            void handleSignIn(event);
          }}
          className="form"
        >
          <h3>{getLabel('Authentication_SinginHeader')}</h3>

          <Form.Field>
            <label>{getLabel('Authentication_Username')}</label>
            <input
              data-testid="username"
              placeholder={getLabel('Authentication_UsernamePlaceholder')}
              value={email}
              autoFocus
              onChange={(e) => setEmail(e.target.value.trim())}
            />
          </Form.Field>
          <Form.Field>
            <label>{getLabel('Authentication_Password')}</label>
            <input
              data-testid="password"
              placeholder={getLabel('Authentication_PasswordPlaceholder')}
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              onPaste={(e) => {
                e.preventDefault();
                e.clipboardData && setPassword(e.clipboardData.getData('text').trim());
              }}
            />
          </Form.Field>
          <div className="forgot-password">
            <span>{getLabel('Authentication_ForgotPassword')}</span>{' '}
            <Link to="/password/forgot" className="link-text" data-testid="forgot-password">
              {getLabel('Authentication_ResetPassword')}
            </Link>
          </div>
          <div className="sign-in-container">
            <Button
              primary
              data-testid="submit"
              type="submit"
              size="medium"
              fluid
              className={loadingClassName}
            >
              {getLabel('Authentication_Login')}
            </Button>
          </div>
          <div className="center-content">
            <div className="separator">{getLabel('Authentication_or')}</div>
            {federatedAlertMessage && <div className="error">{federatedAlertMessage}</div>}
            <Button
              data-testid="federated-sign-in"
              type="button"
              onClick={() => void handleFederatedAuth()}
              basic
              fluid
              color="blue"
            >
              {getLabel('Authentication_BCDsingin')}
            </Button>
          </div>
        </Form>
      ) : (
        <ChangePassword
          userWithoutPassword={userWithoutPassword}
          onCancel={() => setIsChangePassword(false)}
        ></ChangePassword>
      )}
    </>
  );
};

export default SingIn;
