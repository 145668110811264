import React, { ReactElement, ReactNode } from 'react';
import ErrorPage from '../../pages/ErrorPage';

export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    console.log(error);
  }

  render(): ReactElement | ReactNode {
    const { hasError } = this.state;
    const { children } = this.props as { children: React.ReactNode };
    if (hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}
