export enum Role {
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
  ReadOnly = '',
}

export const RolePrecedence = {
  [Role.SuperAdmin]: 0,
  [Role.Admin]: 10,
  [Role.ReadOnly]: 20,
};

export const getAllRoles = () => Object.values(Role);
