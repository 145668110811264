import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import jsonParser from 'json-stringify-date';

function useQueryParam<T>(key: string, defaultVal: T): [T, (val: T, search: string) => string] {
  jsonParser.setOptions<{ utc: boolean }>({ utc: true });
  const getQueryStringVal = (search: string): string | null => {
    return new URLSearchParams(search).get(key);
  };

  const updateUrl = (newObjectVal: T, search: string) => {
    const newVal: string = jsonParser.stringify<T>(newObjectVal);
    setQuery(newVal);
    const newQuery = new URLSearchParams(search);
    if (newVal !== jsonParser.stringify<T>(defaultVal)) {
      newQuery.set(key, newVal);
    } else {
      newQuery.delete(key);
    }
    return newQuery.toString();
  };

  const [query, setQuery] = useState<string>(
    getQueryStringVal(useLocation().search) ?? jsonParser.stringify<T>(defaultVal),
  );
  return [query.includes('searchText') ? (JSON.parse(query) as T) : jsonParser.parse<T>(query), updateUrl];
}
export default useQueryParam;
