import { getLabel } from './../../glossary';
import { ValidationValueMessage } from 'react-hook-form';
import { Role } from '../../enums/Role';
import { UserFormModel } from '../../models/ViewModels/UserFormModel';
import { SelectOption } from '../SelectWithInfo/SelectWithInfo';

export const READONLY = 'ReadOnly';

export const getUserRolesOptions: (roles: Role[]) => SelectOption[] = (roles: Role[]) => {
  return roles.map((r) => ({
    key: r.toString(),
    value: r || READONLY,
    text: getLabel(`UserForm_${r || READONLY}`),
  }));
};

export const userFormDefaultValues: UserFormModel = {
  username: '',
  email: '',
  role: undefined,
  enabled: false,
};

interface RegisterType {
  pattern: ValidationValueMessage<RegExp>;
  required: ValidationValueMessage<boolean>;
}

export const getEmailPattern = (): RegisterType => ({
  required: {
    value: true,
    message: getLabel('UserForm_EmailRequired'),
  },
  pattern: {
    value: /^(\w|\.|-)*([a-z]|\d)+(\w|\.|-)*@([a-z]|\d|\.|-)+\.[a-z]{2,}$/i,
    message: getLabel('UserForm_EmailValidation'),
  },
});

export const getUserNamePattern = (): RegisterType => ({
  pattern: {
    value: /^([0-9A-Z]|-|_|\.)*$/i,
    message: getLabel('UserForm_NameValidation'),
  },
  required: {
    value: true,
    message: getLabel('UserForm_UserNameRequired'),
  },
});
