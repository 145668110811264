import React, { Component } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Form } from 'semantic-ui-react';
import { getPlacementsInitialData } from '../helpers';
import Column from './Column';
import { PlacementOptionsState } from '../States/PlacementOptionsState';
import { OptionModel } from '../Models/OptionModel';
import { ColumnModel } from '../Models/ColumnModel';
import { getLabel } from './../../../glossary';
import { PlacementOptionsProps } from '../Props/PlacementOptionsProps';
import InfoIcon from '../../InfoIcon';

export default class PlacementOptions extends Component<PlacementOptionsProps, PlacementOptionsState> {
  constructor(props: PlacementOptionsProps) {
    super(props);
    this.state = getPlacementsInitialData();
  }

  componentDidUpdate(prevProps: Readonly<PlacementOptionsProps> & Readonly<{ children?: React.ReactNode }>) {
    if (prevProps.optionIds !== this.props.optionIds) {
      this.setColumnsState();
    }
  }

  componentDidMount() {
    this.setColumnsState();
  }

  setColumnsState() {
    const initialData = getPlacementsInitialData();
    const finalOptionIds = this.props.optionIds ? [...this.props.optionIds] : [];
    const availableOptionIds = initialData.options
      .filter((option: OptionModel) => !finalOptionIds.includes(option.id))
      .map((availableOption: OptionModel) => availableOption.id);

    this.setNewColumns(
      this.state.columns['available'],
      [...availableOptionIds],
      this.state.columns['final'],
      [...finalOptionIds],
    );
  }

  setNewColumn(oldColumn: ColumnModel, ids: number[]): PlacementOptionsState {
    const newColumn = {
      ...oldColumn,
      optionIds: ids,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newColumn.id]: newColumn,
      },
    };
    this.setState(newState);
    return newState;
  }

  setNewColumns(
    startColumn: ColumnModel,
    startIds: number[],
    endColumn: ColumnModel,
    endIds: number[],
  ): PlacementOptionsState {
    const newStartColumn = {
      ...startColumn,
      optionIds: startIds,
    };
    const newEndColumn = {
      ...endColumn,
      optionIds: endIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newStartColumn.id]: newStartColumn,
        [newEndColumn.id]: newEndColumn,
      },
    };
    this.setState(newState);
    return newState;
  }

  onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const startColumn = this.state.columns[source.droppableId];
    const endColumn = this.state.columns[destination.droppableId];
    let newState: PlacementOptionsState;

    if (startColumn === endColumn) {
      const newOptionIds = Array.from(startColumn.optionIds);
      newOptionIds.splice(source.index, 1);
      newOptionIds.splice(destination.index, 0, +draggableId);
      newState = this.setNewColumn(startColumn, newOptionIds);
    } else {
      const startOptionIds = Array.from(startColumn.optionIds);
      startOptionIds.splice(source.index, 1);
      const finishOptionIds = Array.from(endColumn.optionIds);
      finishOptionIds.splice(destination.index, 0, +draggableId);
      newState = this.setNewColumns(startColumn, startOptionIds, endColumn, finishOptionIds);
    }

    this.props.onChange([...newState.columns['final'].optionIds]);
  };

  render() {
    return (
      <Form.Group widths="equal" className="placement-options-container">
        <Form.Field width="15">
          <label>{getLabel('WaiversFormPage_PlacementOptionLabel')}</label>
          <InfoIcon infoMessage={getLabel('WaiversFormPage_PlacementOptionInfo')} />
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="columns-container">
              {this.state.columnOrder.map((columnId: string) => {
                const column = this.state.columns[columnId];
                return (
                  <Column
                    key={column.id}
                    column={column}
                    options={this.state.options}
                    readonly={this.props.readonly}
                  />
                );
              })}
            </div>
          </DragDropContext>
        </Form.Field>
      </Form.Group>
    );
  }
}
