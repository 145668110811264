import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import SingIn from './SingIn';
import './styles.scss';
import { getLabel } from './../../glossary';
import { PRIVACY_POLICY, TERMS_AND_CONDITION } from './constants';
import Logo from '../Logo/Logo';

const Authentication = (): JSX.Element => {
  return (
    <div className="auth-container">
      <div className="form-container">
        <Logo />

        <Router>
          <Switch>
            <Route exact path="/" component={SingIn} />
            <Route path="/password/forgot" component={ForgotPassword} />
            <Route path="/password/reset" component={ResetPassword} />
            <Route path="*" component={SingIn} />
          </Switch>
        </Router>
        <div className="form-container-footer">
          {getLabel('Authentication_FooterAllRights')}
          <br />
          {getLabel('Authentication_FooterTrademarks')}
          <br />
          <br />
          <a href={PRIVACY_POLICY} className="link">
            {getLabel('Privacy_policy')}
          </a>
          <a href={TERMS_AND_CONDITION} className="link">
            {getLabel('Terms_and_conditions')}
          </a>
        </div>
      </div>
      <div className="banner">
        <div className="info">
          <div className="text">
            {getLabel('Authentication_HeaderInfo1')}
            <br />
            {getLabel('Authentication_HeaderInfo2')}
          </div>
          <h1> {getLabel('Authentication_Header')}</h1>
        </div>
      </div>
    </div>
  );
};

export default Authentication;
