import React from 'react';

interface Props<T> {
  valuesMap: [string, T][];
  getOnChangeHandler: (value: T) => (data: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: (value: T) => boolean;
}

const PanelFilters = <T,>(props: Props<T>): React.ReactElement => (
  <div className="quick-filters-panel">
    {props.valuesMap.map(([name, value]) => (
      <div key={name.replace(/\s/g, '')} className="filter-item">
        <label className="filter-item-label">
          <input
            type="checkbox"
            checked={props.isChecked(value)}
            onChange={props.getOnChangeHandler(value)}
          />
          <span className="active-bordered">{name}</span>
        </label>
      </div>
    ))}
  </div>
);

export default PanelFilters;
