export enum Permission {
  UsersRead = 'permissions:users:read',
  UsersCreate = 'permissions:users:create',
  UsersUpdate = 'permissions:users:update',
  UsersDelete = 'permissions:users:delete',
  WaiversRead = 'permissions:waivers:read',
  WaiversExport = 'permissions:waivers:export',
  WaiversUpdate = 'permissions:waivers:update',
  WaiversCreate = 'permissions:waivers:create',
  WaiversDelete = 'permissions:waivers:delete',
}
