// this is an auto generated file. This will be overwritten

export const createWaivers = `
  mutation CreateWaivers($input: CreateWaiversInput!) {
    createWaivers(input: $input) {
      affectedAirportCodes
      affectedCountryCodes
      airlineIssueDate
      attachments
      bookingOrMarketingAirlineCodes
      createDate
      createdBy
      flightType
      impactedTravelDates {
        startDate
        endDate
      }
      id
      isGlobal
      issuingAirlineCode
      operatingAirlineCodes
      osiRequirements
      placements
      refundInfo
      reissueInfo
      regions
      sourceWaiverURL
      state
      tags
      ticketedDates {
        startDate
        endDate
      }
      ticketStocks {
        airlineCode
        stockNumber
      }
      title
      updateDate
      updatedBy
      waiverCodes {
        code
        fees
        notes
        rebookDate {
          startDate
          endDate
          withinValidityOfOriginalTicket
        }
        reissueDate {
          startDate
          endDate
          withinValidityOfOriginalTicket
        }
        travelDate {
          startDate
          endDate
          withinValidityOfOriginalTicket
        }
        type
      }
    }
  }
`;
export const deleteWaivers = `
  mutation DeleteWaivers($input: DeleteWaiversInput!) {
    deleteWaivers(input: $input) {
      affectedAirportCodes
      affectedCountryCodes
      airlineIssueDate
      attachments
      bookingOrMarketingAirlineCodes
      createDate
      createdBy
      flightType
      impactedTravelDates {
        startDate
        endDate
      }
      id
      isGlobal
      issuingAirlineCode
      operatingAirlineCodes
      osiRequirements
      placements
      refundInfo
      reissueInfo
      regions
      sourceWaiverURL
      state
      tags
      ticketedDates {
        startDate
        endDate
      }
      ticketStocks {
        airlineCode
        stockNumber
      }
      title
      updateDate
      updatedBy
      waiverCodes {
        code
        fees
        notes
        rebookDate {
          startDate
          endDate
          withinValidityOfOriginalTicket
        }
        reissueDate {
          startDate
          endDate
          withinValidityOfOriginalTicket
        }
        travelDate {
          startDate
          endDate
          withinValidityOfOriginalTicket
        }
        type
      }
    }
  }
`;
export const updateWaivers = `
  mutation UpdateWaivers($input: UpdateWaiversInput!) {
    updateWaivers(input: $input) {
      affectedAirportCodes
      affectedCountryCodes
      airlineIssueDate
      attachments
      bookingOrMarketingAirlineCodes
      createDate
      createdBy
      flightType
      impactedTravelDates {
        startDate
        endDate
      }
      id
      isGlobal
      issuingAirlineCode
      operatingAirlineCodes
      osiRequirements
      placements
      refundInfo
      reissueInfo
      regions
      sourceWaiverURL
      state
      tags
      ticketedDates {
        startDate
        endDate
      }
      ticketStocks {
        airlineCode
        stockNumber
      }
      title
      updateDate
      updatedBy
      waiverCodes {
        code
        fees
        notes
        rebookDate {
          startDate
          endDate
          withinValidityOfOriginalTicket
        }
        reissueDate {
          startDate
          endDate
          withinValidityOfOriginalTicket
        }
        travelDate {
          startDate
          endDate
          withinValidityOfOriginalTicket
        }
        type
      }
    }
  }
`;
