import React from 'react';
import { applicationLabels } from './glossary';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalComponentsProvider } from './features/GlobalComponentsProvider';
import Routes from './routes/Routes';
import { I18n } from 'aws-amplify';
import * as H from 'history';

declare module 'react-router-dom' {
  function useLocation(): H.Location;
  function useHistory(): H.History;
  function useParams<Params extends { [K in keyof Params]?: string }>(): Params;
}

const App: React.FC = () => {
  return (
    <GlobalComponentsProvider>
      <Router>
        <Routes />
      </Router>
    </GlobalComponentsProvider>
  );
};

I18n.setLanguage('en');
I18n.putVocabularies(applicationLabels);

export default App;
