import { getLabel } from './../../glossary';
import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, Segment, Grid, Label, Form } from 'semantic-ui-react';
import { ModalAction } from '../../enums/ModalAction';
import { downloadWaiverAttachment, uploadWaiverAttachment } from '../../services/AttachmentHelpers';
import { useGlobalComponents } from '../GlobalComponentsProvider';
import InfoIcon from '../InfoIcon';
import './styles.scss';

export interface Props {
  waiverId: string;
  files: string[];
  onFileUpload: (fileName: string) => void;
  onFileDelete: (fileName: string) => void;
  readonly?: boolean;
}

const Attachments: React.FC<Props> = (props) => {
  const maxFileSize = 5242880;
  const acceptFileExtensions = ['.pdf', '.msg', '.docx', '.doc', '.ppt', '.pptx', '.png', '.jpeg', '.jpg'];

  const [files, setFiles] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { showModal } = useGlobalComponents();

  useEffect(() => setFiles(props.files), [props.files]);

  const onDownloadFile = async (fileName: string) => {
    await downloadWaiverAttachment(props.waiverId, fileName);
  };

  const onDelete = (event: MouseEvent, fileName: string): void => {
    event.stopPropagation();
    setErrorMessage('');
    showModal(
      `${getLabel('WaiversFormPage_Attachment_ModalWindow_DeleteAttachmentMessage')} ${fileName}?`,
      [ModalAction.Ok, ModalAction.Cancel],
      (action: ModalAction) => {
        if (action === ModalAction.Ok) {
          setFiles(files.filter((item) => item !== fileName));
          props.onFileDelete(fileName);
        }
      },
    );
  };

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.item(0);
    await uploadFile(file);
  };

  const onFileDrop = async (event: DragEvent) => {
    event.preventDefault();

    const file = event.dataTransfer?.files?.item(0);
    await uploadFile(file);
  };

  const onDragPreventDefault = (event: DragEvent) => {
    event.preventDefault();
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const uploadFile = async (file: File | null | undefined) => {
    if (!file) {
      return;
    }

    const message = getErrorMessage(file);
    setErrorMessage(message);

    if (!message) {
      await uploadWaiverAttachment(props.waiverId, file);
      setFiles([...files, file.name]);
      props.onFileUpload(file.name);
    }
  };

  const getErrorMessage = (validationFile: File): string => {
    let message = '';
    const fileExtension = `.${validationFile.name.split('.').pop() ?? ''}`;
    if (files.includes(validationFile.name)) {
      message = getLabel('WaiversFormPage_Attachment_MessageSameName');
      return message;
    }
    if (!acceptFileExtensions.some((ext) => ext === fileExtension)) {
      message = getLabel('WaiversFormPage_Attachment_MessageWrongFileType');
    }
    if (validationFile.size > maxFileSize) {
      message += ` ${getLabel('WaiversFormPage_Attachment_MessageMaxSize')}`;
    }
    return message;
  };

  return (
    <Form.Group widths="equal" className="placement-options-container">
      <Form.Field width="15">
        <label>{getLabel('WaiversFormPage_Attachment_Attachments')}</label>
        <InfoIcon infoMessage={getLabel('WaiversFormPage_Attachment_Tooltip')} />

        <Segment
          className="files-segment"
          placeholder
          onDragOver={onDragPreventDefault}
          onDragEnter={onDragPreventDefault}
          onDragLeave={onDragPreventDefault}
          onDrop={props.readonly ? undefined : onFileDrop}
        >
          <Grid columns={2} stackable textAlign="center" className="files-container">
            <Grid.Row verticalAlign="middle">
              <Grid.Column>
                <Header icon>
                  <Icon name="file pdf outline" />
                  {files?.length > 0
                    ? `${getLabel('WaiversFormPage_Attachment_DocumentsAttached')} ${files.length}`
                    : getLabel('WaiversFormPage_Attachment_NoDocumentsAttached')}
                </Header>
                <Button
                  primary
                  as="label"
                  htmlFor="file"
                  type="button"
                  content={getLabel('WaiversFormPage_Attachment_AddDocument')}
                  disabled={props.readonly ?? false}
                />
                <input
                  type="file"
                  id="file"
                  name="attachments"
                  hidden
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    void onFileChange(event);
                  }}
                  onClick={onInputClick}
                  accept={acceptFileExtensions.join(',')}
                />
              </Grid.Column>
              {files?.length > 0 && (
                <Grid.Column>
                  <Header>{getLabel('WaiversFormPage_Attachment_UploadedDocuments')}</Header>
                  <Button.Group basic vertical className="uploaded-files-list">
                    {files.map((file) => (
                      <Button
                        icon
                        key={file}
                        onClick={() => void onDownloadFile(file)}
                        labelPosition={props.readonly ? undefined : 'right'}
                        type="button"
                      >
                        {!props.readonly && (
                          <Icon name="close" onClick={(event: MouseEvent) => onDelete(event, file)} />
                        )}
                        {file}
                      </Button>
                    ))}
                  </Button.Group>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
        {errorMessage && (
          <Label basic color="red" pointing>
            {errorMessage}
          </Label>
        )}
      </Form.Field>
    </Form.Group>
  );
};

export default Attachments;
