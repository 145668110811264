export const validateDateInputValue = (
  value: Date | (Date | undefined)[] | null | undefined,
): Date | Date[] | null => {
  const arrayValue =
    (value instanceof Array &&
      value.length > 0 &&
      value.reduce<Date[]>(
        (previousValue, currentValue) => (currentValue && [...previousValue, currentValue]) || previousValue,
        [],
      )) ||
    [];
  return (
    (arrayValue.length > 0 && arrayValue) ||
    (value instanceof Object && !(value instanceof Array) && value) ||
    null
  );
};
