import { Auth } from 'aws-amplify';
import React, { SyntheticEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { History } from 'history';
import { Button, Form } from 'semantic-ui-react';
import { getLabel } from './../../../glossary';
import { useGlobalComponents } from '../../GlobalComponentsProvider';
import { ToastType } from '../../../enums/ToastType';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const { showToast } = useGlobalComponents();
  const history: History = useHistory();
  console.log(history);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      await Auth.forgotPassword(email);

      history.push({
        pathname: '/password/reset',
        state: {
          email: email,
        },
      });
    } catch (error) {
      if ((error as Error).name === 'UserNotFoundException') {
        showToast(getLabel('Authentication_UserDoesNotExist'), ToastType.Error);
      } else {
        showToast((error as Error)?.message, ToastType.Error);
      }
    }
  };

  return (
    <Form
      onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
        void handleSubmit(event);
      }}
      className="form"
    >
      <h3>{getLabel('Authentication_ForgotPasswordTitle')}</h3>
      <Form.Field>
        <label>{getLabel('Authentication_Username')}</label>
        <input
          data-testid="forgot-password-email"
          placeholder={getLabel('Authentication_UsernamePlaceholder')}
          value={email}
          autoFocus
          onChange={(e) => setEmail(e.target.value.trim())}
        />
      </Form.Field>
      <div className="sign-in-container">
        <Link to="/" className="link-text" data-testid="go-back-link">
          {getLabel('Authentication_GoBack')}
        </Link>
        <Button
          data-testid="forgot-password-button"
          primary
          type="submit"
          size="medium"
          className="sign-in-button"
        >
          {getLabel('Authentication_VerificationCodeBtn')}
        </Button>
      </div>
    </Form>
  );
};

export default ForgotPassword;
