import React, { useEffect, useState, useRef } from 'react';
import { WaiversService } from '../../services/WaiversService';
import WaiversList from '../../features/WaiversList';
import { WaiversMapper } from '../../mappers/WaiversMapper';
import Loader from '../../features/Loader';
import { CSVLink } from 'react-csv';
import Link from 'react-csv/components/Link';
import { ExportMapper } from '../../mappers/ExportMapper';
import { WaiverExportModel } from '../../models/ExportModel';
import { ExportService } from './../../services/ExportService';
import { WaiverSearchModel } from '../../models/WaiverSearchModel';
import { PanelFilterModel } from '../../models/FilterModels/PanelFilterModel';
import { QuickFilterModel } from '../../models/FilterModels/QuickFilterModel';
import WaiversListBar from '../../features/WaiversListBar';
import WaiverFilters from '../../features/WaiverFilters';
import QuickFilters from '../../features/QuickFilters';
import { WaiversSearchResult } from '../../models/WaiverSearchResult';
import useQueryParam from '../../hooks/useQueryParam';
import { SortDirection } from '../../enums/SortDirection';
import { WaiverFilterBuilder } from '../../services/WaiverFiltersBuilder';
import { RouteComponentProps } from 'react-router-dom';

const WaiversListPage = (
  props: RouteComponentProps<object, object, { excludedIds: string[] }>,
): JSX.Element => {
  const [waivers, setWaivers] = useState<WaiversSearchResult | null>(null);
  const [exportData, setExportData] = useState<WaiverExportModel[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [page, setPage] = useQueryParam<number>('page', 1);
  const [count, setCount] = useQueryParam<number>('count', 50);
  const [sortField, setSortField] = useQueryParam<string>('sortField', 'airlineIssueDate');
  const [sortDirection, setSortDirection] = useQueryParam<SortDirection>('sortDirection', SortDirection.Desc);
  const [filtersModel, setFiltersModel] = useQueryParam<PanelFilterModel>('filters', {});
  const [quickFiltersModel, setquickFiltersModel] = useQueryParam<QuickFilterModel>('quickFilters', {});
  const csvLink = useRef<Link>() as
    | ((string | ((instance: Link | null) => void) | React.RefObject<Link>) &
        (string | ((instance: HTMLAnchorElement | null) => void) | React.RefObject<HTMLAnchorElement>))
    | null
    | undefined;

  const redirectToAddForm = () => {
    props.history.push('/waivers/add');
  };

  const changeQueryParam = (
    functionToUpdateFirstParam:
      | ((val: number, search: string) => string)
      | ((val: string, search: string) => string),
    firstValue: number | string,
    functionToUpdateSecondParam:
      | ((val: QuickFilterModel, search: string) => string)
      | ((val: number, search: string) => string),
    secondValue: QuickFilterModel | number,
    functionToUpdateThirdParam?: (val: PanelFilterModel, search: string) => string,
    thirdValue?: PanelFilterModel,
  ) => {
    const addQueryToUrl = (query: string) => {
      props.history.push({
        pathname: props.location.pathname,
        search: `?${query}`,
      });
    };

    const queryAfterUpdateFirstParam = functionToUpdateFirstParam(firstValue as never, props.location.search);
    const queryAfterUpdateSecondParam = functionToUpdateSecondParam(
      secondValue as never,
      queryAfterUpdateFirstParam,
    );
    const queryResult =
      functionToUpdateThirdParam && thirdValue
        ? functionToUpdateThirdParam(thirdValue, queryAfterUpdateSecondParam)
        : queryAfterUpdateSecondParam;

    addQueryToUrl(queryResult);
  };

  const onExportClicked = async () => {
    setLoaded(false);
    const exportService = new ExportService(new ExportMapper());
    await exportService.getData().then((data) => {
      setExportData(data);
      setLoaded(true);
      csvLink && (csvLink as React.RefObject<{ link: { click: () => void } }>).current?.link.click();
    });
  };

  const search = async (
    filterModel: PanelFilterModel,
    quickFilterModel: QuickFilterModel,
    currentPage: number,
    currentCount: number,
    currentSortField: string,
    currentSortDirection: SortDirection,
  ) => {
    setLoaded(false);
    const waiversService = new WaiversService(new WaiversMapper());
    const searchModel = {
      page: currentPage,
      count: currentCount,
      filter: filterModel,
      quickFilter: quickFilterModel,
      sortField: currentSortField,
      sortDirection: currentSortDirection,
    } as WaiverSearchModel;
    const result: WaiversSearchResult = await waiversService.searchWaivers(searchModel);
    setWaivers(result);
    setLoaded(true);
  };

  const onReset = async () => {
    changeQueryParam(setPage, 1, setquickFiltersModel, {}, setFiltersModel, {});
    await search({}, {}, 1, count, sortField, sortDirection);
  };

  const onFilterChanged = async (value: PanelFilterModel) => {
    changeQueryParam(
      setPage,
      1,
      setquickFiltersModel,
      await WaiverFilterBuilder.syncQuickFilters(value, quickFiltersModel),
      setFiltersModel,
      value,
    );
    await search(value, quickFiltersModel, 1, count, sortField, sortDirection);
  };

  const onQuickFilterChanged = async (value: QuickFilterModel) => {
    changeQueryParam(
      setPage,
      1,
      setquickFiltersModel,
      value,
      setFiltersModel,
      await WaiverFilterBuilder.syncFilters(value, quickFiltersModel, filtersModel),
    );
    await search(filtersModel, value, 1, count, sortField, sortDirection);
  };

  const onPaginationChanged = async (currentPage: number, currentCount: number) => {
    changeQueryParam(setPage, currentPage, setCount, currentCount);
    await search(filtersModel, quickFiltersModel, currentPage, currentCount, sortField, sortDirection);
  };

  const onSortChanged = async (field: string, direction: SortDirection) => {
    changeQueryParam(setSortField, field, setSortDirection, direction);
    await search(filtersModel, quickFiltersModel, 1, count, field, direction);
  };

  useEffect(() => {
    const filtersWithExcludedIdsModel = {
      ...filtersModel,
      excludedIds: props.location.state?.excludedIds,
    };

    void search(filtersWithExcludedIdsModel, quickFiltersModel, page, count, sortField, sortDirection);
  }, [props, page, count, sortField, sortDirection]);

  return (
    <div>
      {!loaded && <Loader />}
      <WaiversListBar onAdd={redirectToAddForm} onExport={() => void onExportClicked()}>
        <WaiverFilters
          filters={filtersModel}
          isQuickSearchApplied={
            (quickFiltersModel?.quickFilters ?? []).length > 0 ||
            quickFiltersModel?.fullSearchFilter?.searchText !== undefined
          }
          onReset={() => void onReset()}
          onFilterChange={(value: PanelFilterModel): void => {
            void onFilterChanged(value);
          }}
        />
        <QuickFilters
          quickFilters={quickFiltersModel}
          onQuickFilterChange={(value: QuickFilterModel): void => {
            void onQuickFilterChanged(value);
          }}
        />
      </WaiversListBar>
      <CSVLink
        data={exportData}
        filename={`BCD_Waivers_${new Date().toISOString()}.csv`}
        className="csvlink"
        ref={csvLink}
        target="_blank"
      ></CSVLink>
      <WaiversList
        waivers={waivers}
        page={page}
        count={count}
        sortField={sortField}
        sortDirection={sortDirection}
        onPaginationChange={(currentPage: number, currentCount: number): void => {
          void onPaginationChanged(currentPage, currentCount);
        }}
        onSortChange={(field: string, direction: SortDirection): void => {
          void onSortChanged(field, direction);
        }}
      ></WaiversList>
    </div>
  );
};

export default WaiversListPage;
