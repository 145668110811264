import { Amplify } from 'aws-amplify';
import { AmplifyConfigurationModel } from '../models/Configuration/AmplifyConfigurationModel';
import { ConfigurationModel } from '../models/Configuration/ConfigurationModel';

export async function buildConfiguration(): Promise<ConfigurationModel> {
  const response = await fetch(`${window.location.origin}/config.json`);
  if (!response.ok) {
    throw new Error(`Fail to fetch file. Status: ${response.status}`);
  }

  const json = (await response.json()) as AmplifyConfigurationModel;
  configureAmplify(json);
  return {
    region: json.auth_region,
    userPoolId: json.auth_userPoolId,
  };
}

function configureAmplify(configuration: AmplifyConfigurationModel) {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: configuration.auth_region,
      userPoolId: configuration.auth_userPoolId,
      userPoolWebClientId: configuration.auth_userPoolWebClientId,
      identityPoolId: configuration.auth_identityPoolId,
      oauth: {
        domain: configuration.cognitoEndpoint,
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: configuration.redirectUrl,
        redirectSignOut: configuration.redirectUrl,
        responseType: 'token',
      },
    },
    API: {
      aws_appsync_graphqlEndpoint: configuration.appsync_graphqlEndpoint,
      aws_appsync_region: configuration.appsync_Region,
      aws_appsync_authenticationType: configuration.appsync_AuthenticationType,
    },
    Storage: {
      AWSS3: {
        bucket: configuration.bucket_attachments,
        region: configuration.auth_region,
        identityPoolId: configuration.auth_identityPoolId,
      },
    },
  });
}
