import { ModalAction } from './enums/ModalAction';
import { I18n } from '@aws-amplify/core';

export type ApplicationLabelsKeys = keyof typeof applicationLabels['en']

export const getLabel = (key: ApplicationLabelsKeys): string => String(I18n.get(key));

export const applicationLabels = {
  en: {
    Authentication_SinginHeader: 'Sign in to BCD Waivers tool',
    Authentication_Username: 'Username *',
    Authentication_UsernamePlaceholder: 'Enter your username',
    Authentication_Password: 'Password *',
    Authentication_PasswordPlaceholder: 'Enter your password',
    Authentication_NewPassword: 'New Password *',
    Authentication_NewPasswordPlaceholder: 'New Password',
    Authentication_Login: 'Sign in',
    Authentication_Change: 'Change',
    Authentication_Code: 'Code *',
    Authentication_CodePlaceholder: 'Code',
    Authentication_ForgotPassword: 'Forget your password?',
    Authentication_ResetPassword: 'Reset password',
    Authentication_PasswordReset: 'Reset your password',
    Authentication_BCDsingin: 'BCD sign in',
    Authentication_ChangePassword: 'Submit',
    Authentication_ChangePasswordTitle: 'Change Password',
    Authentication_SendAgain: 'Resend code',
    Authentication_ForgotPasswordTitle: 'Reset your password',
    Authentication_VerificationCodeBtn: 'Send code',
    Authentication_GoBack: 'Back to Sign In',
    Authentication_or: 'or',
    Authentication_Header: 'First impressions last.',
    Authentication_HeaderInfo1: 'The workforce is changing.',
    Authentication_HeaderInfo2: 'Transform how you manage travel.',
    Footer_text: `© ${new Date().getFullYear()} BCD Travel, All rights reserved. TripSource® and BCD Travel® are registered trademarks of BCD Group.`,
    Authentication_FooterAllRights: `© ${new Date().getFullYear()} BCD Travel, All rights reserved.`,
    Authentication_FooterTrademarks: 'TripSource® and BCD Travel® are registered trademarks of BCD Group',
    Privacy_policy: 'Privacy policy',
    Terms_and_conditions: 'Terms and conditions',
    CompanyName: 'BCD Travel',
    Logo_ProjectName: 'Flight Waivers',
    Logo_Slogan: 'travel smart. achieve more.',
    Authentication_UserDoesNotExist: 'Username/client id combination not found.',
    WaiversList_IssueDate: 'Issue Date',
    WaiversList_StateTitle: 'State / Title',
    WaiversList_Airline: 'Issuing  Airline',
    WaiversList_Regions: 'POS Region',
    WaiversList_Airports: 'Affected Airports',
    WaiversList_Countries: 'Affected Countries',
    WaiversList_ImpactedTravelDates: 'Impacted Travel Dates',
    WaiversList_WaiverCodes: 'Waiver Codes',
    WaiversList_Tags: 'Tags',
    WaiversList_Created: 'Created',
    WaiversList_Actions: 'Actions',
    WaiversList_Nowaiversfound: 'No waivers found',
    ErrorPageMessage: 'Something went wrong. Please contact the administrator.',
    NotFoundPageMessage: "Page which you are looking for doesn't exist.",
    WaiversListPage_AddButton: '+ Add',
    WaiversListPage_ExportButton: 'CSV Export',
    WaiversFormPage_FirstHeader: 'Trip match data',
    WaiversFormPage_SecondHeader: 'Agents data for rebooking a ticket',
    WaiversFormPage_AuthorHeader: 'Audit Info',
    WaiversFormPage_IssuingAirlineCodeLabel: 'Issuing Airline',
    WaiversFormPage_IssuingAirlineRequired: 'Issuing Airline field is required',
    WaiversFormPage_IssuingAirlineInfo: 'IATA carrier code (example: DL, B6, UA)',
    WaiversFormPage_IssuingAirlineValidation: '2-3-char IATA carrier code (exampe: DL)',
    WaiversFormPage_TitleLabel: 'Title',
    WaiversFormPage_TitleInfo: 'Event name (e.g. Spain civil unrest, Carribean Typhoon, etc.)',
    WaiversFormPage_TitleRequired: 'Title field is required',
    WaiversFormPage_OperatingAirlinesLabel: 'Operating Airlines',
    WaiversFormPage_OperatingAirlinesRequired: 'At least one Operating Airline is required',
    WaiversFormPage_OperatingAirlinesValidation: 'Comma-separated 2-char airline codes',
    WaiversFormPage_OperatingAirlinesInfo: 'Comma-separated 2-char airline codes (example: KL, AF, DL)',
    WaiversFormPage_BookingAirlinesLabel: 'Booking/Marketing Airlines',
    WaiversFormPage_BookingAirlinesRequired: 'At least one Booking/Marketing Airline is required',
    WaiversFormPage_BookingAirlinesValidation: 'Comma-separated 2-char airline codes',
    WaiversFormPage_BookingAirlinesInfo: 'Comma-separated 2-char airline codes (example: KL, AF, DL)',
    WaiversFormPage_AirlineIssueDateLabel: 'Issue Date',
    WaiversFormPage_AirlineIssueDateInfo:
      'The date when a waiver was published by the airline (YYYY-MM-DD, e.g. 2019-10-19)',
    WaiversFormPage_AirlineIssueDateRequiredError: 'Airline issue date field is required',
    WaiversFormPage_AirportsLabel: 'Affected Airports',
    WaiversFormPage_AirportsInfo:
      'Comma-separated IATA codes (example: JFK, LGA, EWR). Cannot be used with Global flag or country list.',
    WaiversFormPage_AirportsValidation: 'Comma-separated 3-char airport IATA codes',
    WaiversFormPage_Attachment_AddDocument: 'Add Document',
    WaiversFormPage_Attachment_Attachments: 'Attachments',
    WaiversFormPage_Attachment_DocumentsAttached: 'Documents attached:',
    WaiversFormPage_Attachment_ModalWindow_DeleteAttachmentMessage:
      'Are you sure you want to delete the file',
    WaiversFormPage_Attachment_NoDocumentsAttached: 'No documents attached',
    WaiversFormPage_Attachment_UploadedDocuments: 'Uploaded documents',
    WaiversFormPage_Attachment_MessageMaxSize:
      'The file size exceeds the limit allowed (5 Mb) and cannot be saved.',
    WaiversFormPage_Attachment_MessageWrongFileType:
      'Invalid file attachment. Please upload file with one of following types: .pdf, .msg, .docx, .doc, .ppt, .pptx, .png, .jpeg, .jpg.',
    WaiversFormPage_Attachment_MessageSameName:
      'File with this name already exists. Please rename or delete the previous file.',
    WaiversFormPage_Attachment_Tooltip:
      'Drag-and-drop a file from your computer (max size is 5 Mb, supported formats are pdf, msg, doc/docx, ppt/pptx, png, jpeg/jpg)',
    WaiversFormPage_CountriesLabel: 'Affected Countries',
    WaiversFormPage_CountriesInfo:
      'Comma-separated 2-char ISO country codes (example: US, CA, GB, DE). Cannot be used with Global flag or airport list.',
    WaiversFormPage_CountriesValidation: 'Comma-separated 2-char country ISO codes',
    WaiversFormPage_ImpactedTravelDatesLabel: 'Impacted Travel Dates',
    WaiversFormPage_ImpactedTravelDatesInfo: 'One or several date ranges of impacted travel',
    WaiversFormPage_ImpactedTravelDatesRequiredError: 'Impacted travel dates field is required',
    WaiversFormPage_TicketedTravelDatesLabel: 'Ticketed Dates',
    WaiversFormPage_TicketedTravelDatesInfo:
      'Select the allowed date range of original ticket issue.If not provided by carrier, fill out using this rule:\nEnd date = first effective travel date –  1 day\nStart date = ticketed end date – 1 year (365 days)',
    WaiversFormPage_TicketedTravelDatesRequiredError: 'Ticketed dates field is required',
    WaiversFormPage_TicketStocksLabel: 'Ticket Stocks',
    WaiversFormPage_TicketStocksRequiredError: 'Ticket Stocks field is required',
    WaiversFormPage_TicketStocksInfo:
      'Comma-separated 2-alpha numeric char airline codes combined with 3-digit numbers (exampe: DL 006,KL 074,JL 131,Q6 081)',
    WaiversFormPage_TicketStocksValidation:
      'Comma-separated 2-alpha numeric char codes combined with 3-digit numbers',
    WaiversFormPage_SourceWaiverURLLabel: 'Source URL',
    WaiversFormPage_SourceWaiverURLInfo: 'Original waiver URL address (http://, https://)',
    WaiversFormPage_ChangeFeeCodeTitle: 'Waiver Code to Waive Change Fee',
    WaiversFormPage_CabinToCabinCodeTitle: 'Cabin to Cabin Code',
    WaiversFormPage_ClassToClassCodeTitle: 'Class to Class Code',
    WaiversFormPage_WaiverCodeLabel: 'Code',
    WaiversFormPage_WaiverCodeError: 'Code is required',
    WaiversFormPage_WaiverCodeInfo: 'Code provided by an airline for use with waiver submissions',
    WaiversFormPage_ReissueInfoLabel: 'Reissue Info',
    WaiversFormPage_ReissueInfoInfo: 'Information on ticket reissue',
    WaiversFormPage_OSILabel: 'OSI Requirements',
    WaiversFormPage_OSIInfo:
      'A unique and explicit identifier provided by an airline for use with waiver submissions',
    WaiversFormPage_PlacementOptionLabel: 'Placement Options',
    WaiversFormPage_PlacementOptionInfo:
      'Drag-and-drop one or several replacement options for waiver codes in the order of their priority from the left side list to the right side (example: insert the code in the tour code field if not used, else to the endorsement box field)',
    WaiversFormPage_CreatedByLabel: 'Created By',
    WaiversFormPage_CreatedDateLabel: 'Creation Date',
    WaiversFormPage_UpdatedByLabel: 'Updated By',
    WaiversFormPage_UpdateDateLabel: 'Update Date',
    WaiversFormPage_StateLabel: 'State',
    WaiversFormPage_Back_Button: 'Back',
    WaiversFormPage_Submit_Button: 'Submit',
    WaiversFormPage_Delete_Button: 'Delete',
    WaiversFormPage_TagsLabel: 'Tags',
    WaiversFormPage_TagsInfo:
      'Select one or several categories for classifying this waiver like "Weather", "Coronavirus", "Civil Unrest", "Strike", "Earthquake", "Terrorism", "Volcano eruption", "Construction works", "Service suspension" or other.',
    WaiversFormPage_OtherTagsLabel: 'Other Tags',
    WaiversFormPage_OtherTagsInfo: 'Add comma-separated keywords.',
    WaiversFormPage_NotesLabel: 'Notes',
    WaiversFormPage_NotesInfo: 'Free text notes',
    WaiversFormPage_RefundInfoLabel: 'Refund Info',
    WaiversFormPage_RefundInfoInfo: 'Additional notes for travel agents on how to get a refund',
    WaiversFormPage_FlightTypeLabel: 'Flight Type',
    WaiversFormPage_FlightTypeInfo:
      'Use this field only if the waiver contains one affected country code.\nDomestic matches only flights that both depart from and arrive at an airport within this country.\nInternational matches only flights that arrive or depart outside of this affected country.',
    WaiversFormPage_FlightType_Domestic: 'Domestic',
    WaiversFormPage_FlightType_International: 'International',
    WaiversFormPage_FlightType_All: 'All',
    WaiversFormPage_IsGlobalLabel: 'Global',
    WaiversFormPage_IsGlobalInfo:
      'True only if applies globally to all countries and not limited to a set of airports or countries.',
    WaiversFormPage_RegionsLabel: 'POS regions',
    WaiversFormPage_RegionsRequired: 'At least one POS region must be chosen',
    WaiversFormPage_RegionsInfo:
      "Select one or several options of the agent's ticketing location aka point of sale.",
    WaiversFromPage_FeeError: 'At least one value should be checked',
    WaiversFormPage_FeesLabel: 'Waiver Fees',
    WaiversFormPage_FeesInfo: 'One or several options of what is waived',
    WaiversFromPage_Fee_ChangeFee: 'Change Fee',
    WaiversFromPage_Fee_FareDifference: 'Fare Difference',
    WaiversFromPage_Fee_NameChangeFee: 'Name Change Fee',
    WaiversFromPage_Fee_RefundAvailable: 'Refund Available',
    WaiversFormPage_WaiverCode_InvalidError: 'Invalid waiver code',
    WaiversFormPage_WaiverCode_RequiredError: 'At least one waiver code is required',
    WaiversFormPage_WaiverCode_RebookDateLabel: 'Rebook Date',
    WaiversFormPage_WaiverCode_RebookDateError: 'Please fill rebook date range or tick the checkbox',
    WaiversFormPage_WaiverCode_RebookDateInfo:
      'Allowed date range of new ticket rebook. If not provided, use reissue date range, else use impacted travel range.',
    WaiversFormPage_WaiverCode_ReissueDateLabel: 'Reissue Date',
    WaiversFormPage_WaiverCode_ReissueDateError: 'Please fill reissue date range or tick the checkbox',
    WaiversFormPage_WaiverCode_ReissueDateInfo:
      'Allowed date range of new ticket reissue. If not provided, use rebook date range, else use impacted travel range.',
    WaiversFormPage_WaiverCode_TravelDateLabel: 'Travel Date',
    WaiversFormPage_WaiverCode_TravelDateError: 'Please fill travel date range or tick the checkbox',
    WaiversFormPage_WaiverCode_TravelDateInfo:
      'Allowed date range when the whole new travel must be completed.',
    WaiversFormPage_WaiverCode_DateWithinCheckboxLabel: 'Within validity of original ticket',
    WaiversFormPage_WaiverCode_CabinToCabinInfo:
      'If same class is not available, ok to rebook in the next class available within the same cabin.\nExample for Delta: ok to rebook up to and including Y class for main cabin fares, or the highest available class for premium fares/cabins, P class for Premium Economy fares, F class for First Class fares and J class for Delta One® fares.',
    WaiversFormPage_WaiverCode_ClassToClassInfo:
      'Rebook permitted in same class of service as originally ticketed.\nExample: if original ticket was booked in Economy Y, the new ticket should be in Economy Y',
    WaiversFormPage_WaiverCode_ChangeFeeInfo: 'Flat change fee waiver without cabin or class restrictions.',
    WaiversFormPage_Tag_Weather: 'Weather',
    WaiversFormPage_Tag_Coronavirus: 'Coronavirus',
    WaiversFormPage_Tag_CivilUnrest: 'Civil Unrest',
    WaiversFormPage_Tag_Strike: 'Strike',
    WaiversFormPage_Tag_Earthquake: 'Earthquake',
    WaiversFormPage_Tag_Terrorism: 'Terrorism ',
    WaiversFormPage_Tag_VolcanoEruption: 'Volcano Eruption',
    WaiversFormPage_Tag_ConstructionWorks: 'Construction Works',
    WaiversFormPage_Tag_ServiceSuspension: 'Service Suspension',
    WaiversFormPage_ModalWindow_DeleteWaiverMessage: 'Are you sure you want to delete this waiver?',
    WaiversFormPage_ModalWindow_UnsavedChangesMessage:
      'You have some unsaved changes. Are you sure to move from this page?',
    WaiversFormPage_AffectedSection_RequiredError:
      'Please fill Affected Airports/Countries or check Global checkbox',
    WaiversFormPage_WaiverCodeRequired: 'Please fill at least one waiver code',
    WaiversCodePlacement_TicketDesignator: 'Ticket designator',
    WaiversCodePlacement_TourCodeBox: 'Tour code box',
    WaiversCodePlacement_EndorsementBox: 'Endorsement box',
    WaiversCodePlacement_OsiMessage: 'OSI Message',
    Header_Logout: 'Logout',
    Header_Waivers: 'Waivers',
    Header_Users: 'Users',
    DateInput_Button_AddDate: 'Add',
    WaiversFormPage_MultiDateRange_OverlappingError: 'Please select non-overlapping ranges',
    ToastMessages_SubmitWaiver_Success: 'Waiver has been successfully submitted',
    ToastMessages_SumbitWaiver_Error:
      'An error has happened during waiver submission. Please try again later',
    ToastMessages_DeleteWaiver_Success: 'Waiver has been successfully deleted',
    ToastMessages_DeleteWaiver_Error: 'An error has happened during waiver deletion. Please try again later',
    [`ModalWindow_Action_${ModalAction.Ok}`]: 'Ok',
    [`ModalWindow_Action_${ModalAction.Cancel}`]: 'Cancel',
    [`ModalWindow_Action_${ModalAction.YesLeave}`]: 'Yes, Leave without saving',
    [`ModalWindow_Action_${ModalAction.NoBackToAdd}`]: 'No, Back to adding',
    [`ModalWindow_Action_${ModalAction.NoBackToEdit}`]: 'No, Back to editing',
    [`ModalWindow_Action_${ModalAction.YesConfirm}`]: 'Yes, Confirm',
    [`ModalWindow_Action_${ModalAction.NoLeave}`]: 'No, Leave without saving',
    WaiversQuickFilter_QuickFilter_ActualLastYear: 'Actual last year',
    WaiversQuickFilter_QuickFilter_Active: 'Active',
    WaiversQuickFilter_QuickFilter_Inactive: 'Inactive',
    WaiversQuickFilter_QuickFilter_LastSevenDays: 'Last 7 days',
    WaiversQuickFilter_QuickFilter_Global: 'Global',
    WaiversQuickFilter_QuickFilter_Manual: 'Manual',
    WaiversQuickFilter_QuickFilter_Automated: 'Automated',
    WaiversQuickFilter_QuickFilter_MyItems: 'My items',
    WaiversFilter_Filter: 'Filter',
    WaiversFilter_Search: 'Search',
    WaiversFilter_Reset: 'Reset',
    WaiversFilter_Title: 'Title',
    WaiversFilter_State: 'State',
    WaiversFilter_State_Active: 'Active',
    WaiversFilter_State_Inactive: 'Inactive',
    WaiversFilter_IssuingAirline: 'Issuing Airline',
    WaiversFilter_BookingAirline: 'Booking/Marketing Airline',
    WaiversFilter_OperatingAirline: 'Operating Airline',
    WaiversFilter_IssueDate: 'Issue Date',
    WaiversFilter_CreatedUpdatedDate: 'Created/Updated',
    WaiversFilter_Country: 'Affected Country',
    WaiversFilter_Airpot: 'Affected Airport',
    WaiversFilter_WaiverCode: 'Waiver Code',
    WaiversFilter_CreatedBy: 'Created By',
    WaiversFilter_Tag: 'Tag',
    WaiversFilter_Global: 'Global',
    WaiversFilter_Global_Yes: 'Yes',
    WaiversFilter_Global_No: 'No',
    WaiversFilter_Region: 'POS regions',
    WaiversFilter_Region_AllRegions: 'All Regions',
    WaiversFilter_Region_NorthAmerica: 'North America',
    WaiversFilter_Region_LATAM: 'LATAM',
    WaiversFilter_Region_EMEA: 'EMEA',
    WaiversFilter_Region_APAC: 'APAC',
    WaiversFilter_Region_Australia: 'Australia',
    WaiversFilter_Datepicker_From: 'from',
    WaiversFilter_Datepicker_To: 'to',
    WaiversFilter_FullSearchFilter_Placeholder: 'Type to search',
    WaiversFilter_ContainType_Contains: 'Contains',
    WaiversFilter_ContainType_DoesNotContain: "Doesn't contain",
    WaiversList_ShowPerPage: 'Show per page',
    Region_AllRegions: 'All Regions',
    Region_NorthAmerica: 'North America',
    Region_LATAM: 'LATAM',
    Region_EMEA: 'EMEA',
    Region_APAC: 'APAC',
    Region_Australia: 'Australia',
    WaiverState_Inactive: 'Inactive',
    WaiverState_Active: 'Active',
    UsersPage_CreateUserButton: 'Create new user',
    UsersPage_SearchPlaceholder: 'Search by User name or email',
    UsersPage_SubmitUser_UserError: 'User account already exists',
    UsersPage_User_Success_Start: 'User',
    UsersPage_DeleteUser_Success_End: 'was successfully deleted',
    UsersPage_CreateUser_Success_End: 'was successfully created',
    UsersPage_UpdateUser_Success_End: 'was successfully updated',
    UsersPage_User_Error: 'Something went wrong. Please, try again later',
    UserList_Name: 'Name',
    UserList_Email: 'Email',
    UserList_FeatureGroup: 'Feature group',
    UserList_Enabled: 'Enabled',
    UserList_Disabled: 'Disabled',
    UserList_CreationDate: 'Creation date',
    UserList_Edit: 'Edit',
    UserList_Enable: 'Enable',
    UserList_Disable: 'Disable',
    UserList_Delete: 'Delete',
    UserList_ReadOnly: 'ReadOnly',
    UserList_NoUsersFound: 'No users found',
    UserList_NoResultsFound: 'No results found. Please, restart the search',
    UserForm_EditTitle: 'Edit user',
    UserForm_AddTitle: 'Add user',
    UserForm_Cancel: 'Cancel',
    UserForm_Save: 'Save',
    UserForm_UserName: 'Name',
    UserForm_Email: 'Email',
    UserForm_FeatureGroup: 'Feature group',
    UserForm_Enable: 'Enable',
    UserForm_Admin: 'Admin',
    UserForm_SuperAdmin: 'SuperAdmin',
    UserForm_ReadOnly: 'ReadOnly',
    UserForm_UserNameRequired: 'User name is required',
    UserForm_EmailRequired: 'Email is required',
    UserForm_FeatureGroupRequired: 'Feature group is required',
    UserForm_EmailValidation: 'Entered value does not match email format',
    UserForm_NameValidation: 'User name must contain only letters, numbers and symbols "_", "-", "."',
    UserForm_Modal_UnsavedChanges_Header: 'There are unsaved changes',
    UserForm_Modal_UnsavedChanges_Message: 'Are you sure you want to leave?',
    UserForm_Modal_Save_Message: 'Are you sure you want to save changes?',
    UserForm_Modal_Delete_Message: 'Are you sure that you want to delete'
  }
};
