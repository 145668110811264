import React, { useEffect, useState } from 'react';
import { WaiverFormViewModel } from '../../models/ViewModels/WaiverFormViewModel';
import { WaiversMapper } from '../../mappers/WaiversMapper';
import { WaiversService } from '../../services/WaiversService';
import WaiversForm from '../../features/WaiversForm';
import Loader from '../../features/Loader';
import { WaiverCodeType } from '../../enums/WaiverCodeType';
import { getEmptyWaiverCode } from '../../models/ViewModels/WaiverCodeViewModel';
import { getLabel } from './../../glossary';
import { useGlobalComponents } from '../../features/GlobalComponentsProvider/GlobalComponentsProvider';
import { ModalAction } from '../../enums/ModalAction';
import { WaiverState } from '../../enums/WaiverState';
import { ToastType } from '../../enums/ToastType';
import { RouteComponentProps } from 'react-router-dom';

const WaiversFormPage = (props: RouteComponentProps<{ id: string }>): JSX.Element => {
  const service: WaiversService = new WaiversService(new WaiversMapper());
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { showModal, showToast } = useGlobalComponents();

  const [initialFormValue, setInitialFormValue] = useState<WaiverFormViewModel>({
    title: '',
    createDate: new Date(),
    createdBy: '',
    state: WaiverState.Inactive,
    updateDate: new Date(),
    updatedBy: '',
    waiverCodes: [
      getEmptyWaiverCode(WaiverCodeType.Normal),
      getEmptyWaiverCode(WaiverCodeType.Cabin),
      getEmptyWaiverCode(WaiverCodeType.Class),
    ],
  });

  const initWaiverData = async (id: string) => {
    const result = await service.getById(id);
    setInitialFormValue(result);
  };

  const onSubmit = (formData: WaiverFormViewModel): void => {
    setIsLoaded(false);
    const id: string = props.match.params.id;
    (props.match.params.id
      ? service.updateWaiver(formData).then(async () => {
          showToast(getLabel('ToastMessages_SubmitWaiver_Success'), ToastType.Success);
          await initWaiverData(id);
          setIsLoaded(true);
        })
      : service.createWaiver(formData).then((id: string) => {
          props.history.replace(`/waivers/${id}`);
          showToast(getLabel('ToastMessages_SubmitWaiver_Success'), ToastType.Success);
        })
    ).catch(() => {
      showToast(getLabel('ToastMessages_SumbitWaiver_Error'), ToastType.Error);
    });
  };

  const deleteWaiver = (id: string): void => {
    setIsLoaded(false);
    service
      .deleteWaiver(id)
      .then(() => {
        showToast(getLabel('ToastMessages_DeleteWaiver_Success'), ToastType.Success);
        props.history.push({ pathname: '/', state: { excludedIds: [id] } });
      })
      .catch(() => {
        showToast(getLabel('ToastMessages_DeleteWaiver_Error'), ToastType.Error);
        setIsLoaded(true);
      });
  };

  const onDelete = (id: string | undefined, deleteAttachments: () => Promise<void>): void => {
    showModal(
      getLabel('WaiversFormPage_ModalWindow_DeleteWaiverMessage'),
      [ModalAction.Ok, ModalAction.Cancel],
      (action: ModalAction) => {
        if (action === ModalAction.Ok) {
          id && deleteWaiver(id);
          void deleteAttachments();
        }
      },
    );
  };

  const onBackToList = (): void => {
    props.history.length > 2 ? props.history.goBack() : props.history.push('/');
  };

  useEffect(() => {
    if (props.match.params.id) {
      const id = props.match.params.id;
      void (async () => {
        await initWaiverData(id);
        setIsLoaded(true);
      })();
    } else {
      setIsLoaded(true);
    }
  }, [props.location]);

  if (isLoaded) {
    return (
      <WaiversForm
        form={initialFormValue}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onBackToList={onBackToList}
      />
    );
  } else {
    return <Loader />;
  }
};

export default WaiversFormPage;
