import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Accordion, Checkbox, CheckboxProps, Form, Icon } from 'semantic-ui-react';

import { WaiverCodeType } from '../../../enums/WaiverCodeType';
import { WaiverFee } from '../../../enums/WaiverFee';
import { WaiverCodeDate, WaiverCodeViewModel } from '../../../models/ViewModels/WaiverCodeViewModel';
import CheckboxGroupWithInfo from '../../CheckboxGroupWithInfo';
import DateInputWithInfo from '../../DateInputWithInfo';
import TextAreaWithInfo from '../../TextAreaWithInfo';
import TextInputWithInfo from '../../TextInputWithInfo';
import { getCheckbox } from '../helpers';
import { WaiverCodeRef } from './WaiverCodeRef';
import InfoIcon from '../../InfoIcon';
import { DateInputWithInfoValueType } from '../../DateInputWithInfo/DateInputWithInfo';
import { getLabel } from './../../../glossary';

import './styles.scss';

interface Props {
  data: WaiverCodeViewModel;
  infoMessage: string;
  readonly?: boolean;
}

const WaiverCode = (props: Props, ref: React.Ref<WaiverCodeRef>): JSX.Element => {
  const formHook = useForm<WaiverCodeViewModel>({ defaultValues: { ...props.data } });
  const { register, formState, trigger, getValues, control } = formHook;
  const errors = formState.errors;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const imperativeHandle = {
    submit(): WaiverCodeViewModel {
      if (imperativeHandle.isExist()) {
        void trigger();
      }

      return getValues();
    },
    isExist(): boolean {
      const formData = getValues();
      const codeState = (formData.code?.trim() ?? '') !== '';
      const feesState = formData.fees.length !== 0;
      const notesState = (formData.notes ?? '') !== '';
      const rebookDateState = isDateExist(formData.rebookDate);
      const reissueDateState = isDateExist(formData.reissueDate);
      const travelDateState = isDateExist(formData.travelDate);
      return codeState || feesState || notesState || rebookDateState || reissueDateState || travelDateState;
    },
    isValid(): boolean {
      const formData = getValues();
      return (
        !imperativeHandle.isExist() ||
        ((formData.code?.trim() ?? '') !== '' &&
          formData.fees.length !== 0 &&
          isDateExist(formData.reissueDate) &&
          isDateExist(formData.rebookDate) &&
          isDateExist(formData.travelDate))
      );
    },
  };

  useImperativeHandle(ref, () => imperativeHandle);

  const mapDateInputWaiverInfoValue: (
    isWithinDate: boolean,
    value?: DateInputWithInfoValueType,
  ) => WaiverCodeDate = (isWithinDate, value) => {
    const result: WaiverCodeDate = [null, null, false];

    if (value instanceof Array) {
      value.forEach((value, index) => {
        result[index] = value;
      });
    }

    return [result[0], result[1], isWithinDate];
  };

  const mapWaiverCodeDate = (value?: WaiverCodeDate | null): DateInputWithInfoValueType => {
    const fieldValue: Date[] = [];
    value?.[0] && fieldValue.push(value[0]);
    value?.[1] && fieldValue.push(value[1]);

    return fieldValue;
  };

  const isDateExist = (value: WaiverCodeDate | null | undefined): boolean => {
    return !!value && ((!!value[0] && !!value[1]) || value[2] === true);
  };

  const getCodeTitle = (type: WaiverCodeType | null): string => {
    switch (type) {
      case WaiverCodeType.Normal:
        return getLabel('WaiversFormPage_ChangeFeeCodeTitle');
      case WaiverCodeType.Cabin:
        return getLabel('WaiversFormPage_CabinToCabinCodeTitle');
      case WaiverCodeType.Class:
        return getLabel('WaiversFormPage_ClassToClassCodeTitle');
      default:
        return '';
    }
  };

  return (
    <Accordion styled>
      <Accordion.Title active={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
        <Icon name="dropdown" />
        {getCodeTitle(props.data.type)}
        <InfoIcon infoMessage={props.infoMessage} />
      </Accordion.Title>
      <Accordion.Content active={isExpanded}>
        <Form>
          <TextInputWithInfo
            readonly={props.readonly}
            error={errors.code}
            value={props.data.code}
            label={getLabel('WaiversFormPage_WaiverCodeLabel')}
            infoMessage={getLabel('WaiversFormPage_WaiverCodeInfo')}
            required
            register={register('code', { required: getLabel('WaiversFormPage_WaiverCodeError') })}
          />
          <div className="waiver-code-date">
            <Controller
              name="rebookDate"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                const rebookDate = value;

                return (
                  <>
                    <DateInputWithInfo
                      readonly={props.readonly}
                      value={mapWaiverCodeDate(rebookDate)}
                      error={errors.rebookDate}
                      name={name}
                      onChange={(_e: React.SyntheticEvent | undefined, { value: dateValue }): void =>
                        onChange(mapDateInputWaiverInfoValue(!!rebookDate?.[2], dateValue))
                      }
                      label={getLabel('WaiversFormPage_WaiverCode_RebookDateLabel')}
                      infoMessage={getLabel('WaiversFormPage_WaiverCode_RebookDateInfo')}
                      range
                      required
                    />

                    <Checkbox
                      readOnly={props.readonly}
                      label={getLabel('WaiversFormPage_WaiverCode_DateWithinCheckboxLabel')}
                      checked={!!rebookDate?.[2]}
                      onChange={(_e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                        onChange([rebookDate?.[0], rebookDate?.[1], data.checked ?? false]);
                      }}
                      name="rebookDateWithin"
                    />
                  </>
                );
              }}
              rules={{
                validate: (value?: WaiverCodeDate | null) =>
                  isDateExist(value) || getLabel('WaiversFormPage_WaiverCode_RebookDateError'),
              }}
            />
          </div>
          <div className="waiver-code-date">
            <Controller
              name="reissueDate"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                const reissueDate = value;

                return (
                  <>
                    <DateInputWithInfo
                      readonly={props.readonly}
                      value={mapWaiverCodeDate(reissueDate)}
                      error={errors.reissueDate}
                      name={name}
                      onChange={(_e: React.SyntheticEvent | undefined, { value: dateValue }): void =>
                        onChange(mapDateInputWaiverInfoValue(!!reissueDate?.[2], dateValue))
                      }
                      label={getLabel('WaiversFormPage_WaiverCode_ReissueDateLabel')}
                      infoMessage={getLabel('WaiversFormPage_WaiverCode_ReissueDateInfo')}
                      range
                      required
                    />
                    <Checkbox
                      readOnly={props.readonly}
                      label={getLabel('WaiversFormPage_WaiverCode_DateWithinCheckboxLabel')}
                      checked={!!reissueDate?.[2]}
                      onChange={(_e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                        onChange([reissueDate?.[0], reissueDate?.[1], data.checked ?? false]);
                      }}
                      name="reissueDateWithin"
                    />
                  </>
                );
              }}
              rules={{
                validate: (value?: WaiverCodeDate | null) =>
                  isDateExist(value) || getLabel('WaiversFormPage_WaiverCode_ReissueDateError'),
              }}
            />
          </div>
          <div className="waiver-code-date">
            <Controller
              name="travelDate"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                const travelDate = value;

                return (
                  <>
                    <DateInputWithInfo
                      readonly={props.readonly}
                      value={mapWaiverCodeDate(travelDate)}
                      error={errors.travelDate}
                      name={name}
                      onChange={(_e: React.SyntheticEvent | undefined, { value: dateValue }): void =>
                        onChange(mapDateInputWaiverInfoValue(!!travelDate?.[2], dateValue))
                      }
                      label={getLabel('WaiversFormPage_WaiverCode_TravelDateLabel')}
                      infoMessage={getLabel('WaiversFormPage_WaiverCode_TravelDateInfo')}
                      range
                      required
                    />
                    <Checkbox
                      readOnly={props.readonly}
                      label={getLabel('WaiversFormPage_WaiverCode_DateWithinCheckboxLabel')}
                      checked={!!travelDate?.[2]}
                      onChange={(_e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                        onChange([travelDate?.[0], travelDate?.[1], data.checked ?? false]);
                      }}
                      name="travelDateWithin"
                    />
                  </>
                );
              }}
              rules={{
                validate: (value?: WaiverCodeDate | null) =>
                  isDateExist(value) || getLabel('WaiversFormPage_WaiverCode_TravelDateError'),
              }}
            />
          </div>
          <Controller
            name="fees"
            control={control}
            render={({ field: { onChange, value } }) => {
              const fees = value ?? [];

              return (
                <CheckboxGroupWithInfo
                  readonly={props.readonly}
                  error={errors.fees}
                  required
                  label={getLabel('WaiversFormPage_FeesLabel')}
                  infoMessage={getLabel('WaiversFormPage_FeesInfo')}
                  values={[
                    getCheckbox(
                      fees,
                      WaiverFee.ChangeFee,
                      getLabel('WaiversFromPage_Fee_ChangeFee'),
                      'fee-change-fee',
                    ),
                    getCheckbox(
                      fees,
                      WaiverFee.FareDifference,
                      getLabel('WaiversFromPage_Fee_FareDifference'),
                      'fee-fare-difference',
                    ),
                    getCheckbox(
                      fees,
                      WaiverFee.NameChangeFee,
                      getLabel('WaiversFromPage_Fee_NameChangeFee'),
                      'fee-name-change-fee',
                    ),
                    getCheckbox(
                      fees,
                      WaiverFee.RefundAvailable,
                      getLabel('WaiversFromPage_Fee_RefundAvailable'),
                      'fee-refund-available',
                    ),
                  ]}
                  onChange={onChange}
                />
              );
            }}
            rules={{
              validate: (value: WaiverFee[]) => value.length != 0 || getLabel('WaiversFromPage_FeeError'),
            }}
          />
          <TextAreaWithInfo
            readonly={props.readonly}
            label={getLabel('WaiversFormPage_NotesLabel')}
            infoMessage={getLabel('WaiversFormPage_NotesInfo')}
            register={register('notes')}
          />
        </Form>
      </Accordion.Content>
    </Accordion>
  );
};

export default forwardRef(WaiverCode);
