import React, { createContext, useContext, useState } from 'react';
import { ConfigurationModel } from '../models/Configuration/ConfigurationModel';

const defaultValue: [ConfigurationModel, (newConfig: ConfigurationModel) => void] = [
  { region: '', userPoolId: '' },
  () => {
    console.error('ConfigurationContext was not initialized');
  },
];

const ConfigurationContext = createContext(defaultValue);

interface Props {
  config: ConfigurationModel;
  children?: React.ReactElement | React.ReactElement[];
}

const ConfigurationProvider: React.FC<Props> = ({ children, config }) => {
  const [configuration, setConfiguration] = useState(config);

  return (
    <ConfigurationContext.Provider value={[configuration, setConfiguration]}>
      {children}
    </ConfigurationContext.Provider>
  );
};

const useConfiguration: () => [ConfigurationModel] = () => {
  const context = useContext(ConfigurationContext);
  if (context === defaultValue) {
    throw new Error('useConfiguration must be used within ConfigurationProvider');
  }
  const [configuration] = context;
  return [configuration];
};

export { ConfigurationProvider, useConfiguration };
