import React from 'react';
import './styles.scss';

interface Props {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextFilter: React.FC<Props> = (props) => (
  <div className="text-filter">
    <label>{props.label}</label>
    <input type="text" value={props.value} onChange={props.onChange} />
  </div>
);

export default TextFilter;
