import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { HubCapsule } from '@aws-amplify/core/lib-esm/Hub';
import React, { createContext, useContext, useEffect, useState } from 'react';

const defaultValue: [CognitoUser | undefined, (newUser: CognitoUser | undefined) => void] = [
  undefined,
  () => {
    console.error('UserContext was not initialized');
  },
];

const UserContext = createContext(defaultValue);

const UserProvider: React.FC<{ children?: React.ReactElement | React.ReactElement[] }> = ({ children }) => {
  const [user, setUser] = useState<CognitoUser | undefined>();

  const handleAuthWrapper = ({ payload }: HubCapsule): void => {
    switch (payload.event) {
      case 'signIn':
        void Auth.currentAuthenticatedUser().then((u) => setUser(u as CognitoUser));
        break;
      case 'signOut':
        setUser(undefined);
        break;
      default:
    }
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(setUser).catch(console.error);

    const authListener = Hub.listen('auth', handleAuthWrapper);

    return () => authListener();
  }, []);

  return <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>;
};

const useUser: () => [CognitoUser | undefined, (newUser: CognitoUser | undefined) => void] = () => {
  const context = useContext(UserContext);
  if (context === defaultValue) {
    throw new Error('useUser must be used within UserProvider');
  }

  return context;
};

export { UserProvider, useUser };
