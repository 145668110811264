import { useMemo, useCallback } from 'react';
import { Permission } from '../../enums/Permission';
import { Role, RolePrecedence } from '../../enums/Role';
import { rules } from './rules';
import { useUser } from './user';
import { getAllRoles } from './../../enums/Role';
import { minBy } from 'lodash';

export const usePermissions = (): ((permission: Permission) => boolean) => {
  const [user] = useUser();
  const role = useMemo(() => {
    const claims = user?.getSignInUserSession()?.getIdToken().payload;
    const cognitoGroups = claims && (claims['cognito:groups'] as string[]);
    if (cognitoGroups) {
      const userRoles = getAllRoles().filter((r) => cognitoGroups.includes(r));
      if (userRoles.length > 0) {
        return minBy(userRoles, (r) => RolePrecedence[r]) ?? Role.ReadOnly;
      }
    }
    return Role.ReadOnly;
  }, [user]);

  return useCallback(
    (permission: Permission) => {
      const permissions = rules[role];
      return permissions && permissions.has(permission);
    },
    [role],
  );
};
