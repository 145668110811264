import React from 'react';
import { getLabel } from './../../glossary';

import './styles.scss';
import { Icon } from 'semantic-ui-react';
import { usePermissions } from '../../hooks/User/usePermissions';
import { Permission } from '../../enums/Permission';

interface Props {
  onAdd: () => void;
  onExport: () => void;
  children?: React.ReactElement | React.ReactElement[];
}

const WaiversListBar: React.FC<Props> = (props) => {
  const hasPermission = usePermissions();
  return (
    <div className="waivers-list-bar">
      {props.children}
      <div className="waivers-list-buttons-bar">
        {hasPermission(Permission.WaiversCreate) && (
          <button className="add" onClick={props.onAdd} type="button">
            {getLabel('WaiversListPage_AddButton')}
          </button>
        )}
        {hasPermission(Permission.WaiversExport) && (
          <button onClick={props.onExport} type="button">
            {getLabel('WaiversListPage_ExportButton')} <Icon name="file"></Icon>
          </button>
        )}
      </div>
    </div>
  );
};

export default WaiversListBar;
