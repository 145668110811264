import { ExportMapper } from './../mappers/ExportMapper';
import { WaiverDto } from '../models/WaiverDto';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import Observable from 'zen-observable-ts';
import { WaiverExportModel } from '../models/ExportModel';
import { exportWaivers } from '../api/queries';
import { WaiversSearchResultDto } from '../models/WaiverSearchResult';

export class ExportService {
  private readonly DefaultPageSize: number = 1000;
  private readonly exportMapper: ExportMapper;

  constructor(exportMapper: ExportMapper) {
    this.exportMapper = exportMapper;
  }

  public async getData(): Promise<WaiverExportModel[]> {
    try {
      let from = 0;
      let dataResponse: GraphQLResult<{ searchWaivers: WaiversSearchResultDto }> | Observable<object>;
      let waivers: WaiverDto[] = [];
      let waiversDto: { searchWaivers: WaiversSearchResultDto } | undefined = undefined;

      do {
        dataResponse = await API.graphql(
          graphqlOperation(exportWaivers, { from: from, size: this.DefaultPageSize }),
        );

        waiversDto = (dataResponse as GraphQLResult<{ searchWaivers: WaiversSearchResultDto }>).data;

        if (!waiversDto) {
          window.location.replace('/error');
          throw new Error('Error while fetching data from API');
        }

        waivers = [...waivers, ...waiversDto.searchWaivers.items];
        from += this.DefaultPageSize;
      } while (waiversDto.searchWaivers.total > from);

      const resultList: WaiverExportModel[] = this.exportMapper.mapToExportData(waivers);
      resultList.forEach((w) => this.sortByAlphabetically(w));
      resultList.sort((theFirstWaiver, theSecondWaiver) => this.sortByDate(theFirstWaiver, theSecondWaiver));
      resultList.forEach((w) => this.escapeSpecialCharacters(w));
      return resultList;
    } catch (err) {
      console.error(`error while fetching data ${JSON.stringify((err as GraphQLResult).errors)}`);
      window.location.replace('/error');
      throw new Error('Error while fetching data from API');
    }
  }

  private sortByAlphabetically(waiver: WaiverExportModel): void {
    waiver.issuingAirlineCode = waiver.issuingAirlineCode?.toUpperCase();
    waiver.affectedAirportCodes = waiver.affectedAirportCodes?.map((x) => x.toUpperCase()).sort();
    waiver.affectedCountryCodes = waiver.affectedCountryCodes?.map((x) => x.toUpperCase()).sort();
    waiver.operatingAirlineCodes = waiver.operatingAirlineCodes?.map((x) => x.toUpperCase()).sort();
    waiver.ticketStocks = waiver.ticketStocks?.map((x) => x.toUpperCase()).sort();
    waiver.regions = waiver.regions?.map((x) => x.toUpperCase()).sort();
    waiver.tags = waiver.tags?.map((x) => x.toUpperCase()).sort();
    waiver.bookingOrMarketingAirlineCodes = waiver.bookingOrMarketingAirlineCodes
      ?.map((x) => x.toUpperCase())
      .sort();
  }

  private sortByDate(theFirstWaiver: WaiverExportModel, theSecondWaiver: WaiverExportModel): number {
    const theFirstDate = new Date(theFirstWaiver.airlineIssueDate as string);
    const theSecondDate = new Date(theSecondWaiver.airlineIssueDate as string);

    return theFirstDate > theSecondDate ? -1 : 1;
  }

  private escapeSpecialCharacters(waiver: WaiverExportModel): void {
    for (const key of Object.keys(waiver) as Array<keyof WaiverExportModel>) {
      if (typeof waiver[key] === 'string') {
        (waiver[key] as any) = this.escapeDoubleQuote(waiver[key] as string);
      }
      if (Array.isArray(waiver[key])) {
        (waiver[key] as Array<any>).forEach(
          (prop, index, arr) => typeof prop === 'string' && (arr[index] = this.escapeDoubleQuote(prop)),
        );
      }
    }
  }

  private escapeDoubleQuote(input: string): string {
    return input.replace(/"+/g, (match) => (match.length % 2 !== 0 ? match + '"' : match));
  }
}
