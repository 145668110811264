import React from 'react';
import { Popup } from 'semantic-ui-react';

interface Props {
  source: React.ReactElement<ChildProps>;
  content: React.ReactElement<ChildProps>;
}

type ChildProps = {
  children?: React.ReactElement<ChildProps>;
};

const PopupTableCell: React.FC<Props> = (props) => {
  return props.source.props.children?.props?.children ? (
    <Popup content={props.content} trigger={props.source} />
  ) : (
    <>{props.source}</>
  );
};

export default PopupTableCell;
