import { GroupType } from '@aws-sdk/client-cognito-identity-provider';
import { GroupViewModel } from '../models/ViewModels/GroupViewModel';

export class GroupsMapper {
  public mapFromDtoList(
    groups: GroupType[],
    getUsernamesByGroupName: (userName?: string) => string[],
  ): GroupViewModel[] {
    return groups.map((group) => {
      return {
        name: group.GroupName,
        usersNames: getUsernamesByGroupName(group.GroupName),
        precedence: group.Precedence,
      };
    });
  }
}
