import React from 'react';
import { FieldError } from 'react-hook-form/dist/types';
import { Checkbox, Form, Label } from 'semantic-ui-react';

import InfoIcon from '../InfoIcon';

export interface Props {
  value?: boolean | null;
  label: string;
  name: string;
  infoMessage?: string;
  onChange?: (data: boolean) => void;
  disabled?: boolean;
  error?: FieldError;
  readonly?: boolean;
}

const CheckboxWithInfo: React.FC<Props> = (props) => {
  return (
    <Form.Group widths="equal">
      <Form.Field width="15">
        <Checkbox
          name={props.name}
          label={props.label}
          checked={props.value ?? false}
          onChange={(_e, { checked }) => {
            props.onChange?.(checked ?? false);
          }}
          disabled={props.disabled}
          readOnly={props.readonly}
        />
        <InfoIcon infoMessage={props.infoMessage} left />
        {props.error && props.error.message && (
          <Form.Field className="validation-error">
            <Label basic color="red" pointing>
              {props.error.message}
            </Label>
          </Form.Field>
        )}
      </Form.Field>
    </Form.Group>
  );
};

export default CheckboxWithInfo;
