import React from 'react';
import './styles.scss';

interface Props<T> {
  label: string;
  valuesMap: [string, T][];
  getOnChangeHandler: (value: T) => (data: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: (value: T) => boolean;
}

const CheckboxGroupFilter = <T,>(props: Props<T>): React.ReactElement => (
  <div className="filter">
    <label>{props.label}</label>
    <div className="checkbox-group">
      {props.valuesMap.map(([name, value]) => (
        <div key={name.replace(/\s/g, '')} className="ui checkbox">
          <input
            type="checkbox"
            checked={props.isChecked(value)}
            onChange={props.getOnChangeHandler(value)}
          />
          <label>{name}</label>
        </div>
      ))}
    </div>
  </div>
);

export default CheckboxGroupFilter;
