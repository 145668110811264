import React from 'react';
import * as Semantic from 'semantic-ui-react';
import { ReactComponent as LoaderImage } from '../../assets/LoaderImage.svg';

const Loader = () => (
  <Semantic.Dimmer active>
    <div className="loader">
      <LoaderImage />
    </div>
    <Semantic.Loader />
  </Semantic.Dimmer>
);

export default Loader;
