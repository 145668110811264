import React from 'react';
import { Message } from 'semantic-ui-react';
import { ToastType } from '../../enums/ToastType';

import './styles.scss';

export interface ToastProps {
  hidden: boolean;
  type: ToastType;
  message: string;
  onDismiss: (hidden: boolean) => void;
}

export const ToastMessages: React.FC<ToastProps> = (props) => {
  const onDismiss = (hidden: boolean) => {
    props.onDismiss(hidden);
  };

  return (
    <div className="toast-messages-container">
      <Message size="small" color={props.type} hidden={props.hidden} onDismiss={() => onDismiss(true)}>
        <Message.Header>{props.message}</Message.Header>
      </Message>
    </div>
  );
};
