import React from 'react';

import './styles.scss';

interface Props {
  infoMessage?: string;
  left?: boolean;
}

const InfoIcon: React.FC<Props> = (props) => (
  <div className={`info-icon-container ${props.infoMessage ? '' : 'hidden'} ${props.left ? 'left' : ''}`}>
    <div className="info-icon">
      <span className="tooltip">{props.infoMessage}</span>
      <p>i</p>
    </div>
  </div>
);

export default InfoIcon;
