import React, { useState } from 'react';
import { Dropdown, Icon, Input } from 'semantic-ui-react';
import { ContainType } from '../../../enums/ContainType';
import { FullSearchFilterModel } from '../../../models/FilterModels/FullSearchFilterModel';

interface Props {
  fullSearchFilter?: FullSearchFilterModel;
  placeholder: string;
  valuesMap: [string, ContainType][];
  onSearch: (fullSearchFilter?: FullSearchFilterModel) => void;
}

const FullSearchFilter = (props: Props): React.ReactElement => {
  const validateFullSearchFilterModel = (
    fullSearchFilterToValidate?: FullSearchFilterModel,
  ): FullSearchFilterModel => {
    if (!fullSearchFilterToValidate?.containType) {
      return { ...fullSearchFilterToValidate, containType: ContainType.Contains };
    } else {
      return fullSearchFilterToValidate;
    }
  };

  const [fullSearchFilter, setFullSearchFilter] = useState<FullSearchFilterModel>(
    validateFullSearchFilterModel(props.fullSearchFilter),
  );

  const onKeyDownInput = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      props.onSearch(fullSearchFilter);
    }
  };

  const onClickDropDown = (data: ContainType): void => {
    const result = { ...fullSearchFilter, containType: data };
    setFullSearchFilter(result);
    result.searchText && props.onSearch(result);
  };

  const onReset = (): void => {
    setFullSearchFilter({});
    props.onSearch();
  };

  React.useEffect(() => {
    setFullSearchFilter(validateFullSearchFilterModel(props.fullSearchFilter));
  }, [props.fullSearchFilter]);

  return (
    <div className="full-search-container">
      <div className="full-search-item">
        <Input
          value={fullSearchFilter.searchText || ''}
          onChange={(event) => setFullSearchFilter({ ...fullSearchFilter, searchText: event.target.value })}
          onKeyDown={onKeyDownInput}
          icon={
            <Icon
              name="close"
              className="clean-icon"
              disabled={!fullSearchFilter.searchText}
              circular
              link
              onClick={onReset}
            />
          }
          label={
            <Dropdown icon="filter" basic>
              <Dropdown.Menu>
                {props.valuesMap.map(([name, value]) => (
                  <Dropdown.Item
                    key={name}
                    text={name}
                    icon={value === fullSearchFilter.containType ? 'check' : ''}
                    onClick={() => onClickDropDown(value)}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          }
          placeholder={props.placeholder}
        ></Input>
      </div>
    </div>
  );
};

export default FullSearchFilter;
