import React, { SyntheticEvent } from 'react';
import { Select, Form, Label } from 'semantic-ui-react';
import { FieldError } from 'react-hook-form/dist/types';

export interface SelectOption {
  key: string;
  value: string | number;
  text: string;
}

export type SelectValue = { value?: string | number | boolean | (string | number | boolean)[] };

interface SelectWithInfoProps {
  label: string;
  disabled?: boolean;
  options: SelectOption[];
  error?: FieldError;
  onChange: (e: SyntheticEvent, { value }: SelectValue) => void;
  required?: boolean;
  defaultValue?: string;
}

const SelectWithInfo: React.FC<SelectWithInfoProps> = (props) => {
  return (
    <Form.Group widths="equal">
      <Form.Field
        error={props.error !== undefined}
        required={props.required}
        width="15"
        disabled={props.disabled}
      >
        <label>{props.label}</label>
        <Select
          disabled={props.disabled}
          options={props.options}
          defaultValue={props.defaultValue || ''}
          ref={undefined}
          onChange={props.onChange}
          error={!!props.error}
          search={true}
        />
        {(props.error as FieldError)?.message && (
          <Label basic color="red" pointing>
            {(props.error as FieldError).message}
          </Label>
        )}
      </Form.Field>
    </Form.Group>
  );
};

export default SelectWithInfo;
