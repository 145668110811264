import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { QuickFilter } from '../enums/QuickFilter';
import { WaiverState } from '../enums/WaiverState';
import { QuickFilterModel } from '../models/FilterModels/QuickFilterModel';
import { PanelFilterModel } from '../models/FilterModels/PanelFilterModel';

export class WaiverFilterBuilder {
  public static async syncQuickFilters(
    filterModel: PanelFilterModel,
    quickfiltersToSync: QuickFilterModel,
  ): Promise<QuickFilterModel> {
    const setQuickFilters: Set<QuickFilter> = new Set(quickfiltersToSync.quickFilters);

    this.syncStateQuickFilters(filterModel, setQuickFilters);
    this.syncIsGlobalQuickFilters(filterModel, setQuickFilters);
    this.syncLastSevenDaysQuickFilters(filterModel, setQuickFilters);
    await this.syncMyItemsQuickFilters(filterModel, setQuickFilters);

    quickfiltersToSync.quickFilters = Array.from(setQuickFilters);
    return quickfiltersToSync;
  }

  private static syncStateQuickFilters(
    filterModel: PanelFilterModel,
    setQuickFilters: Set<QuickFilter>,
  ): void {
    if (filterModel.state === WaiverState.Active) {
      setQuickFilters.add(QuickFilter.Active);
      setQuickFilters.delete(QuickFilter.Inactive);
    } else if (filterModel.state === WaiverState.Inactive) {
      setQuickFilters.add(QuickFilter.Inactive);
      setQuickFilters.delete(QuickFilter.Active);
    } else {
      setQuickFilters.delete(QuickFilter.Active);
      setQuickFilters.delete(QuickFilter.Inactive);
    }
  }

  private static syncGenericQuickFilter(
    condition: boolean | undefined,
    filter: QuickFilter,
    setQuickFilters: Set<QuickFilter>,
  ): void {
    condition ? setQuickFilters.add(filter) : setQuickFilters.delete(filter);
  }

  private static syncIsGlobalQuickFilters(
    filterModel: PanelFilterModel,
    setQuickFilters: Set<QuickFilter>,
  ): void {
    this.syncGenericQuickFilter(filterModel.isGlobal, QuickFilter.Global, setQuickFilters);
  }

  private static syncLastSevenDaysQuickFilters(
    filterModel: PanelFilterModel,
    setQuickFilters: Set<QuickFilter>,
  ): void {
    const dateRangeSevenDays = this.getDateRangeSevenDays();
    const isSetLastSevenDays =
      filterModel.createdUpdatedDate?.length === dateRangeSevenDays.length &&
      filterModel.createdUpdatedDate[0]?.getTime() === dateRangeSevenDays[0]?.getTime() &&
      filterModel.createdUpdatedDate[1]?.getTime() === dateRangeSevenDays[1]?.getTime();
    this.syncGenericQuickFilter(isSetLastSevenDays, QuickFilter.LastSevenDays, setQuickFilters);
  }

  private static async syncMyItemsQuickFilters(
    filterModel: PanelFilterModel,
    setQuickFilters: Set<QuickFilter>,
  ): Promise<void> {
    const createdByCurrentUser =
      filterModel.createdBy === ((await Auth.currentAuthenticatedUser()) as CognitoUser).getUsername();
    this.syncGenericQuickFilter(createdByCurrentUser, QuickFilter.MyItems, setQuickFilters);
  }

  public static async syncFilters(
    quickFilterModel: QuickFilterModel,
    previousQuickFilterModel: QuickFilterModel,
    filtersToSync: PanelFilterModel,
  ): Promise<PanelFilterModel> {
    this.syncStateFilters(quickFilterModel, filtersToSync);

    this.syncGenericFilter(
      QuickFilter.Global,
      quickFilterModel,
      previousQuickFilterModel,
      filtersToSync,
      'isGlobal',
      true,
    );
    this.syncGenericFilter(
      QuickFilter.LastSevenDays,
      quickFilterModel,
      previousQuickFilterModel,
      filtersToSync,
      'createdUpdatedDate',
      this.getDateRangeSevenDays(),
    );
    this.syncGenericFilter(
      QuickFilter.MyItems,
      quickFilterModel,
      previousQuickFilterModel,
      filtersToSync,
      'createdBy',
      ((await Auth.currentAuthenticatedUser()) as CognitoUser).getUsername(),
    );
    return filtersToSync;
  }

  private static syncStateFilters(quickFilterModel: QuickFilterModel, filtersToSync: PanelFilterModel) {
    if (
      quickFilterModel.quickFilters?.includes(QuickFilter.Active) &&
      !quickFilterModel.quickFilters?.includes(QuickFilter.Inactive)
    ) {
      filtersToSync.state = WaiverState.Active;
    } else if (
      !quickFilterModel.quickFilters?.includes(QuickFilter.Active) &&
      quickFilterModel.quickFilters?.includes(QuickFilter.Inactive)
    ) {
      filtersToSync.state = WaiverState.Inactive;
    } else {
      filtersToSync.state = undefined;
    }
  }

  private static syncGenericFilter(
    filter: QuickFilter,
    quickFilters: QuickFilterModel,
    previousQuickfilters: QuickFilterModel,
    filtersToSync: PanelFilterModel,
    fieldName: keyof PanelFilterModel,
    value: PanelFilterModel[keyof PanelFilterModel],
  ) {
    if (quickFilters.quickFilters?.includes(filter)) {
      Object.assign(filtersToSync, { [fieldName]: value });
    } else if (previousQuickfilters.quickFilters?.includes(filter)) {
      filtersToSync[fieldName] = undefined;
    }
  }

  public static getDateRangeSevenDays(): [Date, Date] {
    const getDateSomeDaysAgo = (daysAgo?: number): Date => {
      const dateNow = new Date();
      dateNow.setHours(0, 0, 0, 0);
      daysAgo && dateNow.setDate(dateNow.getDate() - daysAgo);
      return dateNow;
    };
    return [getDateSomeDaysAgo(7), getDateSomeDaysAgo()];
  }
}
