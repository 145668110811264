import React from 'react';
import { getLabel } from './../../glossary';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import logoWhite from '../../assets/logo-white.svg';

import './styles.scss';

interface Props {
  white?: boolean;
}

const Logo: React.FC<Props> = ({ white }) => {
  return (
    <Link to="/" className={`logoWrapper ${white ? 'whiteLogo' : ''}`} data-testid="logo-wrapper">
      <div className="container-logo">
        <img className="logo" src={white ? logoWhite : logo} alt="logo" data-testid="logo-image" />
        <span className="slogan" data-testid="logo-slogan">
          {getLabel('Logo_Slogan')}
        </span>
      </div>
      <div className="container-projectName">
        <span className="projectName" data-testid="logo-projectName">
          {getLabel('Logo_ProjectName')}
        </span>
      </div>
    </Link>
  );
};

export default Logo;
