import { getLabel } from './../../glossary';
import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { ModalAction } from '../../enums/ModalAction';

export type ModalWindowProps = {
  header?: string;
  message: string;
  open: boolean;
  actions: ModalAction[];
  callback: (action: ModalAction) => void;
};

const isPositiveAction = (action: ModalAction) =>
  action === ModalAction.Ok || action === ModalAction.YesLeave || action === ModalAction.YesConfirm;

export const ModalWindow: React.FC<ModalWindowProps> = ({ header, message, open, actions, callback }) => {
  return (
    <Modal size="small" open={open}>
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Content>{message}</Modal.Content>
      <Modal.Actions>
        {actions?.map((action: ModalAction, index: number) => {
          return (
            <Button
              key={index}
              positive={isPositiveAction(action)}
              negative={!isPositiveAction(action)}
              onClick={() => callback(action)}
            >
              {getLabel(`ModalWindow_Action_${action}`)}
            </Button>
          );
        })}
      </Modal.Actions>
    </Modal>
  );
};
