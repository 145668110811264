import { WaiverCodeType } from '../../enums/WaiverCodeType';
import { WaiverFee } from '../../enums/WaiverFee';

export type WaiverCodeDate = [Date | null, Date | null, boolean];

export type WaiverCodeViewModel = {
  type: WaiverCodeType;
  code?: string | null;
  fees: WaiverFee[];
  notes?: string | null;
  rebookDate?: WaiverCodeDate | null;
  reissueDate?: WaiverCodeDate | null;
  travelDate?: WaiverCodeDate | null;
};

export const getEmptyWaiverCode = (type: WaiverCodeType): WaiverCodeViewModel => ({
  type: type,
  code: '',
  fees: [],
  rebookDate: null,
  reissueDate: null,
  travelDate: null,
});
