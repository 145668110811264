import { Auth } from 'aws-amplify';
import React, { SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { Button, Form } from 'semantic-ui-react';
import { getLabel } from './../../../glossary';
import { useGlobalComponents } from '../../GlobalComponentsProvider';
import { ToastType } from '../../../enums/ToastType';

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const history: History = useHistory();
  const { showToast } = useGlobalComponents();

  const { email } = history.location.state as { email: string };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      history.push('/');
    } catch (error) {
      showToast((error as Error)?.message, ToastType.Error);
    }
  };

  const handleSendAgain = async () => {
    try {
      await Auth.forgotPassword(email);
    } catch (error) {
      showToast((error as Error)?.message, ToastType.Error);
    }
  };

  return (
    <Form
      onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
        void handleSubmit(event);
      }}
      className="form"
    >
      <h3>{getLabel('Authentication_PasswordReset')}</h3>
      <Form.Field>
        <label>{getLabel('Authentication_Code')}</label>
        <input
          data-testid="reset-password-code"
          placeholder={getLabel('Authentication_CodePlaceholder')}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>{getLabel('Authentication_NewPassword')}</label>
        <input
          data-testid="reset-password-password"
          placeholder={getLabel('Authentication_NewPasswordPlaceholder')}
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Field>
      <div className="sign-in-container">
        <Button className="link-text" onClick={() => void handleSendAgain()} data-testid="send-again-link">
          {getLabel('Authentication_SendAgain')}
        </Button>
        <Button
          primary
          type="submit"
          size="medium"
          className="sign-in-button"
          data-testid="reset-password-button"
          fluid
          color="blue"
        >
          {getLabel('Authentication_ChangePassword')}
        </Button>
      </div>
    </Form>
  );
};

export default ResetPassword;
