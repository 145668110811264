import { Permission } from '../../enums/Permission';
import { Role } from '../../enums/Role';

const readonlyPermissions = new Set([Permission.WaiversRead]);
const adminPermissions = new Set([
  ...Array.from(readonlyPermissions),
  Permission.WaiversExport,
  Permission.WaiversUpdate,
  Permission.WaiversCreate,
  Permission.WaiversDelete,
]);

const superAdminPermissions = new Set([
  ...Array.from(adminPermissions),
  Permission.UsersRead,
  Permission.UsersCreate,
  Permission.UsersUpdate,
  Permission.UsersDelete,
]);

export const rules = {
  [Role.ReadOnly]: readonlyPermissions,
  [Role.Admin]: adminPermissions,
  [Role.SuperAdmin]: superAdminPermissions,
};
