import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { getLabel } from './../../../glossary';
import { useGlobalComponents } from '../../GlobalComponentsProvider';
import { ToastType } from '../../../enums/ToastType';
import { CognitoUser } from 'amazon-cognito-identity-js';

interface Props {
  userWithoutPassword: CognitoUser | undefined;
  onCancel: () => void;
}

const ChangePassword = ({ userWithoutPassword, onCancel }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const loadingClassName = isLoading ? 'loading' : '';

  const { showToast } = useGlobalComponents();

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await Auth.completeNewPassword(userWithoutPassword, password);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      (error as Error)?.message && showToast((error as Error)?.message, ToastType.Error);
    }
  };

  return (
    <Form onSubmit={() => void handleSubmit()} className="form">
      <h3>{getLabel('Authentication_ChangePasswordTitle')}</h3>
      <Form.Field>
        <label>{getLabel('Authentication_Password')}</label>
        <input
          data-testid="change-password-input"
          placeholder={getLabel('Authentication_NewPasswordPlaceholder')}
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Field>
      <div className="sign-in-container">
        <Button className="link-text" onClick={onCancel} data-testid="back-link">
          {getLabel('Authentication_GoBack')}
        </Button>
        <Button
          data-testid="change-password-button"
          primary
          type="submit"
          size="medium"
          className={`sign-in-button ${loadingClassName}`}
        >
          {getLabel('Authentication_Change')}
        </Button>
      </div>
    </Form>
  );
};

export default ChangePassword;
