import React from 'react';
import { Form } from 'semantic-ui-react';

import { WaiverState } from '../../enums/WaiverState';
import { getLabel } from './../../glossary';

import './styles.scss';

interface Props {
  readonly?: boolean;
  waiverState?: WaiverState;
  onChange: (value: WaiverState) => void;
}

const StateToggle: React.FC<Props> = ({ readonly, waiverState, onChange }) => {
  const isActiveState = (state?: WaiverState): boolean => state === WaiverState.Active;

  return (
    <Form.Group widths="equal" className="state-container">
      <Form.Field width="15">
        <label>{getLabel('WaiversFormPage_StateLabel')}</label>
        <div className="toggle">
          <button
            className={`${isActiveState(waiverState) ? 'active' : 'inactive'} ${readonly ? 'readonly' : ''}`}
            type="button"
            onClick={() => !readonly && onChange(WaiverState.Active)}
          >
            {getLabel('WaiverState_Active')}
          </button>

          <button
            className={`${isActiveState(waiverState) ? 'inactive' : 'active'} ${readonly ? 'readonly' : ''}`}
            type="button"
            onClick={() => !readonly && onChange(WaiverState.Inactive)}
          >
            {getLabel('WaiverState_Inactive')}
          </button>
        </div>
      </Form.Field>
    </Form.Group>
  );
};

export default StateToggle;
