import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import { FieldError, Merge } from 'react-hook-form/dist/types';
import InfoIcon from '../InfoIcon';
import { getDateStringLabel } from '../WaiversForm/helpers';
import { validateDateInputValue } from './helpers';

export type DateInputWithInfoValueType = Date | Date[] | null;

interface Props {
  name?: string;
  label: string;
  infoMessage?: string;
  onChange: (event: React.SyntheticEvent | undefined, data: SemanticDatepickerProps) => void;
  error?:
    | FieldError
    | Merge<FieldError, [(FieldError | undefined)?, (FieldError | undefined)?, (FieldError | undefined)?]>;
  range?: boolean;
  value?: DateInputWithInfoValueType;
  required?: boolean;
  readonly?: boolean;
}

const DateInputWithInfo: React.FC<Props> = (props) => {
  const value = validateDateInputValue(props.value);
  const dateInputType = props.range ? 'range' : 'basic';

  return (
    <Form.Group>
      <Form.Field
        className={props.range ? 'datepicker daterange' : 'datepicker'}
        error={props.error !== undefined}
        required={props.required}
      >
        <label htmlFor={props.name}>{props.label}</label>
        <InfoIcon infoMessage={props.infoMessage} />
        <div className="datepicker-wrapper">
          {props.readonly ? (
            getDateStringLabel(value)
          ) : (
            <SemanticDatepicker
              value={value}
              pointing="top left"
              name={props.name}
              clearable
              type={dateInputType}
              onChange={props.onChange}
            />
          )}
        </div>
        {props.error && props.error.message && (
          <Form.Field className="validation-error">
            <Label basic color="red" pointing>
              {props.error.message}
            </Label>
          </Form.Field>
        )}
      </Form.Field>
    </Form.Group>
  );
};

export default DateInputWithInfo;
