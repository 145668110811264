import React, { useEffect, useState } from 'react';
import { Dropdown, Grid, Icon, Pagination, Table } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { getLabel } from './../../glossary';
import { Link } from 'react-router-dom';
import { WaiversSearchResult } from '../../models/WaiverSearchResult';
import DateColumn from '../DateColumn';
import SortedHeaderCell from '../SortedHeaderCell';
import PopupTableCell from '../PopupTableCell';
import { WaiverState } from '../../enums/WaiverState';
import { SortDirection } from '../../enums/SortDirection';

interface Props {
  page: number;
  count: number;
  waivers: WaiversSearchResult | null;
  sortField: string | null;
  sortDirection: SortDirection | null;
  onPaginationChange: (page: number, count: number) => void;
  onSortChange: (field: string, direction: SortDirection) => void;
}

function WaiversList(props: Readonly<Props>): React.ReactElement {
  const [page, setPage] = useState(props.page);
  const [count, setCount] = useState(props.count);
  const [sortField, setSortField] = useState(props.sortField);
  const [sortDirection, setSortDirection] = useState(props.sortDirection);
  const waivers = props.waivers?.items;

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  const onPageChanged = (value: number) => {
    setPage(value);
    props.onPaginationChange(value, count);
  };

  const onCountChanged = (value: number) => {
    setCount(value);
    props.onPaginationChange(page, value);
  };

  const getCurrentPageBounds = (value: number): string | undefined => {
    if (props.waivers) {
      const left = count * (value - 1) + 1;
      const upperBound = count * value;
      const right = upperBound > props.waivers.total ? props.waivers.total : upperBound;
      return `${left}-${right}`;
    }
  };

  const getSortDirection = (field: string): SortDirection | null => {
    if (field !== sortField) {
      return null;
    }

    return sortDirection === SortDirection.Desc ? SortDirection.Desc : SortDirection.Asc;
  };

  const onSortChanged = (field: string, direction: SortDirection) => {
    setSortField(field);
    setSortDirection(direction);
    props.onSortChange(field, direction);
  };

  return (
    <div className="waivers-table">
      <Grid celled="internally">
        <Grid.Row>
          <Grid.Column width={16}>
            <Table celled fixed>
              <Table.Header>
                <Table.Row>
                  <SortedHeaderCell
                    className="issue-date-column"
                    label={getLabel('WaiversList_IssueDate')}
                    field="airlineIssueDate"
                    onSortChange={onSortChanged}
                    direction={getSortDirection('airlineIssueDate')}
                  />
                  <SortedHeaderCell
                    label={getLabel('WaiversList_StateTitle')}
                    field="title"
                    width={3}
                    onSortChange={onSortChanged}
                    direction={getSortDirection('title')}
                  />
                  <SortedHeaderCell
                    className="issuing-airline-column"
                    label={getLabel('WaiversList_Airline')}
                    field="issuingAirlineCode"
                    width={1}
                    onSortChange={onSortChanged}
                    direction={getSortDirection('issuingAirlineCode')}
                  />
                  <SortedHeaderCell
                    label={getLabel('WaiversList_Regions')}
                    field="regions"
                    width={1}
                    onSortChange={onSortChanged}
                    direction={getSortDirection('regions')}
                  />
                  <SortedHeaderCell
                    className="airports-column"
                    label={getLabel('WaiversList_Airports')}
                    field="affectedAirportCodes"
                    onSortChange={onSortChanged}
                    direction={getSortDirection('affectedAirportCodes')}
                  />
                  <SortedHeaderCell
                    className="contries-column"
                    label={getLabel('WaiversList_Countries')}
                    field="affectedCountryCodes"
                    onSortChange={onSortChanged}
                    direction={getSortDirection('affectedCountryCodes')}
                  />
                  <Table.HeaderCell width={2}>{getLabel('WaiversList_ImpactedTravelDates')}</Table.HeaderCell>
                  <SortedHeaderCell
                    label={getLabel('WaiversList_WaiverCodes')}
                    field="waiverCodes"
                    width={2}
                    onSortChange={onSortChanged}
                    direction={getSortDirection('waiverCodes')}
                  />
                  <SortedHeaderCell
                    label={getLabel('WaiversList_Tags')}
                    field="tags"
                    width={1}
                    onSortChange={onSortChanged}
                    direction={getSortDirection('tags')}
                  />
                  <Table.HeaderCell className="created-column">
                    {getLabel('WaiversList_Created')}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="edit-column" />
                </Table.Row>
              </Table.Header>
              <Table.Body data-testid="waivers-list">
                {waivers && waivers.length !== 0 ? (
                  waivers.map((waiver) => (
                    <Table.Row key={waiver.id}>
                      <PopupTableCell
                        source={
                          <Table.Cell data-testid="issueDate">
                            <span>
                              <DateColumn date={waiver.airlineIssueDate} />
                            </span>
                          </Table.Cell>
                        }
                        content={
                          <span>
                            <DateColumn date={waiver.airlineIssueDate} />
                          </span>
                        }
                      />

                      <Table.Cell data-testid="state-title">
                        <div className="state-title-cell">
                          <div className="state">
                            {waiver.state === WaiverState.Active ? (
                              <Icon className="active" name="circle" />
                            ) : (
                              <Icon className="inactive" name="circle" />
                            )}
                          </div>
                          <div>
                            <Link to={`/waivers/${waiver.id}`}>{waiver.title}</Link>
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell data-testid="airline"> {waiver.issuingAirlineCode}</Table.Cell>

                      <PopupTableCell
                        source={
                          <Table.Cell data-testid="regions">
                            <span>{waiver.regions}</span>
                          </Table.Cell>
                        }
                        content={<span>{waiver.regions}</span>}
                      />
                      <PopupTableCell
                        source={
                          <Table.Cell data-testid="airports">
                            <div className="truncate-cell">{waiver.affectedAirportCodes}</div>
                          </Table.Cell>
                        }
                        content={<div className="truncate-cell">{waiver.affectedAirportCodes}</div>}
                      />
                      <PopupTableCell
                        source={
                          <Table.Cell data-testid="countries">
                            <div className="truncate-cell">{waiver.affectedCountryCodes}</div>
                          </Table.Cell>
                        }
                        content={<div className="truncate-cell">{waiver.affectedCountryCodes}</div>}
                      />
                      <Table.Cell data-testid="impactedTravelDate">
                        {waiver.impactedTravelDates &&
                          waiver.impactedTravelDates.map((date, index) => (
                            <div className="date-range-container" key={`${waiver.id}-${index}`}>
                              <DateColumn date={date[0]} /> - <DateColumn date={date[1]} />
                            </div>
                          ))}
                      </Table.Cell>
                      <PopupTableCell
                        source={
                          <Table.Cell data-testid="waiverCodes">
                            <div className="truncate-cell">{waiver.waiverCodes}</div>
                          </Table.Cell>
                        }
                        content={<div className="truncate-cell">{waiver.waiverCodes}</div>}
                      />
                      <PopupTableCell
                        source={
                          <Table.Cell data-testid="tags">
                            <span>{waiver.tags}</span>
                          </Table.Cell>
                        }
                        content={<span>{waiver.tags}</span>}
                      />
                      <PopupTableCell
                        source={
                          <Table.Cell data-testid="created">
                            <span>
                              {waiver.createdBy} <DateColumn date={waiver.createDate} />
                            </span>
                          </Table.Cell>
                        }
                        content={
                          <span>
                            {waiver.createdBy} <DateColumn date={waiver.createDate} />
                          </span>
                        }
                      />

                      <Table.Cell className="edit-column-cell">
                        <Link to={`/waivers/${waiver.id}`} data-testid="edit-link">
                          <Icon name="edit" />
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={16} textAlign="center">
                      {getLabel('WaiversList_Nowaiversfound')}
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
              {!waivers || waivers.length === 0 ? null : (
                <Table.Footer className="footer">
                  <Table.Row>
                    <Table.HeaderCell colSpan={16}>
                      <div className="pagination-container">
                        <Pagination
                          activePage={page}
                          totalPages={Math.ceil((props.waivers?.total || 0) / count)}
                          onPageChange={(_, data) => onPageChanged(+(data.activePage || 0))}
                        />
                        <div className="pagination-info">
                          {getCurrentPageBounds(page)} of {props.waivers?.total}
                        </div>
                        <div className="show-per-page">
                          <label>{getLabel('WaiversList_ShowPerPage')}</label>
                          <Dropdown value={count} text={count.toString()}>
                            <Dropdown.Menu>
                              {[25, 50, 100].map((value) => (
                                <Dropdown.Item
                                  key={value}
                                  text={value}
                                  value={value}
                                  onClick={() => onCountChanged(value)}
                                />
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default WaiversList;
