import React, { useEffect } from 'react';

interface Props {
  onUserLogoutRedirect: () => void;
}

const LogoutPage: React.FC<Props> = ({ onUserLogoutRedirect }) => {
  useEffect(() => {
    onUserLogoutRedirect();
  });

  return <></>;
};

export default LogoutPage;
