import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ContainType } from '../enums/ContainType';
import { QuickFilter } from '../enums/QuickFilter';
import { Region, RegionNameMap } from '../enums/Region';
import { SortDirection } from '../enums/SortDirection';
import { Tag } from '../enums/Tag';
import { WaiverCodeType } from '../enums/WaiverCodeType';
import { FullSearchFilterModel } from '../models/FilterModels/FullSearchFilterModel';
import { QuickFilterModel } from '../models/FilterModels/QuickFilterModel';
import { WaiverCodeDate, WaiverCodeViewModel } from '../models/ViewModels/WaiverCodeViewModel';
import { WaiverFormViewModel } from '../models/ViewModels/WaiverFormViewModel';
import { WaiverListViewModel } from '../models/ViewModels/WaiverListViewModel';
import { WaiverDto } from '../models/WaiverDto';
import { WaiverSearchModel } from '../models/WaiverSearchModel';
import {
  CreateWaiversInput,
  DateRangeInput,
  SearchWaiversQueryVariables,
  TicketStockInput,
  UpdateWaiversInput,
  WaiverCodeDateInput,
  WaiverCodeInput,
  WaiverFilterInput,
} from '../ScaffoldedAPI';
import { getLabel } from '../glossary';
import { WaiverState } from '../enums/WaiverState';

export class WaiversMapper {
  private readonly MultipleSpacesPattern: RegExp = /,\s*/;

  private defaultFormDate: WaiverFormViewModel = {
    state: WaiverState.Inactive,
    flightType: null,
    isGlobal: false,
    regions: [],
    tags: [],
    attachments: [],
    createDate: new Date(),
    createdBy: '',
    title: '',
    waiverCodes: [],
    affectedAirportCodes: '',
    affectedCountryCodes: '',
    airlineIssueDate: null,
    bookingOrMarketingAirlineCodes: '',
    impactedTravelDates: null,
    issuingAirlineCode: '',
    operatingAirlineCodes: '',
    osiRequirements: '',
    placements: [],
    refundInfo: '',
    reissueInfo: '',
    sourceWaiverURL: '',
    otherTags: '',
    ticketedDates: null,
    ticketStocks: '',
    updateDate: null,
    updatedBy: '',
  };

  private defaultString(value?: string | null) {
    return value ?? '';
  }

  public getDefaultValue() {
    return { ...this.defaultFormDate };
  }

  public mapToDefaultValues(waiver: WaiverFormViewModel): WaiverFormViewModel {
    return {
      ...waiver,
      flightType: waiver.flightType ?? null,
      isGlobal: waiver.isGlobal ?? false,
      regions: waiver.regions ?? [],
      tags: waiver.tags ?? [],
      attachments: waiver.attachments ?? [],
      createdBy: this.defaultString(waiver.createdBy),
      title: this.defaultString(waiver.title),
      waiverCodes: waiver.waiverCodes ?? [],
      affectedAirportCodes: this.defaultString(waiver.affectedAirportCodes),
      affectedCountryCodes: this.defaultString(waiver.affectedCountryCodes),
      bookingOrMarketingAirlineCodes: this.defaultString(waiver.bookingOrMarketingAirlineCodes),
      issuingAirlineCode: this.defaultString(waiver.issuingAirlineCode),
      operatingAirlineCodes: this.defaultString(waiver.operatingAirlineCodes),
      osiRequirements: this.defaultString(waiver.osiRequirements),
      placements: waiver.placements ?? [],
      refundInfo: this.defaultString(waiver.refundInfo),
      reissueInfo: this.defaultString(waiver.reissueInfo),
      sourceWaiverURL: this.defaultString(waiver.sourceWaiverURL),
      otherTags: this.defaultString(waiver.otherTags),
      ticketStocks: this.defaultString(waiver.ticketStocks),
      updatedBy: this.defaultString(waiver.updatedBy),
    };
  }

  public mapFromDto(waiver: WaiverDto): WaiverFormViewModel {
    const tagsValues = Object.values<string>(Tag);

    return {
      affectedAirportCodes: this.mapCodes(waiver.affectedAirportCodes),
      affectedCountryCodes: this.mapCodes(waiver.affectedCountryCodes),
      airlineIssueDate: this.convertStringToUtc(waiver.airlineIssueDate),
      attachments: waiver.attachments,
      bookingOrMarketingAirlineCodes: this.mapCodes(waiver.bookingOrMarketingAirlineCodes),
      createDate: new Date(waiver.createDate),
      createdBy: waiver.createdBy,
      flightType: waiver.flightType,
      impactedTravelDates: waiver.impactedTravelDates?.map((date) => this.extractDateRangeInput(date)),
      id: waiver.id,
      isGlobal: waiver.isGlobal,
      issuingAirlineCode: waiver.issuingAirlineCode,
      operatingAirlineCodes: this.mapCodes(waiver.operatingAirlineCodes),
      osiRequirements: waiver.osiRequirements,
      placements: waiver.placements,
      refundInfo: waiver.refundInfo,
      reissueInfo: waiver.reissueInfo,
      regions: waiver.regions,
      sourceWaiverURL: waiver.sourceWaiverURL,
      state: waiver.state,
      tags: waiver.tags?.filter((tag) => tagsValues.includes(tag)).map((tag) => tag as Tag),
      otherTags: this.mapCodes(waiver.tags?.filter((tag) => !tagsValues.includes(tag))),
      ticketedDates: waiver.ticketedDates?.map((date) => this.extractDateRangeInput(date)),
      ticketStocks: this.mapTicketStocks(waiver.ticketStocks),
      title: waiver.title,
      updateDate: waiver.updateDate && new Date(waiver.updateDate),
      updatedBy: waiver.updatedBy,
      waiverCodes: this.extractWaiverCodes(waiver.waiverCodes ?? []),
    } as WaiverFormViewModel;
  }

  public mapFromDtoList(waivers: WaiverDto[]): WaiverListViewModel[] {
    return waivers.map((waiver: WaiverDto) => {
      return {
        affectedAirportCodes: waiver.affectedAirportCodes?.join(', '),
        affectedCountryCodes: waiver.affectedCountryCodes?.join(', '),
        airlineIssueDate: waiver.airlineIssueDate && new Date(waiver.airlineIssueDate),
        createDate: new Date(waiver.createDate),
        createdBy: waiver.createdBy,
        id: waiver.id,
        issuingAirlineCode: waiver.issuingAirlineCode,
        state: waiver.state,
        ticketStocks: waiver.ticketStocks ?? [],
        title: waiver.title,
        regions: waiver.regions?.map((region) => getLabel(RegionNameMap[region])).join(', '),
        tags: waiver.tags?.join(', '),
        waiverCodes: waiver.waiverCodes
          ?.sort((left, right) => left.type - right.type)
          .map((code) => code.code)
          .join(', '),
        impactedTravelDates: waiver.impactedTravelDates?.map((date) => [
          date.startDate ? new Date(date.startDate) : null,
          date.endDate ? new Date(date.endDate) : null,
        ]),
      } as WaiverListViewModel;
    });
  }

  public async mapToCreateDto(model: WaiverFormViewModel): Promise<CreateWaiversInput> {
    const currentUser = (await Auth.currentAuthenticatedUser()) as CognitoUser;
    return this.nullifyFileds(true, {
      affectedAirportCodes: model.affectedAirportCodes?.split(this.MultipleSpacesPattern).sort(),
      affectedCountryCodes: model.affectedCountryCodes?.split(this.MultipleSpacesPattern).sort(),
      airlineIssueDate: this.convertDateToUtc(model.airlineIssueDate),
      attachments: model.attachments,
      bookingOrMarketingAirlineCodes: model.bookingOrMarketingAirlineCodes?.split(this.MultipleSpacesPattern),
      createDate: new Date().toISOString(),
      createdBy: currentUser.getUsername(),
      flightType: model.flightType,
      impactedTravelDates: model.impactedTravelDates?.map((date) => this.mapToDateRange(date)),
      id: model.id,
      isGlobal: model.isGlobal,
      issuingAirlineCode: model.issuingAirlineCode,
      operatingAirlineCodes: model.operatingAirlineCodes?.split(this.MultipleSpacesPattern),
      osiRequirements: model.osiRequirements,
      placements: model.placements,
      refundInfo: model.refundInfo,
      reissueInfo: model.reissueInfo,
      regions: model.regions,
      sourceWaiverURL: model.sourceWaiverURL,
      state: model.state,
      tags: [...(model.tags ?? []), ...(model.otherTags?.split(this.MultipleSpacesPattern) ?? [])],
      ticketedDates: model.ticketedDates?.map((date) => this.mapToDateRange(date)),
      ticketStocks: this.mapTicketStocksToDto(model.ticketStocks),
      title: model.title,
      waiverCodes:
        model.waiverCodes && this.mapToWaiverCodes(model.waiverCodes).filter((code) => !!code.code),
    }) as CreateWaiversInput;
  }

  public async mapToUpdateDto(model: WaiverFormViewModel): Promise<UpdateWaiversInput> {
    const currentUser = (await Auth.currentAuthenticatedUser()) as CognitoUser;
    return this.nullifyFileds(false, {
      affectedAirportCodes: model.affectedAirportCodes?.split(this.MultipleSpacesPattern).sort(),
      affectedCountryCodes: model.affectedCountryCodes?.split(this.MultipleSpacesPattern).sort(),
      airlineIssueDate: this.convertDateToUtc(model.airlineIssueDate),
      attachments: model.attachments,
      bookingOrMarketingAirlineCodes: model.bookingOrMarketingAirlineCodes?.split(this.MultipleSpacesPattern),
      createDate: model.createDate.toISOString(),
      createdBy: model.createdBy,
      flightType: model.flightType,
      impactedTravelDates: model.impactedTravelDates?.map((date) => this.mapToDateRange(date)),
      id: model.id,
      isGlobal: model.isGlobal,
      issuingAirlineCode: model.issuingAirlineCode,
      operatingAirlineCodes: model.operatingAirlineCodes?.split(this.MultipleSpacesPattern),
      osiRequirements: model.osiRequirements,
      placements: model.placements,
      refundInfo: model.refundInfo,
      reissueInfo: model.reissueInfo,
      regions: model.regions,
      sourceWaiverURL: model.sourceWaiverURL,
      state: model.state,
      tags: [...(model.tags ?? []), ...(model.otherTags?.split(this.MultipleSpacesPattern) ?? [])],
      ticketedDates: model.ticketedDates?.map((date) => this.mapToDateRange(date)),
      ticketStocks: this.mapTicketStocksToDto(model.ticketStocks),
      title: model.title,
      updateDate: new Date().toISOString(),
      updatedBy: currentUser.getUsername(),
      waiverCodes:
        model.waiverCodes && this.mapToWaiverCodes(model.waiverCodes).filter((code) => !!code.code),
    }) as UpdateWaiversInput;
  }

  public mapSearchModel(searchModel: WaiverSearchModel): SearchWaiversQueryVariables {
    const query = {
      size: searchModel.count,
      from: searchModel.count * (searchModel.page - 1),
      sortField: searchModel.sortField,
      sortDirection: searchModel.sortDirection === SortDirection.Desc ? 'desc' : 'asc',
      filter: {
        ...searchModel.filter,
        ...this.mapQuickFilter(searchModel.quickFilter),
        airlineIssueDate: this.mapDateRangeFilter(searchModel.filter.airlineIssueDate),
        createdUpdatedDate: this.mapDateRangeFilter(searchModel.filter.createdUpdatedDate),
      },
    };

    const keys = Object.keys(query.filter);
    const filtersMap: { [key: string]: unknown } = query.filter;
    keys.forEach((key) => {
      if (typeof filtersMap[key] !== 'boolean' && !filtersMap[key]) {
        filtersMap[key] = null;
      }
    });

    return query as SearchWaiversQueryVariables;
  }

  private mapQuickFilter(quickFilter: QuickFilterModel): WaiverFilterInput {
    const result: WaiverFilterInput = {};

    this.mapFullSearch(result, quickFilter.fullSearchFilter);
    this.mapIsManual(quickFilter, result);

    return result;
  }

  private mapFullSearch(result: WaiverFilterInput, fullSearchFilter?: FullSearchFilterModel) {
    if (!fullSearchFilter?.searchText) {
      return;
    }
    result.textToFullSearch = fullSearchFilter.searchText;
    result.isContainTextToFullSearch = fullSearchFilter.containType !== ContainType.DoesNotContain;

    this.mapRegionsToFullSearch(fullSearchFilter, result);
  }

  private mapRegionsToFullSearch(fullSearchFilter: FullSearchFilterModel, result: WaiverFilterInput) {
    const matchedRegions = Object.keys(Region)
      .map((regionKey) => Number(regionKey))
      .filter(
        (regionKey) =>
          fullSearchFilter.searchText &&
          !isNaN(regionKey) &&
          new RegExp(fullSearchFilter.searchText, 'i').exec(getLabel(RegionNameMap[Number(regionKey)])),
      );

    if (matchedRegions.length) {
      result.regionsToFullSearch = matchedRegions;
    }
  }

  private mapIsManual(quickFilter: QuickFilterModel, result: WaiverFilterInput) {
    if (
      quickFilter?.quickFilters?.includes(QuickFilter.Manual) &&
      !quickFilter?.quickFilters?.includes(QuickFilter.Automated)
    ) {
      result.isManual = true;
    } else if (
      !quickFilter?.quickFilters?.includes(QuickFilter.Manual) &&
      quickFilter?.quickFilters?.includes(QuickFilter.Automated)
    ) {
      result.isManual = false;
    }
  }

  private mapDateRangeFilter(dates: [Date | null, Date | null] | undefined): DateRangeInput | null {
    if (!dates || (!dates[0] && !dates[1])) {
      return null;
    }

    return {
      startDate: this.convertDateTimeFilterToUtcString(dates[0], 0, 0, 0),
      endDate: this.convertDateTimeFilterToUtcString(dates[1], 23, 59, 59),
    };
  }

  private convertDateTimeFilterToUtcString(
    date: Date | undefined | null,
    hrs: number,
    min: number,
    sec: number,
  ): string | null {
    const utcDate = this.convertDateToUtc(date);
    utcDate?.setUTCHours(hrs);
    utcDate?.setUTCMinutes(min);
    utcDate?.setUTCSeconds(sec);
    return utcDate ? utcDate.toISOString() : null;
  }

  private convertDateToUtc(date: Date | undefined | null): Date | undefined {
    if (date) {
      const utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
      return new Date(utcDate);
    }
  }

  private convertStringToUtc(dateString: string | null | undefined): Date | undefined {
    const date = new Date(dateString as string);
    if (dateString && !isNaN(date.getDate())) {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds(),
      );
    }

    return undefined;
  }

  private mapTicketStocks(ticketStocks: TicketStockInput[] | undefined | null): string | undefined {
    if (ticketStocks) {
      return ticketStocks
        .map((v) => `${v.airlineCode?.trim() ?? ''} ${v.stockNumber?.trim() ?? ''}`)
        .join(',');
    }
  }

  private mapCodes(codes: (string | null)[] | undefined | null): string | undefined {
    if (codes) {
      return codes.map((code) => code?.trim()).join(',');
    }
  }

  private mapTicketStocksToDto(ticketStocks: string | undefined | null): TicketStockInput[] | undefined {
    if (!ticketStocks) {
      return;
    }

    return ticketStocks.split(this.MultipleSpacesPattern).map((v) => {
      return {
        airlineCode: v.split(' ')[0],
        stockNumber: v.split(' ')[1],
      };
    });
  }

  private extractDateRangeInput({
    startDate,
    endDate,
  }: DateRangeInput): [Date | undefined, Date | undefined] | void {
    if (startDate || endDate) {
      return [
        startDate ? this.convertStringToUtc(startDate) : undefined,
        endDate ? this.convertStringToUtc(endDate) : undefined,
      ];
    }
  }

  private mapToWaiverCodeDate(dateModel: WaiverCodeDate): WaiverCodeDateInput | null {
    return !dateModel[0] && !dateModel[1] && !dateModel[2]
      ? null
      : ({
          startDate: dateModel[0] && this.convertDateToUtc(dateModel[0])?.toISOString(),
          endDate: dateModel[1] && this.convertDateToUtc(dateModel[1])?.toISOString(),
          withinValidityOfOriginalTicket: dateModel[2],
        } as WaiverCodeDateInput);
  }

  private extractWaiverCodes(codes: WaiverCodeInput[]): WaiverCodeViewModel[] | null {
    return codes.map(
      (code) =>
        ({
          code: code.code,
          type: code.type,
          fees: code.fees,
          notes: code.notes,
          rebookDate: [
            this.convertStringToUtc(code.rebookDate?.startDate),
            this.convertStringToUtc(code.rebookDate?.endDate),
            code.rebookDate?.withinValidityOfOriginalTicket,
          ],
          reissueDate: [
            this.convertStringToUtc(code.reissueDate?.startDate),
            this.convertStringToUtc(code.reissueDate?.endDate),
            code.reissueDate?.withinValidityOfOriginalTicket,
          ],
          travelDate: [
            this.convertStringToUtc(code.travelDate?.startDate),
            this.convertStringToUtc(code.travelDate?.endDate),
            code.travelDate?.withinValidityOfOriginalTicket,
          ],
          waiverCodeToWaiveChangeFee: codes.find((item) => item.type === WaiverCodeType.Normal)?.code,
        }) as WaiverCodeViewModel,
    );
  }

  private mapToWaiverCodes(models: WaiverCodeViewModel[]): WaiverCodeInput[] {
    return models.map(
      (model) =>
        ({
          code: model.code,
          type: model.type,
          fees: model.fees,
          notes: model.notes,
          rebookDate: model.rebookDate && this.mapToWaiverCodeDate(model.rebookDate),
          reissueDate: model.reissueDate && this.mapToWaiverCodeDate(model.reissueDate),
          travelDate: model.travelDate && this.mapToWaiverCodeDate(model.travelDate),
        }) as WaiverCodeInput,
    );
  }

  private mapToDateRange([startDate, endDate]: Date[]): DateRangeInput {
    return {
      startDate: startDate && this.convertDateToUtc(startDate)?.toISOString(),
      endDate: endDate && this.convertDateToUtc(endDate)?.toISOString(),
    } as DateRangeInput;
  }

  private nullifyFileds(isNew: boolean, model: { [key: string]: unknown }): { [key: string]: unknown } {
    const keys = Object.keys(model);
    const emptyValueFromIsNew = isNew ? undefined : null;
    keys.forEach((key) => {
      if (!model[key] && model[key] !== false) {
        model[key] = emptyValueFromIsNew;
      } else if (model[key] && model[key] instanceof Array) {
        const array = (model[key] as [])
          .filter((item) => !!item)
          .map((item) => this.nullifyFileds(true, item));
        model[key] = array.length === 0 ? emptyValueFromIsNew : array;
      } else if (model[key] && model[key] instanceof Object) {
        this.nullifyFileds(true, model[key] as { [key: string]: unknown });
      }
    });

    return model;
  }
}
