import { Auth } from 'aws-amplify';
import { CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Icon, Input } from 'semantic-ui-react';
import { ModalAction } from '../../enums/ModalAction';
import { ToastType } from '../../enums/ToastType';
import { useGlobalComponents } from '../../features/GlobalComponentsProvider';
import Loader from '../../features/Loader';
import UserForm from '../../features/UserForm/UserForm';
import UsersList from '../../features/UsersList';
import { usePageNavigation } from '../../hooks/Navigation/usePageNavigation';
import { useActiveItem } from '../../hooks/Table/useActiveItem';
import { useConfiguration } from '../../hooks/useConfiguration';
import { GroupsMapper } from '../../mappers/GroupsMapper';
import { UsersMapper } from '../../mappers/UsersMapper';
import { UserFormModel } from '../../models/ViewModels/UserFormModel';
import { UsersService } from '../../services/UsersService';
import { getExpandedUsers, getSearchingUsers } from './helpers';
import './styles.scss';
import { getLabel } from '../../glossary';

const credentialsProvider = async () => {
  return await Auth.currentCredentials();
};

const UsersPage: React.FC = () => {
  const { showToast, showModal } = useGlobalComponents();
  const [
    { path, id, haveActiveItem, isFormOpen, searchTerm },
    { goToMainPage, goToUrl, goToModal, handleSearchChanged },
  ] = usePageNavigation();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [users, setUsers] = useState<UserFormModel[] | null>(null);
  const [searchingUsers, setSearchingUsers] = useState<UserFormModel[] | null>(null);
  const [{ activeItem }, { updateActiveItem }] = useActiveItem<UserFormModel>();
  const [config] = useConfiguration();

  const usersService = useMemo(
    () =>
      new UsersService(
        new CognitoIdentityProviderClient({ region: config.region, credentials: credentialsProvider }),
        new UsersMapper(),
        new GroupsMapper(),
        config.userPoolId,
      ),
    [config.region, config.userPoolId],
  );

  const showSucessToast = (endMessage: string, username?: string) => {
    showToast(
      `${getLabel('UsersPage_User_Success_Start')}
        ${username ?? ''} ${endMessage}`,
      ToastType.Success,
    );
  };

  const updateUserStatus = async (formData: UserFormModel) => {
    try {
      setIsLoaded(false);
      await usersService.updateUserStatus(formData);
      await getUsersList();
      showSucessToast(getLabel('UsersPage_UpdateUser_Success_End'), formData.username);
    } catch (e) {
      showToast((e as Error).message, ToastType.Error);
    } finally {
      setIsLoaded(true);
    }
  };

  const deleteUser = async (username?: string) => {
    try {
      setIsLoaded(false);
      await usersService.deleteUser(username);
      await getUsersList();
      showSucessToast(getLabel('UsersPage_DeleteUser_Success_End'), username);
    } catch (e) {
      showToast((e as Error).message, ToastType.Error);
    } finally {
      setIsLoaded(true);
    }
  };

  const submitUserForm = async (formData: UserFormModel) => {
    const updateUsersPage = async () => {
      goToMainPage();
      await getUsersList();
    };

    try {
      setIsLoaded(false);
      if (activeItem?.username) {
        await usersService.updateUser(activeItem, formData);
        await updateUsersPage();
        showSucessToast(getLabel('UsersPage_UpdateUser_Success_End'), formData.username);
      } else {
        await usersService.createUser(formData);
        await updateUsersPage();
        showSucessToast(getLabel('UsersPage_CreateUser_Success_End'), formData.username);
      }
    } catch (e) {
      showToast((e as Error).message, ToastType.Error);
    } finally {
      setIsLoaded(true);
    }
  };

  const handleDeleteUser = (username?: string) => {
    showModal(
      `${getLabel('UserForm_Modal_Delete_Message')} ${username ?? ''}?`,
      [ModalAction.NoLeave, ModalAction.YesConfirm],
      (action: ModalAction) => {
        action === ModalAction.YesConfirm && void deleteUser(username);
      },
    );
  };

  const getUsersList = useCallback(async () => {
    const usersList = await usersService.getUsers();
    const groupsList = await usersService.getGroups();

    const expandedUsers = getExpandedUsers(usersList, groupsList);

    setUsers(expandedUsers);
    setIsLoaded(true);
  }, [usersService]);

  useEffect(() => {
    users && updateActiveItem(users, 'username', id, haveActiveItem);
  }, [path, id, haveActiveItem, updateActiveItem, users]);

  useEffect(() => {
    void getUsersList();
  }, [getUsersList]);

  useEffect(() => {
    setSearchingUsers(getSearchingUsers(users, searchTerm));
  }, [searchTerm, users]);

  return (
    <div className={`users-page ${isFormOpen ? 'form-open' : ''}`}>
      {!isLoaded && <Loader />}
      <div className="users-title-container">
        <Input
          data-testid="users-search-input"
          size="mini"
          icon="search"
          iconPosition="left"
          placeholder={getLabel('UsersPage_SearchPlaceholder')}
          value={searchTerm}
          onChange={(e) => handleSearchChanged(e.target.value)}
        />
        <Button
          data-testid="create-user-button"
          primary
          className="create-button"
          onClick={() => goToModal()}
        >
          <Icon name="plus circle" />
          <span>{getLabel('UsersPage_CreateUserButton')}</span>
        </Button>
      </div>
      <div className="users-page-container">
        <UsersList
          users={searchingUsers}
          activeItem={activeItem}
          noDataMessage={searchTerm ? getLabel('UserList_NoResultsFound') : getLabel('UserList_NoUsersFound')}
          onUserEdit={(user) => goToModal(user?.username)}
          onUserDelete={(user) => handleDeleteUser(user?.username)}
          onUserStatusChange={(value: UserFormModel): void => {
            void updateUserStatus(value);
          }}
        />
        {isFormOpen && activeItem && (
          <UserForm
            model={activeItem}
            key={activeItem?.username}
            onSubmit={(value: UserFormModel): void => {
              void submitUserForm(value);
            }}
            onClose={goToMainPage}
            goToUrl={goToUrl}
          />
        )}
      </div>
    </div>
  );
};

export default UsersPage;
