import React from 'react';
import { FieldError, Merge } from 'react-hook-form/dist/types';
import { Checkbox, Form, Label } from 'semantic-ui-react';
import InfoIcon from '../InfoIcon';
import { CheckboxModel } from './CheckboxModel';

export interface Props<T> {
  label: string;
  infoMessage?: string;
  values: CheckboxModel<T>[];
  onChange: (value: T[]) => void;
  horizontal?: boolean;
  error?: FieldError | Merge<FieldError, (FieldError | undefined)[]>;
  required?: boolean;
  readonly?: boolean;
}

const CheckboxGroupWithInfo = <T extends number | string>(props: Props<T>): React.ReactElement => {
  const valueMap = new Map<T, boolean>();
  props.values.forEach(({ value, checked }) => valueMap.set(value, checked));

  const onChange = (changedValue: T) => {
    valueMap.set(changedValue, !valueMap.get(changedValue));
    const values = Array.from(valueMap.entries())
      .filter(([, checked]) => checked)
      .map(([value]) => value);
    props.onChange(values);
  };

  return (
    <Form.Group widths="equal">
      <Form.Field width="15" required={props.required}>
        <label>{props.label}</label>
        <InfoIcon infoMessage={props.infoMessage} left />
        {props.horizontal && <br />}
        <div>
          {props.values.map(({ label, name, value, checked }, index) => (
            <React.Fragment key={index}>
              {!props.horizontal && <br />}
              <Checkbox
                readOnly={props.readonly}
                name={name}
                label={label}
                checked={checked}
                onChange={() => onChange(value)}
              />
            </React.Fragment>
          ))}
        </div>
        {props.error && props.error.message && (
          <Form.Field className="validation-error">
            <Label basic color="red" pointing>
              {props.error.message}
            </Label>
          </Form.Field>
        )}
      </Form.Field>
    </Form.Group>
  );
};

export default CheckboxGroupWithInfo;
