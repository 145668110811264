import React, { useEffect, useState } from 'react';
import { Form, Label } from 'semantic-ui-react';
import { FieldError, Merge } from 'react-hook-form/dist/types';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import InfoIcon from '../InfoIcon';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import { getLabel } from './../../glossary';
import { getDateStringLabel } from '../WaiversForm/helpers';

import '../../styles/form.scss';
import './styles.scss';

interface Props {
  name?: string;
  label: string;
  infoMessage?: string;
  onChange: (dates: Date[][]) => void;
  error?: FieldError | Merge<FieldError, (Merge<FieldError, (FieldError | undefined)[]> | undefined)[]>;
  value?: Date[][] | null;
  required?: boolean;
  readonly?: boolean;
}

const MultipleDateInputWithInfo: React.FC<Props> = (props) => {
  const [dates, setDates] = useState<Date[][]>([[]]);

  useEffect(() => {
    setDates(props.value ? props.value.map((value) => [...value]) : [[]]);
  }, [props.value]);

  const onDateRangeInputChange = (
    event: React.SyntheticEvent | undefined,
    data: SemanticDatepickerProps,
  ): void => {
    const changedDates = Array.from(dates);
    if (data.value && data.value instanceof Array) {
      changedDates[Number(data.id)] = [];
      data.value[0] && changedDates[Number(data.id)].push(data.value[0]);
      data.value[1] && changedDates[Number(data.id)].push(data.value[1]);
    } else if (!data.value) {
      changedDates[Number(data.id)] = [];
    }

    setDates(changedDates);
    props.onChange(changedDates);
  };

  const addDate = () => {
    setDates([...dates, []]);
  };

  const removeDate = (index: number) => {
    const splicedDates = Array.from(dates);
    splicedDates.splice(index, 1);
    setDates(splicedDates);
    props.onChange(splicedDates);
  };

  const getDateLabel = (date: Date[] | Date): string => {
    return getDateStringLabel(date);
  };

  const getDatepickerValue = (date: Date[]): Date[] | null => {
    return date.length > 0 ? date : null;
  };

  return (
    <Form.Group>
      <Form.Field
        className="datepicker daterange"
        error={props.error !== undefined}
        required={props.required}
      >
        <label htmlFor={props.name}>{props.label}</label>
        <InfoIcon infoMessage={props.infoMessage} left />
        {dates.map((date, index) => (
          <div className="datepicker-row" key={index}>
            <div className="datepicker-wrapper">
              {props.readonly ? (
                getDateLabel(date)
              ) : (
                <SemanticDatepicker
                  id={index}
                  value={getDatepickerValue(date)}
                  pointing="top left"
                  name={`${props.name ?? ''}${index}`}
                  type="range"
                  onChange={onDateRangeInputChange}
                />
              )}
            </div>
            {index === 0 && !props.readonly && (
              <button className="btn-add" type="button" onClick={addDate}>
                {getLabel('DateInput_Button_AddDate')}
              </button>
            )}
            {index > 0 && <button className="btn-remove" type="button" onClick={() => removeDate(index)} />}
          </div>
        ))}
        {props.error?.message && (
          <Form.Field className="validation-error">
            <Label basic color="red" pointing>
              {props.error.message}
            </Label>
          </Form.Field>
        )}
      </Form.Field>
    </Form.Group>
  );
};

export default MultipleDateInputWithInfo;
