import { getLabel } from './../../glossary';
import { PlacementOption } from '../../enums/PlacementOption';
import { PlacementOptionsState } from './States/PlacementOptionsState';

export const getPlacementsInitialData = (): PlacementOptionsState => ({
  options: [
    {
      id: PlacementOption.TicketDesignator,
      value: getLabel('WaiversCodePlacement_TicketDesignator'),
    },
    {
      id: PlacementOption.TourCodeBox,
      value: getLabel('WaiversCodePlacement_TourCodeBox'),
    },
    {
      id: PlacementOption.EndorsementBox,
      value: getLabel('WaiversCodePlacement_EndorsementBox'),
    },
    {
      id: PlacementOption.OsiMessage,
      value: getLabel('WaiversCodePlacement_OsiMessage'),
    },
  ],
  columns: {
    available: {
      id: 'available',
      title: 'Available',
      optionIds: [],
    },
    final: {
      id: 'final',
      title: 'Final',
      optionIds: [],
    },
  },
  columnOrder: ['available', 'final'],
});
