import { getLabel } from './../glossary';
import {
  AdminAddUserToGroupCommand,
  AdminCreateUserCommand,
  AdminDeleteUserCommand,
  AdminDisableUserCommand,
  AdminEnableUserCommand,
  AdminListGroupsForUserCommand,
  AdminRemoveUserFromGroupCommand,
  CognitoIdentityProviderClient,
  GroupType,
  ListGroupsCommand,
  ListUsersCommand,
  ListUsersCommandOutput,
  ListUsersInGroupCommand,
  UserType,
} from '@aws-sdk/client-cognito-identity-provider';
import { READONLY } from '../features/UserForm/UserFormConstants';
import { GroupsMapper } from '../mappers/GroupsMapper';
import { UsersMapper } from '../mappers/UsersMapper';
import { UserFormModel } from '../models/ViewModels/UserFormModel';
import { GroupViewModel } from './../models/ViewModels/GroupViewModel';
import { UserViewModel } from './../models/ViewModels/UserViewModel';

export class UsersService {
  private readonly fetchDataErrorMessage = 'Error while fetching data from API';
  private cognitoClient: CognitoIdentityProviderClient;
  private usersMapper: UsersMapper;
  private groupsMapper: GroupsMapper;
  private userPoolId: string;

  constructor(
    cognitoClient: CognitoIdentityProviderClient,
    usersMapper: UsersMapper,
    groupsMapper: GroupsMapper,
    userPoolId: string,
  ) {
    this.cognitoClient = cognitoClient;
    this.usersMapper = usersMapper;
    this.groupsMapper = groupsMapper;
    this.userPoolId = userPoolId;
  }

  public async getUsers(): Promise<UserViewModel[] | undefined> {
    try {
      const commandOutputList: ListUsersCommandOutput[] = [];
      let nextPaginationToken: string | undefined;
      do {
        const output = await this.cognitoClient.send(
          new ListUsersCommand({
            UserPoolId: this.userPoolId,
            PaginationToken: nextPaginationToken,
          }),
        );
        output && commandOutputList.push(output);
        nextPaginationToken = commandOutputList.length
          ? commandOutputList[commandOutputList.length - 1].PaginationToken
          : undefined;
      } while (nextPaginationToken);

      const users = commandOutputList.map((o) => o.Users).flat() as UserType[];
      return this.usersMapper.mapFromDtoList(users);
    } catch (err) {
      console.error(`error while fetching data ${JSON.stringify(err)}`);
      throw new Error(this.fetchDataErrorMessage);
    }
  }

  public async getGroups(): Promise<GroupViewModel[] | undefined> {
    try {
      const result = await this.cognitoClient.send(
        new ListGroupsCommand({
          UserPoolId: this.userPoolId,
        }),
      );

      const groupsWithRole = result?.Groups?.filter((g) => g.RoleArn) ?? [];

      const groupsWithUser = await this.getUsersInGroupsMapping(groupsWithRole);
      return this.groupsMapper.mapFromDtoList(
        groupsWithRole,
        (groupName) => groupsWithUser.find((g) => g.groupName === groupName)?.userNames ?? [],
      );
    } catch (err) {
      console.error(`error while fetching data ${JSON.stringify(err)}`);
      throw new Error(this.fetchDataErrorMessage);
    }
  }

  public async createUser(user: UserFormModel) {
    try {
      await this.cognitoClient.send(
        new AdminCreateUserCommand(this.usersMapper.mapToDto(user, this.userPoolId)),
      );
      if (user.role !== READONLY) {
        await this.cognitoClient.send(
          new AdminAddUserToGroupCommand({
            UserPoolId: this.userPoolId,
            Username: user.username,
            GroupName: user.role,
          }),
        );
      }
      if (!user.enabled) {
        await this.cognitoClient.send(
          new AdminDisableUserCommand({
            UserPoolId: this.userPoolId,
            Username: user.username,
          }),
        );
      }
    } catch (err) {
      const isUserExists = (err as { message: string[] }).message.includes('User account already exists');
      console.error(`error while fetching data ${JSON.stringify(err)}`);
      throw new Error(
        isUserExists ? getLabel('UsersPage_SubmitUser_UserError') : getLabel('UsersPage_User_Error'),
      );
    }
  }

  public async updateUserStatus(originalModel: UserFormModel) {
    try {
      const commandInput = {
        UserPoolId: this.userPoolId,
        Username: originalModel.username,
      };
      await this.cognitoClient.send(
        originalModel.enabled
          ? new AdminDisableUserCommand(commandInput)
          : new AdminEnableUserCommand(commandInput),
      );
    } catch (err) {
      console.error(`error while update user status ${JSON.stringify(err)}`);
      throw new Error(getLabel('UsersPage_User_Error'));
    }
  }

  public async updateUser(originalModel: UserViewModel, updatedModel: UserFormModel) {
    try {
      originalModel.enabled !== updatedModel.enabled && (await this.updateUserStatus(originalModel));

      const [groupsToAdd, groupsToRemove] = await this.getGroupsToAddAndDelete(
        originalModel.username,
        updatedModel.role,
      );

      await Promise.all([
        ...groupsToAdd
          .filter((g) => g !== READONLY)
          .map((g) =>
            this.cognitoClient.send(
              new AdminAddUserToGroupCommand({
                GroupName: g,
                UserPoolId: this.userPoolId,
                Username: originalModel.username,
              }),
            ),
          ),
        ...groupsToRemove
          .filter((g) => g !== READONLY)
          .map((g) =>
            this.cognitoClient.send(
              new AdminRemoveUserFromGroupCommand({
                GroupName: g,
                UserPoolId: this.userPoolId,
                Username: originalModel.username,
              }),
            ),
          ),
      ]);
    } catch (err) {
      console.error(`error while update user ${JSON.stringify(err)}`);
      throw new Error(getLabel('UsersPage_User_Error'));
    }
  }

  public async deleteUser(username?: string) {
    try {
      await this.cognitoClient.send(
        new AdminDeleteUserCommand({
          Username: username,
          UserPoolId: this.userPoolId,
        }),
      );
    } catch (err) {
      console.error(`error while delete user ${JSON.stringify(err)}`);
      throw new Error(getLabel('UsersPage_User_Error'));
    }
  }

  private getUsersInGroupsMapping = (groupsWithRole: GroupType[]) => {
    const usersInGroups = groupsWithRole.map(async (group) => {
      const usersInGroup = await this.cognitoClient.send(
        new ListUsersInGroupCommand({
          UserPoolId: this.userPoolId,
          GroupName: group.GroupName,
        }),
      );

      return usersInGroup
        ? {
            groupName: group.GroupName ?? '',
            userNames: usersInGroup.Users?.map((user) => user.Username ?? '') ?? [],
          }
        : {
            groupName: group.GroupName ?? '',
            userNames: [],
          };
    });
    return Promise.all(usersInGroups);
  };

  private getGroupsToAddAndDelete = async (userName?: string, roleName?: string) => {
    const groups =
      (
        await this.cognitoClient.send(
          new AdminListGroupsForUserCommand({
            Username: userName,
            UserPoolId: this.userPoolId,
          }),
        )
      )?.Groups?.filter((g) => g.RoleArn)?.map((g) => g.GroupName) ?? [];
    const groupsToAdd = groups.some((g) => g === roleName) ? [] : [roleName];
    const groupsToRemove = groups.filter((g) => g !== roleName);
    return [groupsToAdd, groupsToRemove];
  };
}
