import { useHistory, useLocation, useParams } from 'react-router-dom';

interface NavigationState {
  search?: string;
}

export const usePageNavigation = (): [
  {
    path: string;
    id: string;
    haveActiveItem: boolean;
    isFormOpen: boolean;
    searchTerm: string;
  },
  {
    handleSearchChanged: (search?: string) => void;
    goToMainPage: () => void;
    goToUrl: (url?: string) => void;
    goToModal: (id?: string) => void;
    goToSubPage: (url: string) => void;
  },
] => {
  const location = useLocation<NavigationState>();
  const history = useHistory();
  const params: { id: string } = useParams<{ id: string }>();

  const baseUrl = location.pathname.replace(/\/(add|edit)(\/|[0-9A-Z]|-|_|\.)*$/gi, '');
  const haveActiveItem = location.pathname.includes('edit');
  const isFormOpen = haveActiveItem || location.pathname.includes('add');
  const searchTerm = location.state?.search ?? '';

  const goToMainPage = () => history.push(baseUrl, location.state);

  const goToUrl = (url?: string) => {
    url ? history.push(url, location.state) : goToMainPage();
  };

  const goToModal = (id?: string) => {
    id
      ? history.push(`${baseUrl}/edit/${id}`, location.state)
      : history.push(`${baseUrl}/add`, location.state);
  };

  const goToSubPage = (url: string) => {
    history.push(`${baseUrl}/${url}`, location.state);
  };

  const handleSearchChanged = (searchValue?: string) => {
    history.replace(location.pathname, { ...location.state, search: searchValue });
  };

  return [
    {
      path: location.pathname,
      id: params.id,
      haveActiveItem,
      isFormOpen,
      searchTerm,
    },
    { goToMainPage, goToUrl, goToModal, goToSubPage, handleSearchChanged },
  ];
};
