import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import App from './App';
import ErrorPage from './pages/ErrorPage';
import { buildConfiguration } from './services/configurationBuilder';
import { UserProvider } from './hooks/User/user';
import { ConfigurationProvider } from './hooks/useConfiguration';
import { ClearCacheProvider } from 'react-clear-cache';

buildConfiguration()
  .then((config) =>
    renderComponentToRoot(
      <ClearCacheProvider auto={true}>
        <ConfigurationProvider config={config}>
          <UserProvider>
            <App />
          </UserProvider>
        </ConfigurationProvider>
      </ClearCacheProvider>,
    ),
  )
  .catch((reason) => {
    console.log(reason);
    renderComponentToRoot(<ErrorPage />);
  });

function renderComponentToRoot(component: JSX.Element) {
  const root = document.getElementById('root');
  ReactDOM.render(component, root);
}
