import React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { OptionProps } from '../Props/OptionProps';

const Option: React.FC<OptionProps> = (props: OptionProps) => {
  return (
    <Draggable draggableId={props.option.id.toString()} index={props.index} isDragDisabled={props.readonly}>
      {(provided: DraggableProvided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={'option'}
        >
          {props.option.value}
        </div>
      )}
    </Draggable>
  );
};

export default Option;
