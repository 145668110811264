import React, { SyntheticEvent, useState } from 'react';
import * as H from 'history';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { Prompt, useLocation } from 'react-router-dom';
import { Button, Form, Radio } from 'semantic-ui-react';

import { getLabel } from './../../glossary';
import { ModalAction } from '../../enums/ModalAction';
import { getAllRoles } from '../../enums/Role';
import { UserFormModel } from '../../models/ViewModels/UserFormModel';
import { useGlobalComponents } from '../GlobalComponentsProvider';
import SelectWithInfo from '../SelectWithInfo';
import { SelectValue } from '../SelectWithInfo/SelectWithInfo';
import TextInputWithInfo from '../TextInputWithInfo';
import {
  getEmailPattern,
  getUserNamePattern,
  getUserRolesOptions,
  userFormDefaultValues,
} from './UserFormConstants';

import '../../styles/form.scss';
import './styles.scss';

interface Props {
  model?: UserFormModel;
  onSubmit: (formData: UserFormModel) => void;
  onClose: () => void;
  goToUrl: (redirectUrl?: string) => void;
}

const UserForm: React.FC<Props> = ({ model, onSubmit, onClose, goToUrl }) => {
  const { showModal } = useGlobalComponents();
  const emailPattern = getEmailPattern();
  const userNamePattern = getUserNamePattern();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const location: H.Location = useLocation();

  const formHook = useForm<UserFormModel>({
    defaultValues: {
      ...userFormDefaultValues,
      ...model,
    },
  });
  const { register, handleSubmit, control, formState } = formHook;

  const errors: { [key in keyof UserFormModel]?: FieldError } = formState.errors;
  const isDirty = formState.isDirty;

  const handleRedirect = (nextLocation: H.Location): boolean => {
    if (nextLocation.pathname !== location.pathname) {
      setConfirmedNavigation(true);
      showModal(
        getLabel('UserForm_Modal_UnsavedChanges_Message'),
        [ModalAction.YesLeave, model?.username ? ModalAction.NoBackToEdit : ModalAction.NoBackToAdd],
        (action: ModalAction) => {
          if (action === ModalAction.YesLeave) {
            goToUrl(nextLocation.pathname);
          } else if (action === ModalAction.NoBackToAdd || action === ModalAction.NoBackToEdit) {
            setConfirmedNavigation(false);
          }
        },
        getLabel('UserForm_Modal_UnsavedChanges_Header'),
      );
      return false;
    }
    return true;
  };

  const handleSubmitForm = (formData: UserFormModel): void => {
    showModal(
      getLabel('UserForm_Modal_Save_Message'),
      [ModalAction.NoLeave, ModalAction.YesConfirm],
      (action: ModalAction) => {
        if (action === ModalAction.YesConfirm) {
          setConfirmedNavigation(true);
          onSubmit(formData);
        }
      },
    );
  };

  return (
    <>
      <Prompt when={isDirty && !confirmedNavigation} message={handleRedirect} />

      <Form
        onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
          void handleSubmit(handleSubmitForm)(event);
        }}
        autoComplete="off"
        className="form-panel user-form"
      >
        <h2 className="header">
          {model?.username ? getLabel('UserForm_EditTitle') : getLabel('UserForm_AddTitle')}
        </h2>
        <TextInputWithInfo
          error={errors.username}
          label={getLabel('UserForm_UserName')}
          required
          disabled={!!model?.username}
          register={register('username', userNamePattern)}
        />
        <TextInputWithInfo
          error={errors.email}
          label={getLabel('UserForm_Email')}
          required
          disabled={!!model?.email}
          register={register('email', emailPattern)}
        />

        <Controller
          name="role"
          control={control}
          rules={{
            required: {
              value: true,
              message: getLabel('UserForm_FeatureGroupRequired'),
            },
          }}
          render={({ field: { onChange } }) => (
            <SelectWithInfo
              defaultValue={model?.role || ''}
              options={getUserRolesOptions(getAllRoles())}
              label={getLabel('UserForm_FeatureGroup')}
              error={errors.role}
              required
              onChange={(_e: SyntheticEvent, { value }: SelectValue) => {
                onChange(String(value));
              }}
            />
          )}
        />
        <Form.Field>
          <Controller
            name="enabled"
            control={control}
            render={({ field: { onChange } }) => (
              <Radio
                toggle
                defaultChecked={model?.enabled || false}
                label={getLabel('UserForm_Enable')}
                onChange={(_e, { checked }) => onChange(Boolean(checked))}
              />
            )}
          />
        </Form.Field>
        <div className="user-form-footer">
          <Button basic color="blue" type="button" onClick={() => onClose()}>
            {getLabel('UserForm_Cancel')}
          </Button>
          <Button primary disabled={!isDirty} type="submit">
            {getLabel('UserForm_Save')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UserForm;
