// this is an auto generated file. This will be overwritten

export const getWaivers = `
  query GetWaivers($id: String!) {
    getWaivers(id: $id) {
      affectedAirportCodes
      affectedCountryCodes
      airlineIssueDate
      attachments
      bookingOrMarketingAirlineCodes
      createDate
      createdBy
      flightType
      impactedTravelDates {
        startDate
        endDate
      }
      id
      isGlobal
      issuingAirlineCode
      operatingAirlineCodes
      osiRequirements
      placements
      refundInfo
      reissueInfo
      regions
      sourceWaiverURL
      state
      tags
      ticketedDates {
        startDate
        endDate
      }
      ticketStocks {
        airlineCode
        stockNumber
      }
      title
      updateDate
      updatedBy
      waiverCodes {
        code
        fees
        notes
        rebookDate {
          startDate
          endDate
          withinValidityOfOriginalTicket
        }
        reissueDate {
          startDate
          endDate
          withinValidityOfOriginalTicket
        }
        travelDate {
          startDate
          endDate
          withinValidityOfOriginalTicket
        }
        type
      }
    }
  }
`;

export const searchWaivers = `
  query SearchWaivers($filter: WaiverFilterInput, $size: Int, $from: Int, $sortField: String, $sortDirection: String) {
    searchWaivers(filter: $filter, size: $size, from: $from, sortField: $sortField, sortDirection: $sortDirection) {
      items {
        affectedAirportCodes
        affectedCountryCodes
        airlineIssueDate
        createDate
        createdBy
        impactedTravelDates {
          startDate
          endDate
        }
        id
        issuingAirlineCode
        regions
        state
        tags
        title
        waiverCodes {
          code
          type
        }
      }
      total
    }
  }
`;

export const exportWaivers = `
  query ExportWaivers($size: Int, $from: Int) {
    searchWaivers(size: $size, from: $from) {
      items {
        affectedAirportCodes
        affectedCountryCodes
        airlineIssueDate
        attachments
        bookingOrMarketingAirlineCodes
        createDate
        createdBy
        flightType
        impactedTravelDates {
          startDate
          endDate
        }
        id
        isGlobal
        issuingAirlineCode
        operatingAirlineCodes
        osiRequirements
        placements
        refundInfo
        reissueInfo
        regions
        sourceWaiverURL
        state
        tags
        ticketedDates {
          startDate
          endDate
        }
        ticketStocks {
          airlineCode
          stockNumber
        }
        title
        updateDate
        updatedBy
        waiverCodes {
          code
          fees
          notes
          rebookDate {
            startDate
            endDate
            withinValidityOfOriginalTicket
          }
          reissueDate {
            startDate
            endDate
            withinValidityOfOriginalTicket
          }
          travelDate {
            startDate
            endDate
            withinValidityOfOriginalTicket
          }
          type
        }
      }
      total
    }
  }
`;
