import React from 'react';
import { Radio, Form } from 'semantic-ui-react';
import InfoIcon from '../InfoIcon';
import { RadiobuttonModel } from './RadiobuttonModel';

import './styles.scss';

export interface Props<T> {
  infoMessage?: string;
  label: string;
  value: T | null;
  radiobuttons: RadiobuttonModel<T>[];
  onChange: (value: T) => void;
  horizontal?: boolean;
  disabled?: boolean;
  readonly?: boolean;
}

const RadiobuttonGroup = <T extends number | string>(props: Props<T>): React.ReactElement => {
  const isActive = (value?: T): boolean => props.value === value;

  return (
    <Form.Group widths="equal">
      <Form.Field disabled={props.disabled ?? false}>
        <label>{props.label}</label>
        <InfoIcon infoMessage={props.infoMessage} left />
        {props.horizontal && <br />}
        {props.radiobuttons.map(({ label, name, value }, index: number) => {
          return (
            <React.Fragment key={index}>
              {!props.horizontal && <br />}
              <Radio
                readOnly={props.readonly}
                label={label}
                name={name}
                value={value}
                checked={isActive(value)}
                onChange={() => props.onChange(value)}
              />
            </React.Fragment>
          );
        })}
      </Form.Field>
    </Form.Group>
  );
};

export default RadiobuttonGroup;
