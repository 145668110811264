import { AdminCreateUserCommandInput, UserType } from '@aws-sdk/client-cognito-identity-provider';
import { UserFormModel } from '../models/ViewModels/UserFormModel';
import { UserViewModel } from '../models/ViewModels/UserViewModel';

export class UsersMapper {
  public mapFromDtoList(users: UserType[]): UserViewModel[] {
    return users.map((user) => {
      return {
        username: user.Username,
        enabled: user.Enabled,
        email: user.Attributes?.find((a) => a.Name === 'email')?.Value,
        creationDate: user.UserCreateDate,
      };
    });
  }

  public mapToDto(user: UserFormModel, userPoolId: string): AdminCreateUserCommandInput {
    return {
      Username: user.username,
      UserPoolId: userPoolId,
      UserAttributes: [
        {
          Name: 'email',
          Value: user.email,
        },
        {
          Name: 'email_verified',
          Value: 'true',
        },
      ],
    };
  }
}
