import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form/dist/types';

import InfoIcon from '../InfoIcon';

import './styles.scss';

interface Props {
  label?: string;
  infoMessage?: string;
  error?: FieldError;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  register: UseFormRegisterReturn;
}

const TextAreaWithInfo: React.FC<Props> = ({
  error,
  required,
  disabled,
  label,
  infoMessage,
  readonly,
  register,
}) => {
  return (
    <Form.Group widths="equal">
      <Form.Field error={error !== undefined} required={required} width="15" disabled={disabled}>
        <label className="name" htmlFor={register.name}>
          {label}
        </label>
        <InfoIcon infoMessage={infoMessage} />
        <textarea {...register} readOnly={readonly} id={register?.name} />
        {error?.message && (
          <Label basic color="red" pointing>
            {error.message}
          </Label>
        )}
      </Form.Field>
    </Form.Group>
  );
};

export default TextAreaWithInfo;
